<ion-content>

  <div class="info-modal">

    <p class="down-btn" tappable (click)="modalCtrl.dismiss()">
      <i class="fas fa-chevron-down"></i>
    </p>

    <div class="info-img img-center" [style.background-image]="'url('+ firm?.shop_image +')'"></div>

    <div class="firm-info" tappable (click)="modalCtrl.dismiss()">
      <p class="firm-name font-17">{{ firm?.name }}</p>
      <p class="address font-11">{{ firm?.address }},</p>
      <p class="address font-11">{{ firm?.zipcode }} {{ firm?.locality }}</p>
      <p *ngIf="firm?.phone" class="phone font-11">{{ firm?.phone }}</p>
    </div>

    <div *ngIf="firm?.periods.opening_hours.length > 0">
      <p class="opening-hours-txt">{{ 'INFO.OPENING_HOURS' | translate }}</p>

      <!-- Opening hours -->
      <ion-row *ngFor="let day of opening_hours" class="hours-row">

        <!-- Day name + day indicator -->
        <ion-col size="4">
          <ion-icon class="hours-today-symbol" name="play" *ngIf="day.day_id === today_id"></ion-icon>
          <p class="day-name font-11" style="display: inline-block">{{ day.day }}</p>
        </ion-col>

        <ion-col size="8">
          <!-- Catch bad formatted hours -->
          <p *ngIf="day.am && !day.pm && day.am.type === 'open'">{{ day.am.from_time }} - {{ day.am.to_time }}</p>
          <p *ngIf="!day.am && day.pm && day.pm.type === 'open'">{{ day.pm.from_time }} - {{ day.pm.to_time }}</p>
          <p *ngIf="(day.am && !day.pm && day.am.type === 'closed') || (!day.am && day.pm && day.pm.type === 'closed')">{{ 'OPENINGHOURS.CLOSED_LOWER' | translate }}</p>

          <!-- Show normal hours -->
          <p *ngIf="(day.am && day.pm) && (day.am.type === 'open' && day.pm.type === 'closed')">{{ day.am.from_time }} - {{ day.am.to_time }}</p>
          <p *ngIf="(day.am && day.pm) && (day.am.type === 'closed' && day.pm.type === 'open')">{{ day.pm.from_time }} - {{ day.pm.to_time }}</p>
          <p *ngIf="(day.am && day.pm) && (day.am.type === 'open' && day.pm.type === 'open')">{{day.am.from_time}} - {{day.am.to_time}} & {{day.pm.from_time}} - {{day.pm.to_time}}</p>
          <p *ngIf="(day.am && day.pm) && (day.am.type === 'closed' && day.pm.type === 'closed')">{{ 'OPENINGHOURS.CLOSED_LOWER' | translate }}</p>
        </ion-col>
      </ion-row>

    </div>

    <ion-button class="custom-btn" *ngIf="!firm?.is_demo" (click)="openFirm()">{{ 'CHOOSE_LOCATION' | translate }}</ion-button>
    <ion-button class="custom-btn" *ngIf="firm?.is_demo">{{ 'UNDER_CONSTRUCTION' | translate }}</ion-button>
  </div>

</ion-content>
