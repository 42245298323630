import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeliveryTimeStore {
  public currentDeliveryTime$ = new BehaviorSubject<string>('');
  public updateEvent: EventEmitter<string>;
  public selectedTimeslotId$ = new BehaviorSubject(0);
  public modalShown$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.updateEvent = new EventEmitter<string>();
  }

  setDeliveryTime(deliveryTime: string): void {
    this.currentDeliveryTime$.next(deliveryTime);
    this.updateEvent.emit(deliveryTime);
  }

  setDeliveryTimeWithoutEvent(deliveryTime: string): void {
    this.currentDeliveryTime$.next(deliveryTime);
  }

  getDeliveryTime(): string {
    return this.currentDeliveryTime$.getValue();
  }

  selectTimeslot(id: number): void {
    this.selectedTimeslotId$.next(id);
  }
}
