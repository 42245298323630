import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'ready-to-order-modal',
  templateUrl: './ready-to-order-modal.component.html',
  styleUrls: ['./ready-to-order-modal.component.scss']
})
export class ReadyToOrderModalComponent {
  constructor(
    private modalController: ModalController,
    private navController: NavController,
  ) {
  }

  public async handleOnSubmit(): Promise<void> {
    await this.modalController.dismiss();
    await this.navController.navigateRoot('/method-selection');
  }

  public async handleOnMethodSelect(): Promise<void> {
    await this.modalController.dismiss();
  }
}
