import { Component } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { DeliveryTimeServiceInterface } from 'src/app/modules/delivery-time/services/delivery-time-service.interface';
import { DeliveryTimeServiceResolver } from 'src/app/modules/delivery-time/services/delivery-time-service.resolver';
import { DeliveryTimeStore } from 'src/app/modules/delivery-time/store/delivery-time.store';
import { FirmService } from 'src/app/services/firm.service';

@Component({
  selector: 'delivery-time-modal',
  templateUrl: './delivery-time-modal.component.html',
  styleUrls: ['./delivery-time-modal.component.scss'],
})
export class DeliveryTimeModalComponent {
  private deliveryTimeService: DeliveryTimeServiceInterface;

  public firm: any;
  public delay = '';
  public loading = true;

  constructor(
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
    private firmService: FirmService,
    private deliveryTimeStore: DeliveryTimeStore,
  ) {
    this.firmService.getLocalFirm()
      .then((firm) => {
        this.firm = firm;

        this.deliveryTimeServiceResolver.resolveDeliveryTimeService(firm)
          .then((service: DeliveryTimeServiceInterface) => {
            this.deliveryTimeService = service;

            const deliveryTime = this.deliveryTimeService?.getDeliveryTime();
            this.setDelay(deliveryTime);

            this.loading = false;
          });
      });

    this.listenToEvents();
  }

  public listenToEvents(): void {
    this.deliveryTimeStore.updateEvent
      .subscribe((deliveryTime: string) => {
        this.setDelay(deliveryTime);
      });
  }

  private setDelay(deliveryTime: string): void {
    this.delay = deliveryTime;
  }

  public isLimitedToSameDayOrdering(): boolean {
    return this.deliveryTimeService?.isLimitedToSameDayOrdering();
  }

  public hasCustomDaysEnabled(): boolean {
    return this.deliveryTimeService?.hasCustomDays();
  }

  public hasOrderTimeSlotsEnabled(): boolean {
    return this.deliveryTimeService?.hasOrderTimeslots();
  }

  public async handleOnSubmit(): Promise<void> {
    await this.modalCtrl.dismiss({});
  }
}
