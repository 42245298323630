import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'method-delivery-switcher',
  templateUrl: './method-delivery-switcher.component.html',
  styleUrls: ['./method-delivery-switcher.component.scss'],
})
export class MethodDeliverySwitcherComponent {
  constructor(
    private modalCtrl: ModalController,
    private router: Router,
  ) { }

  public async goToMethodSelection(): Promise<void> {
    await this.modalCtrl.dismiss({ cancelled: true });
    await this.router.navigate(['/method-selection']);
  }
}
