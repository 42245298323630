import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AnimationDirective } from './animation/animation.directive';

@NgModule({
  declarations: [
    AnimationDirective
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule
  ],
  exports: [
    AnimationDirective
  ]
})
export class DirectivesModule { }
