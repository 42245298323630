import {
  Component,
  EventEmitter,
  Input,
  OnDestroy, OnInit,
  Output
} from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import {
  ReadyToOrderModalComponent
} from 'src/app/modules/delivery-method/components/ready-to-order-modal/ready-to-order-modal.component';
import { MenuStore } from 'src/app/modules/menu/store/menu.store';
import { Units } from '../../constants/units';
import { OrderModalPage } from '../../pages/order-modal/order-modal.page';
import { BasketItemsService } from '../../services/basket-items.service';
import { NavService } from '../../services/nav.service';
import { ProductsService } from '../../services/products.service';
import { AddToCartService } from '../../services/add-to-cart.service';
import { Subscription } from 'rxjs';
import { DeliveryTimeStore } from '../../modules/delivery-time/store/delivery-time.store';

@Component({
  selector: 'app-item',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
})
export class ItemComponent implements OnInit, OnDestroy {

  @Input() item: any;
  @Input() cat_id: any;
  @Input() firm: any;
  @Input() opened: boolean;
  @Output() itemOpened: any;

  moment = moment;
  units = Units;

  basketItems: any;
  basketAmount: number;
  amountAlreadyInCart: number;

  quantity: any;
  ingr_group: any;
  activated: boolean = false;
  isSnoozed: boolean;
  updateSubscription: Subscription;

  constructor(private navCtrl: NavController,
              private modalCtrl: ModalController,
              private menuStore: MenuStore,
              private storage: Storage,
              public productsService: ProductsService,
              private navService: NavService,
              public basketItemsService: BasketItemsService,
              private addToCart: AddToCartService,
              private deliveryTimeStore: DeliveryTimeStore) {
    this.itemOpened = new EventEmitter<string>();

    this.updateSubscription = this.deliveryTimeStore.updateEvent.subscribe(() => {
      this.isSnoozed = this.isProductSnoozed();
    });
  }

  ngOnDestroy() {
    this.updateSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.setQuantity();

    if(this.item.ingredient_groups.length > 0) {
      let ingr = this.item.ingredient_groups.filter(elem => elem.id === 0);
      if(ingr.length > 0) {
        this.ingr_group = ingr[0];
      }
    }

    if(this.item.cat_id) {
      this.cat_id = this.item.cat_id;
    }
    else {
      this.cat_id = this.item.category.category_id;
    }

    this.isSnoozed = this.isProductSnoozed();
  }

  setQuantity() {
    if(this.item.unit.id === Units.UNIT || this.item.unit.id === Units.LITER || this.item.unit.id === Units.PERSON) {
      this.quantity = this.addToCart.getInitialQuantityForUnits(this.item.min);
    }
    else if(this.item.unit.id === Units.GRAM || this.item.unit.id === Units.KG) {
      this.quantity = this.addToCart.getInitialQuantityForInterval(this.item.min, this.item.unit_interval);
    }
  }

  isProductSnoozed(): boolean {
    return this.productsService.isSnoozed(this.item);
  }

  /* Navigation */
  goToItemPage(event) {
    event.stopPropagation();
    this.navService.item = JSON.stringify(this.item);
    this.navService.cat_id = this.cat_id;
    this.navCtrl.navigateForward('/item');
  }

  async openOrderBox(event) {
    event.stopPropagation();

    const basketItems = await this.storage.get('basketItems');
    this.basketAmount = this.basketItemsService.getTotalBasketAmount(this.firm.id, basketItems);
    this.amountAlreadyInCart = this.basketItemsService.getProductAmountFromBasket(this.item.id, basketItems);

    if(!this.item.opened) {
      if(this.item.in_stock) {
        await this.itemOpened.emit();
        this.item.opened = true;
      }
    }
    else {
      this.item.opened = false;
    }
  }

  quantityChanged(event) {
    this.quantity = event;
  }

  async orderProduct() {
    if (this.menuStore.viewMenuActive.getValue() === true) {
      const modal = await this.modalCtrl.create({
        component: ReadyToOrderModalComponent,
        cssClass: 'contrast-modal',
        backdropDismiss: true,
        showBackdrop: true,
      });

      await modal.present();

      return;
    }

    if (this.productsService.allowOpenOrderModal(this.item, this.firm)) {
      this.openOrderModal();
    }
    else {
      let init_product = JSON.stringify(this.item);
      var basketItem = await this.basketItemsService.setInitBasketItem(Number(this.quantity), this.item, this.firm, init_product, (Number(this.quantity) * Number(this.item.price)), [], undefined, []);
      this.basketItemsService.addToBasket(basketItem, [], Number(this.quantity), basketItem.total_price, [], this.firm, this.item.has_promotional_price);

      this.activated = true;

      setTimeout(() => {
        this.item.opened = false;
        this.activated = false;
        this.quantity = 1;
      }, 2000);
    }
  }

  async openOrderModal() {
    const orderModal = await this.modalCtrl.create({
      component: OrderModalPage,
      cssClass: "order-modal-height",
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        'init_product': JSON.stringify(this.item),
        'category_id': this.cat_id,
        'amount': this.quantity
      }
    });

    orderModal.onDidDismiss().then((data: any) => {
      if(data.data) {
        this.activated = true;

        setTimeout(() => {
          this.item.opened = false;
          this.activated = false;
          this.quantity = 1;
        }, 2000);
      }
    });

    return await orderModal.present();
  }

}
