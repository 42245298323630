import { Injectable } from '@angular/core';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { Storage } from '@ionic/storage';
import { Config } from '../../config';
import { CustomerService } from './customer.service';
import { Platform, ToastController } from '@ionic/angular';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(
    private push: Push,
    private customerService: CustomerService,
    private storage: Storage,
    private platform: Platform,
    private toastCtrl: ToastController,
    private diagnostic: Diagnostic,
  ) { }

  initPushNotifications() {
    const options: PushOptions = {
      android: {
        senderID: Config.SENDER_ID
      },
      ios: {
        alert: 'true',
        badge: false,
        sound: 'true'
      }
    };
    const pushObject: PushObject = this.push.init(options);

    pushObject.on('registration').subscribe(
      async (data: any) => {
        // get deviceToken and save locally
        await this.storage.set('deviceToken', data.registrationId);

        // if user is logged in, send deviceToken to server
        let login = await this.storage.get('login');
        if(login) {
          if(login.activated) {
            this.customerService.saveDeviceToken(login.id);
          }
        }
      },
      async (error: any) => {}
    );

    pushObject.on('notification').subscribe(async (data: any) => {
      this.customerService.checkLoginMessages();

      let login = await this.storage.get('login');
      if(login.activated) {
        //if user using app and push notification comes
        if (data.additionalData.foreground) {
          this.push.hasPermission().then(async (res: any) => {
            if(res.isEnabled) {

              let disconnectToast;

              if(this.platform.is('ios')) {
                disconnectToast = await this.toastCtrl.create({
                  message: data.message,
                  position: 'top',
                  duration: 10000,
                  cssClass: "toast",
                  buttons: [
                    {
                      text: 'X',
                      role: 'cancel',
                      handler: () => {}
                    }
                  ]
                });
              }
              else if(this.platform.is('android')) {
                disconnectToast = await this.toastCtrl.create({
                  message: data.title,
                  position: 'top',
                  duration: 10000,
                  cssClass: "toast",
                  buttons: [
                    {
                      text: 'X',
                      role: 'cancel',
                      handler: () => {}
                    }
                  ]
                });
              }

              return await disconnectToast.present();
            }
          });
        }
      }
    });

  }
  
  promptPermission() {
    if(this.platform.is('ios')) {
      this.diagnostic.getRemoteNotificationsAuthorizationStatus().then((status) => {
        if(status === this.diagnostic.permissionStatus.NOT_REQUESTED) {
          this.initPushNotifications();
        }
        else if(status === this.diagnostic.permissionStatus.DENIED_ONCE 
          || status === this.diagnostic.permissionStatus.DENIED_ALWAYS) {
          this.diagnostic.switchToSettings();
        }
      });
    }
    else if(this.platform.is('android')) {
      this.diagnostic.getPermissionAuthorizationStatus(this.diagnostic.permission.RECEIVE_WAP_PUSH).then((status) => {
        if(status === this.diagnostic.permissionStatus.NOT_REQUESTED 
          || status === this.diagnostic.permissionStatus.DENIED_ONCE 
          || status === this.diagnostic.permissionStatus.DENIED_ALWAYS) {
          this.diagnostic.switchToSettings();
        }
      });
    }
  }

  async getPermissionForUser(user) {
    return await this.getDevicePermission() && user.allow_push_notifications;
  }

  async getDevicePermission() {
    if(this.platform.is('android')) {
      return await this.diagnostic.isRemoteNotificationsEnabled();
    } else if(this.platform.is('ios')) {
      const status = await this.diagnostic.getRemoteNotificationsAuthorizationStatus();
      return (status === this.diagnostic.permissionStatus.GRANTED
        || status === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE
        || status === 'authorized');
    }
  }

}
