import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import {
  NotDeliverableModalComponent
} from 'src/app/modules/delivery-method/components/not-deliverable-modal/not-deliverable-modal.component';
import { MethodSelectorComponent } from './components/method-selector/method-selector.component';
import { ReadyToOrderModalComponent } from './components/ready-to-order-modal/ready-to-order-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    ComponentsModule,
  ],
  declarations: [
    NotDeliverableModalComponent,
    ReadyToOrderModalComponent,
    MethodSelectorComponent,
  ],
  exports: [
    NotDeliverableModalComponent,
    ReadyToOrderModalComponent,
    MethodSelectorComponent,
  ],
})
export class DeliveryMethodModule { }
