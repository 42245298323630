import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DELIVERY_METHODS } from 'src/app/constants/delivery-methods';
import { MethodSelectorEvents } from 'src/app/modules/delivery-method/events/MethodSelectorEvents';
import { DeliveryAddressService } from 'src/app/modules/delivery-method/services/delivery-address.service';
import { DeliveryTimeStore } from 'src/app/modules/delivery-time/store/delivery-time.store';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';
import { MenuStore } from 'src/app/modules/menu/store/menu.store';
import { DeliveryMethodService } from 'src/app/services/delivery-method.service';
import { DeliveryTimePickerService } from 'src/app/services/delivery-time-picker.service';
import { FirmService } from 'src/app/services/firm.service';
import { ModalService } from 'src/app/services/modal.service';
import { Config } from 'src/config';

@Component({
  selector: 'method-selector',
  templateUrl: './method-selector.component.html',
  styleUrls: ['./method-selector.component.scss'],
})
export class MethodSelectorComponent implements OnInit, OnDestroy {
  @Output() onSelect = new EventEmitter();

  public firm: any = {};
  private selectTakeawayEventSubscriber;
  private selectDeliveryEventSubscriber;

  constructor(
    private firmService: FirmService,
    private firmStore: FirmStore,
    private deliveryMethodService: DeliveryMethodService,
    private storage: Storage,
    private modalService: ModalService,
    private modalController: ModalController,
    private deliveryAddressService: DeliveryAddressService,
    private deliveryTimeStore: DeliveryTimeStore,
    private deliveryTimePickerService: DeliveryTimePickerService,
    private menuStore: MenuStore,
    private methodSelectorEvents: MethodSelectorEvents,
  ) {
    this.firm = { ...this.firmStore.currentFirm };
  }

  ngOnInit(): void {
    this.selectTakeawayEventSubscriber = this.methodSelectorEvents.selectTakeaway.subscribe(
      async (value: boolean) => {
        if (value) {
          await this.selectPickupMethod();
        }
      }
    );

    this.selectDeliveryEventSubscriber = this.methodSelectorEvents.selectDelivery.subscribe(
      async (value: boolean) => {
        if (value) {
          await this.selectDeliveryMethod();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.selectTakeawayEventSubscriber.unsubscribe();
    this.selectDeliveryEventSubscriber.unsubscribe();
  }

  displayPickupMethod() {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return this.deliveryMethodService.isMethodEnabledForMultiFirm(DELIVERY_METHODS.PICKUP);
    }

    return this.deliveryMethodService.isPickupMethodActiveForFirm(this.firm);
  }

  displayDeliveryMethod() {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return this.deliveryMethodService.isMethodEnabledForMultiFirm(DELIVERY_METHODS.DELIVERY);
    }

    return this.deliveryMethodService.isDeliveryMethodActive(this.firm);
  }

  displayPickupPointMethod() {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return false;
    }

    return this.deliveryMethodService.isPickupPointMethodActive(this.firm.pickup, this.firm.pickup_points);
  }

  displayTableMethod() {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return this.deliveryMethodService.isMethodEnabledForMultiFirm(DELIVERY_METHODS.TABLE);
    }

    return this.deliveryMethodService.isTableMethodActive(this.firm);
  }

  disablePickupMethod() {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return false;
    }

    return this.deliveryMethodService.isPickupMethodTemporarilyInactive(this.firm);
  }

  disableDeliveryMethod() {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return false;
    }

    return this.deliveryMethodService.isDeliveryMethodTemporarilyInactive(this.firm);
  }

  disableTableMethod() {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return false;
    }

    return this.deliveryMethodService.isTableMethodTemporarilyInactive(this.firm);
  }

  async selectPickupPointMethod() {
    this.onSelect.emit();

    await this.firmSelected(DELIVERY_METHODS.PICKUP_POINT);

    return this.modalService.openPickPointSelectionModal(this.firm.pickup_points, this.firm, false);
  }

  async selectTableMethod() {
    this.onSelect.emit();

    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      const modal = await this.modalService.openFirmListModal(DELIVERY_METHODS.TABLE);

      modal.onDidDismiss().then(async ({ data }: any) => {
        if (data.cancelled) {
          return;
        }

        this.firm = data.firm;
        return this.selectTableMethodForFirm();
      });

      return;
    }

    return this.selectTableMethodForFirm();
  }

  async selectTableMethodForFirm() {
    await this.firmSelected(DELIVERY_METHODS.TABLE);

    if (this.firm.custom_table_methods && this.firm.custom_table_methods.length > 0) {
      return this.modalService.openTableMethodSelectionModal(this.firm.custom_table_methods, this.firm, false);
    }

    if (this.firm.table_numbers && this.firm.table_numbers.length > 0) {
      await this.storage.set('selectedTableMethod', undefined);

      return this.modalService.openTableNumberSelectionModal(this.firm.table_numbers, this.firm.labels.table, this.firm, false);
    }

    await this.storage.set('selectedTableMethod', undefined);
    await this.storage.set('selectedTableNumber', undefined);

    return this.firmService.navigateToMenu(this.firm);
  }

  async selectDeliveryMethod() {
    this.onSelect.emit();

    const modal = await this.deliveryAddressService.openDeliveryAddressSelector(false);

    modal.onDidDismiss()
      .then(async ({ data }) => {
        if (data.cancelled) {
          return;
        }

        await this.firmSelected(DELIVERY_METHODS.DELIVERY);
      });
  }

  async selectPickupMethod() {
    this.onSelect.emit();

    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      const modal = await this.modalService.openFirmListModal(DELIVERY_METHODS.PICKUP);

      modal.onDidDismiss().then(async ({ data }: any) => {
        if (data.cancelled) {
          return;
        }

        await this.firmSelected(DELIVERY_METHODS.PICKUP);

        return this.openDeliveryDateTimeModal(data.firm);
      });

      return;
    }

    await this.firmSelected(DELIVERY_METHODS.PICKUP);
    return this.openDeliveryDateTimeModal(this.firm);
  }

  async openDeliveryDateTimeModal(firm: any) {
    if (!firm.theme.show_timepicker_modal) {
      await this.deliveryTimePickerService.selectFirstAvailableTime();
      await this.firmService.navigateToMenu(firm);
      return;
    }

    const modal = await this.modalService.openDeliveryDateTimeModal();

    modal.onDidDismiss().then(({ data }: any) => {
      if (data.cancelled) {
        return;
      }

      this.firmService.navigateToMenu(firm);
    });
  }

  async firmSelected(method: string) {
    this.deliveryTimeStore.setDeliveryTimeWithoutEvent(undefined);
    await this.deliveryMethodService.updateSelectedDeliveryMethod(method);

    this.menuStore.viewMenuActive.next(false);
  }

  displayViewMenu() {
    if (Config.IS_MULTIFIRM) {
      return this.firmStore.distributor.theme.show_menu_preview && !this.deliveryMethodService.currentDeliveryMethod;
    }

    return this.firmStore.currentFirm.theme.show_menu_preview && !this.deliveryMethodService.currentDeliveryMethod;
  }

  async viewMenu() {
    let currentFirm = this.firmStore.currentFirm;
    this.menuStore.viewMenuActive.next(true);

    if (Config.IS_MULTIFIRM) {
      await this.storage.set('firm', this.firmStore.firms[0].id);
      currentFirm = await this.firmService.setFirm(this.firmStore.firms[0].id);

      this.firmStore.setCurrentFirm(currentFirm);
    }

    await this.firmService.navigateToMenu(currentFirm);
  }
}
