import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { FirmService } from '../../services/firm.service';

@Component({
  selector: 'app-firm-info',
  templateUrl: './firm-info.page.html',
  styleUrls: ['./firm-info.page.scss'],
})
export class FirmInfoPage implements OnInit {

  @Input() firm: any;

  today_id: any;
  enable_am: boolean = false;
  enable_pm: boolean = false;
  opening_hours: any;

  constructor(public modalCtrl: ModalController,
              public firmService: FirmService,
              private storage: Storage) { }

  async ngOnInit() {
    this.today_id = new Date().getDay();

    if(this.firm) {
      this.firm.periods.opening_hours.forEach(day => {
        if(day.am.type === 'open') {
          this.enable_am = true;
        }
        if(day.pm.type === 'open') {
          this.enable_pm = true;
        }
      });
      this.opening_hours = await this.firmService.setHours(this.firm.periods.opening_hours);
    }
  }

  openFirm() {
    if(this.firm && !this.firm.is_demo) {
      this.modalCtrl.dismiss();
      this.firmService.openFirm(this.firm);
    }
  }

}
