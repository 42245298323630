import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(private storage: Storage) { }

  getUnseenAnnouncements(allAnnouncements): Promise<Array<any>> {
    return new Promise(async (resolve) => {
      let unseenAnnouncements = [];
  
      if(allAnnouncements.length > 0) {
        const seenAnnouncements = await this.getSeenAnnouncements();

        if(seenAnnouncements) {
          await allAnnouncements.forEach(async announcement => {
            const announcementFound = await seenAnnouncements.find(seenAnnouncement => seenAnnouncement.id === announcement.id);
            if(!announcementFound) unseenAnnouncements.push(announcement);
          });
        }
        else unseenAnnouncements = allAnnouncements;
      }

      resolve(unseenAnnouncements);
    });
  }

  async getSeenAnnouncements() {
    return await this.storage.get('seen_announcements');
  }

}
