import { Component, EventEmitter, Input, Output, ViewChild, TemplateRef } from '@angular/core';
import { INGREDIENT_ACTION, INGREDIENT_TYPE } from '../../constants/ingredients';
import { Units } from '../../constants/units';
import { BasketItemsService } from '../../services/basket-items.service';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'app-basket-list-item',
  templateUrl: './basket-list-item.component.html',
  styleUrls: ['./basket-list-item.component.scss']
})
export class BasketListItemComponent {

  @ViewChild('default', { static: true }) defaultTemplate: TemplateRef<any>;
  @ViewChild('shoppingCartStyle2', { static: true }) shoppingCartStyle2: TemplateRef<any>;

  readonly ingredientType = INGREDIENT_TYPE;
  readonly ingredientAction = INGREDIENT_ACTION;
  readonly units = Units;

  @Input() item: any;
  @Input() basketItems: any;
  @Input() firm: any;
  @Input() template: string;
  @Input() price: number;

  @Output() deleteOutput;
  @Output() updateOutput

  basketAmount: number;
  amountAlreadyInCart: number;
  enableNotes: boolean;
  enableEditNote: boolean;
  note: any;
  optionsAndIngredients = [];

  constructor(
    public productsService: ProductsService,
    public basketItemsService: BasketItemsService,
  ) {
    this.deleteOutput = new EventEmitter<string>();
    this.updateOutput = new EventEmitter<string>();
  }

  ngOnInit() {
    this.getBasketAmount();

    // Enable notes box
    if(this.item.comment != undefined) {
      this.note = this.item.comment;
      this.enableNotes = true;
      this.enableEditNote = false;
    }
    else {
      this.enableNotes = false;
      this.enableEditNote = false;
    }
    this.getOptionsAndIngredientsOrderedBySequence();
  }

  getTemplateRef(template) {
    switch(template) {
      case 'style1': return this.defaultTemplate;
      case 'style2': return this.shoppingCartStyle2;
    }
  }

  async getOptionsAndIngredientsOrderedBySequence(){
    let init_item = await JSON.parse(this.item.init_item);
    await this.item.ingredients.forEach(ingr => {
      let ingredient_group = init_item.ingredient_groups.find((ingr_group) => ingr_group.id === ingr.ingredient_group_id);
      if(ingredient_group) {
        this.optionsAndIngredients.push({
          ...ingr,
          sequence: ingredient_group.sequence,
          type: 'ingredient'
        });
      }
    });

    await this.item.product_options.forEach(opt => {
      let option_group = init_item.option_groups.find((opt_group) => opt_group.id === opt.id);
      if(option_group) {
        this.optionsAndIngredients.push({
          ...opt.selected_option,
          sequence: option_group.sequence,
          type: 'option'
        });
      }
    });

    this.optionsAndIngredients = this.optionsAndIngredients.sort((a, b) => a.sequence - b.sequence);
  }

  getIngredient(id){
    let ingredient = this.item.ingredients.find((ingr) => ingr.id === id);
    return ingredient;
  }

  getOption(id){
    let option = this.item.product_options.find((opt) => opt.selected_option.id === id);
    return option;
  }

  ngOnChanges() {
    this.getBasketAmount();
  }

  quantityChanged(event) {
    this.item.amount = event;
    this.updateOutput.emit();
    this.getBasketAmount();
  }

  async setItemAmount(value) {
    this.item.amount = Number(value);
    this.updateOutput.emit();
  }

  removeItem() {
    this.deleteOutput.emit(this.item);
  }

  addComment() {
    if(this.note) {
      this.item.comment = this.note;
      this.enableEditNote = false;
    }
    else {
      this.enableNotes = false;
      this.enableEditNote = true;
    }
  }

  getBasketAmount() {
    this.basketAmount = this.basketItemsService.getTotalBasketAmount(this.firm.id, this.basketItems);
    this.amountAlreadyInCart = this.basketItemsService.getProductAmountFromBasket(this.item.id, this.basketItems);
    this.basketItemsService.getAmount(this.basketItems);
  }

  displayNotes() {
    this.enableNotes = true;
    this.enableEditNote = true;
  }

}