<div class="favorite-products" *ngIf="favorite_products?.length > 0">
  <p class="menu-title">{{ 'FAVORITES.TITLE' | translate }}:</p>
  <ion-slides tappable autoplay="5000" [options]="slideOpts">
    <ion-slide *ngFor="let prod of favorite_products">
      <div tappable class="standard container-shadow" (click)="goToProductPage(prod.item)">
        <button class="fav-btn" (click)="removeFavorite($event, prod)">
          <ion-icon name="star"></ion-icon>
        </button>
        <div class="img img-center" [style.background-image]="'url('+ productsService.getImage(prod.item.images) +')'"></div>
        <div class="cat-info ion-text-center">
          <p class="font-13">{{ prod.item.name }}</p>
        </div>
      </div>
    </ion-slide>
  </ion-slides>
</div>
