import { Component, Input } from '@angular/core';

@Component({
  selector: 'header-notification',
  templateUrl: './header-notification.component.html',
  styleUrls: ['./header-notification.component.scss'],
})
export class HeaderNotificationComponent {
  @Input() text: string;
  @Input() backgroundColor: string;
  @Input() textColor: string;
}
