import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../config';
import { BasketItemsService } from './basket-items.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alert: AlertController,
              private translate: TranslateService,
              private router: Router,
              private iab: InAppBrowser,
              private bis: BasketItemsService) { }


  async presentCustomAlert(message) {
    const alert = await this.alert.create({
      subHeader: message
    });
    return await alert.present();
  }

  async presentConnectionError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.INTERNET_TITLE'),
      subHeader: this.translate.instant('DIALOGS.INTERNET_MESSAGE')
    });

    return await alert.present();
  }

  async presentServerError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.SERVER_TITLE'),
      subHeader: this.translate.instant('DIALOGS.SERVER_MESSAGE'),
    });

    return await alert.present();
  }

  async presentSessionError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.SESSION_EXPIRED'),
      subHeader: this.translate.instant('DIALOGS.SESSION_EXPIRED_MSG'),
    });

    return await alert.present();
  }

  async presentServerNotRespondingError() {
    const alert = await this.alert.create({
      header: 'Server error',
      subHeader: 'Server is not responding, sorry for the inconvenience.'
    });

    return await alert.present();
  }

  async presentCouponAlreadyUsedError() {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('COUPON_CODE_ALREADY_USED')
    });

    return await alert.present();
  }

  async presentUnknownError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        subHeader: this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
    });

    return await alert.present();
  }

  async presentPaymentError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.ERROR'),
      subHeader: this.translate.instant('PAYMENT_ERROR'),
    });

    return alert.present();
  }

  async presentBlockedError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.BLOCKED_TITLE'),
      subHeader: this.translate.instant('DIALOGS.BLOCKED_MESSAGE'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // Set redirect event
            this.router.navigate(['/menu']);
          }
        }
      ]
    });

    return await alert.present();
  }

  async presentInvalidCustomerError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.CUST_INVALID_TITLE'),
      subHeader: this.translate.instant('DIALOGS.CUST_INVALID_MESSAGE'),
    });

    return await alert.present();
  }

  async presentValidationError(errors) {
    var errorStr = "\n";

    for(var key in errors) {
      var value = errors[key];
      errorStr = errorStr + value + '\n'
    }

    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.VALIDATION_TITLE'),
      subHeader: errorStr
    });

    return await alert.present();
  }

  async presentFaultyOrderError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.VALIDATION_TITLE'),
      subHeader: this.translate.instant('DIALOGS.VALIDATION_MESSAGE'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // Set redirect event
            this.router.navigate(['/menu']);
          }
        }
      ]
    });

    return await alert.present();
  }

  async presentMissingSettingsError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.MISSING_SETTINGS_TITLE'),
      subHeader: this.translate.instant('DIALOGS.MISSING_SETTINGS_MESSAGE_ORDER'),
    });

    return await alert.present();
  }

  async presentError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.MISSING_SETTINGS_TITLE'),
      subHeader: this.translate.instant('DIALOGS.MISSING_SETTINGS_MESSAGE_ORDER'),
    });

    return await alert.present();
  }

  async presentDeliveryTooFarError() {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('DIALOGS.TOO_FAR'),
      buttons: [{ text: 'Ok', handler: () => {}}]
    });

    return await alert.present();
  }

  async presentCalculateDistanceError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.DISTANCE_TITLE'),
      subHeader: this.translate.instant('DIALOGS.DISTANCE_MESSAGE'),
      buttons: [{ text: 'Ok', handler: () => {}}]
    });

    return await alert.present();
  }

  async presentInvalidPaymentError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.INVALID_PAYMENT_TITLE'),
      subHeader: this.translate.instant('DIALOGS.INVALID_PAYMENT_MESSAGE'),
    })

    return await alert.present();
  }

  async presentPasswordConfirmError() {
    const alert = await this.alert.create({
      header: this.translate.instant('PASSWORD_CONFIRM_TITLE'),
      subHeader: this.translate.instant('PASSWORD_CONFIRM_MSG'),
    })

    return await alert.present();
  }

  async presentFirmNotFoundError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.FIRM_NOT_FOUND_TITLE'),
      subHeader: this.translate.instant('DIALOGS.FIRM_NOT_FOUND_MESSAGE'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            if(!Config.SINGLE_APP) {
              this.router.navigate(['/container-menu']);
            }
            else if(Config.SINGLE_APP) {
              // Todo
            }
          }
        }
      ]
    });

    return await alert.present();
  }

  async presentOrderNotFoundError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.ORDER_NOT_FOUND_TITLE'),
      subHeader: this.translate.instant('DIALOGS.ORDER_NOT_FOUND_MESSAGE'),
    });

    return await alert.present();
  }

  async presentLoginNotFoundOrderError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.LOGIN_NOT_FOUND_TITLE'),
      subHeader: this.translate.instant('DIALOGS.LOGIN_NOT_FOUND_MSG'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {}
        }
      ]
    });

    return await alert.present();
  }

  async presentCustomerNotFoundOrderError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.CUST_NOT_FOUND_TITLE'),
      subHeader: this.translate.instant('DIALOGS.CUST_NOT_FOUND_MESSAGE_ORDER'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {}
        }
      ]
    });

    return await alert.present();
  }

  async presentCustomerNotActivatedError() {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('DIALOGS.CUST_NOT_ACTIVATED'),
    });

    return await alert.present();
  }

  async presentShopOfflineError(message) {
    const alert = await this.alert.create({
      subHeader: message,
    });
    return await alert.present();
  }

  async presentCustomerNotActivatedRedirectError() {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('DIALOGS.CUST_NOT_ACTIVATED'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // Set redirect event
            this.router.navigate(['/menu']);
          }
        }
      ]
    });

    return await alert.present();
  }

  async presentInvalidProductsError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.INVALID_PRODUCT_TITLE'),
      subHeader: this.translate.instant('DIALOGS.INVALID_PRODUCT_MESSAGE'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // Set redirect event
            this.router.navigate(['/menu']);
          }
        }
      ]
    });

    return await alert.present();
  }

  async presentNoPickupError() {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('DIALOGS.PICKUP_MESSAGE')
    });

    return await alert.present();
  }

  async presentNoDeliveryError() {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('DIALOGS.DELIVERY_MESSAGE')
    });

    return await alert.present();
  }

  async presentNoTableError() {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('DIALOGS.TABLE_MESSAGE')
    });

    return await alert.present();
  }

  async presentInvalidCouponError() {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('INVALID_COUPON')
    })

    return await alert.present();
  }

  async presentMinDeliveryPriceError(firm, min_price) {
    const alert = await this.alert.create({
      subHeader: this.translate.instant('DIALOGS.MIN_DELIVERY_PRICE', { firm_valuta: firm.currency.valuta, min_order_price: min_price }),
    });

    return await alert.present();
  }

  async presentZipcodeError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.ZIPCODE_TITLE'),
      subHeader: this.translate.instant('DIALOGS.ZIPCODE_MESSAGE'),
    });

    return await alert.present();
  }

  async presentInvalidTimestampError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.DATETIME_TITLE'),
      subHeader: this.translate.instant('DIALOGS.DATETIME_MESSAGE'),
    });

    return await alert.present();
  }

  async presentInvalidOrderError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.ERROR'),
      subHeader: this.translate.instant('DIALOGS.INVALID_ORDER_MESSAGE'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // Set redirect event
            this.router.navigate(['/menu']);
          }
        }
      ]
    });

    return await alert.present();
  }

  async presentUpdateError(data) {
    const alert = await this.alert.create({
      backdropDismiss: false,
      header: "Update (" + data.latest_version + ")",
      subHeader: "An update is needed to use the app.",
      buttons: [
        {
          text: 'Update',
          handler: () => {
            this.iab.create(data.app_url, '_system');
          }
        }
      ]
    });

    return await alert.present();
  }

  async presentIncorrectPasswordError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DEMO_PASSWORD_INCORRECT'),
      subHeader: this.translate.instant('PASSWORD_INCORRECT'),
    });
    return await alert.present();
  }

  async showPasswordRequiredError() {
    const alert = await this.alert.create({
      header: this.translate.instant('VALIDATION_ERROR'),
      subHeader: this.translate.instant('PASSWORD_REQUIRED'),
    });
    return await alert.present();
  }

  async presentPaymentSucceeded(reference) {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.PAYMENT_SUCCEEDED_TITLE'),
      subHeader: this.translate.instant('DIALOGS.PAYMENT_SUCCEEDED_MESSAGE2', { order_reference: reference }),
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // Delete order & basketItems
            this.bis.removeAll();
          }
        }
      ]
    });
    return await alert.present();
  }

  async presentPaymentStatusError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.PAYMENT_STATUS_TITLE'),
      subHeader: this.translate.instant('DIALOGS.PAYMENT_STATUS_MESSAGE'),
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            // Delete order & basketItems
            this.bis.removeAll();
          }
        }
      ]
    });
    return await alert.present();
  }

  async presentCustomerNotFoundError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.CUST_NOT_FOUND_TITLE'),
      subHeader: this.translate.instant('DIALOGS.CUST_NOT_FOUND_MESSAGE')
    });
    return await alert.present();
  }

  async presentGoogleError() {
    const alert = await this.alert.create({
      header: this.translate.instant('GOOGLE_ERROR_TITLE'),
      subHeader: this.translate.instant('GOOGLE_ERROR_MSG')
    });
    return await alert.present();
  }

  async presentApplePermissionError() {
    const alert = await this.alert.create({
      header: this.translate.instant('APPLE_PERMISSION_TITLE'),
      subHeader: this.translate.instant('APPLE_PERMISSION_MSG')
    });
    return await alert.present();
  }

  async presentAppleValidationError() {
    const alert = await this.alert.create({
      header: this.translate.instant('APPLE_ERROR_TITLE'),
      subHeader: this.translate.instant('APPLE_ERROR_MSG')
    });
    return await alert.present();
  }

  async showMaintenanceError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.MAINTENANCE_TITLE'),
      subHeader: this.translate.instant('DIALOGS.MAINTENANCE_MESSAGE')
    });
    return await alert.present();
  }

  async presentBasketQuantityError(message) {
    const alert = await this.alert.create({
      header: this.translate.instant('MAX_AMOUNT_EXCEEDED_ERR'),
      subHeader: message
    });
    return await alert.present();
  }

  async presentCountryError() {
    const alert = await this.alert.create({
      header: this.translate.instant('DIALOGS.ERROR'),
      subHeader: this.translate.instant('COUNTRY_ERR')
    });
    return await alert.present();
  }

}
