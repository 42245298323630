import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PickupPointStore {
  public pickupPoint: Record<any, any>|null = null;
  public updateEvent = new BehaviorSubject(null);

  private readonly KEY = 'selectedPickupPoint';

  constructor(
    private storage: Storage,
  ) { }

  public async setSelectedPickupPoint<T>(pickupPoint: T): Promise<T> {
    this.pickupPoint = pickupPoint;
    this.updateEvent.next(pickupPoint);

    return this.storage.set(this.KEY, pickupPoint);
  }

  public async getSelectedPickupPoint<T>(): Promise<T> {
    if (this.pickupPoint) {
      return this.pickupPoint;
    }

    return this.storage.get(this.KEY);
  }
}
