<p style="padding: 10px 0 5px 5px">{{ 'SEARCH_RESULTS' | translate }}:</p>

<div *ngFor="let cat of found_categories">

  <!-- Normal categories -->
  <div class="search-box" *ngIf="cat?.products">
    <p>{{ cat.category_name }}</p>
    <p class="result" *ngIf="cat?.products.length != 1">{{ cat?.products.length }} {{ 'RESULTS' | translate }}</p>
    <p class="result" *ngIf="cat?.products.length === 1">{{ cat?.products.length }} {{ 'RESULT' | translate }}</p>

    <!-- Parent category unavailable -->
    <eo-unavailable-message
      *ngIf="firm?.only_show_available_parent_categories && !isCategoryAvailable(cat)"
      [categoryName]="cat.category_name"
      [dayName]="(getAvailabilities(cat) | firstAvailableDate:currentDeliveryTime)?.day_name"
      [toTime]="(getAvailabilities(cat) | firstAvailableDate:currentDeliveryTime)?.from_time">
    </eo-unavailable-message>

    <app-item *ngFor="let item of cat.products" [item]="item" [opened]="item.opened" [firm]="firm" detail="false"></app-item>
  </div>

  <!-- Subcategories -->
  <div *ngIf="cat?.subcategories">
    <div *ngFor="let subcat of cat?.subcategories">
      <div class="search-box" *ngIf="subcat?.products">
        <p>{{ cat.category_name }} - {{ subcat.category_name }}</p>
        <p class="result" *ngIf="subcat?.products.length != 1">{{ subcat?.products.length }} {{ 'RESULTS' | translate }}</p>
        <p class="result" *ngIf="subcat?.products.length === 1">{{ subcat?.products.length }} {{ 'RESULT' | translate }}</p>

        <!-- Parent category unavailable -->
        <eo-unavailable-message
          *ngIf="firm?.only_show_available_parent_categories && !isCategoryAvailable(cat)"
          [categoryName]="cat.category_name"
          [dayName]="(getAvailabilities(cat) | firstAvailableDate:currentDeliveryTime)?.day_name"
          [toTime]="(getAvailabilities(cat) | firstAvailableDate:currentDeliveryTime)?.from_time">
        </eo-unavailable-message>

        <app-item *ngFor="let item of subcat.products" [item]="item" [opened]="item.opened" [firm]="firm" detail="false"></app-item>
      </div>
    </div>
  </div>

</div>

<div *ngIf="found_categories.length === 0">
  <div class="no-result">
    <i class="far fa-frown icon"></i>
    <p>{{ 'NO_RESULTS' | translate }}
    </p>
  </div>

  <div>
    <p class="other-products">{{ 'OTHER_PRODUCTS' | translate }}:</p>
    <ion-list>
      <div *ngFor="let category of all_categories">

        <!-- Category -->
        <div class="category-box"
            *ngIf="category.products.length > 0">
          <p class="font-16">{{ category.name }}</p>
          <app-item *ngFor="let item of category?.products" [opened]="item.opened" [item]="item" [firm]="firm" detail="false"></app-item>
        </div>

        <!-- Subcategory -->
        <div *ngIf="category?.subcategories">
          <div *ngFor="let subcat of category?.subcategories">
            <div class="category-box" *ngIf="subcat?.products">
              <p class="font-16">{{ category.name }} - {{ subcat.name }}</p>
              <app-item *ngFor="let item of subcat.products" [item]="item" [opened]="item.opened" [firm]="firm" detail="false"></app-item>
            </div>
          </div>
        </div>

      </div>
    </ion-list>
  </div>
</div>
