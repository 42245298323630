<div class="featured-categories" *ngIf="featured_categories?.length > 0">
  <p class="menu-title">{{ 'TOP_CATEGORIES' | translate }}:</p>
  <ion-slides tappable autoplay="5000" [options]="slideOpts">
    <ion-slide *ngFor="let cat of featured_categories">
      <div tappable
          class="standard container-shadow"
          (click)="goToCategoryPage(cat)">
        <div class="img img-center" [style.background-image]="'url('+ productsService.getImage(cat.images) +')'"></div>
        <div class="cat-info ion-text-center">
          <p class="name font-13">{{ cat.name }}</p>
          <p class="amount font-12 ion-text-wrap" *ngIf="cat.productsCount > 1">{{ cat.productsCount}} {{ 'PRODUCTS' | translate }}</p>
          <p class="amount font-12 ion-text-wrap" *ngIf="cat.productsCount === 1">{{ cat.productsCount}} {{ 'PRODUCT' | translate }}</p>
        </div>
      </div>
    </ion-slide>
  </ion-slides>
</div>
