<div class="list-item container-shadow" tappable (click)="firmService.openFirm(firm)">
  <ion-row *ngIf="!firm.distance_to_client">
    <ion-col size="1" class="ion-text-center">
      <i class="fas fa-store store-icon v-align"></i>
    </ion-col>

    <ion-col size="9" class="info">
      <p class="firm-name font-13">{{ firm?.name }}</p>
      <p class="address font-12">{{ firm?.address }},</p>
      <p class="address font-12">{{ firm?.zipcode }} {{ firm?.locality }}</p>
    </ion-col>

    <ion-col size="2" class="ion-text-center" tappable (click)="$event.stopPropagation(); openInfoModal()">
      <i class="fas fa-info-circle icon v-align font-20"></i>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="firm.distance_to_client">
    <ion-col size="1" class="ion-text-center">
      <i class="fas fa-store store-icon v-align"></i>
    </ion-col>

    <ion-col size="6" class="info">
      <p class="firm-name font-13">{{ firm?.name }}</p>
      <p class="address font-12">{{ firm?.address }},</p>
      <p class="address font-12">{{ firm?.zipcode }} {{ firm?.locality }}</p>
    </ion-col>

    <ion-col size="3" class="ion-text-center">
      <div class="v-align" style="margin-top: 5px">
        <i class="fas fa-map-marker-alt v-align font-20"></i>
        <p class="distance">{{ firm?.distance_to_client | number:'1.1-1' }} km</p>
      </div>
    </ion-col>
    <ion-col size="2" class="ion-text-center" tappable (click)="$event.stopPropagation(); openInfoModal()">
      <i class="fas fa-info-circle icon v-align font-20"></i>
    </ion-col>
  </ion-row>
</div>
