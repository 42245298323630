<!-- Enter coupon -->
<div class="coupon-input" *ngIf="enterCoupon">
  <ion-input class="v-align" [(ngModel)]="couponCode" name="coupon_code" placeholder="e.g. EO2020"></ion-input>
  <ion-button class="custom-btn" *ngIf="!errorMsg" (click)="checkCoupon()" [disabled]="!couponCode">{{ 'CHECK_CODE' | translate }}</ion-button>
  <ion-button class="custom-btn" *ngIf="errorMsg" (click)="checkCoupon()">{{ 'RETRY' | translate }}</ion-button>
</div>

<!-- Result -->
<div *ngIf="hasSuccesfullCouponResult()">

  <!-- Result -->
  <div class="coupon-result">

    <!-- Coupon code -->
    <ion-input [(ngModel)]="couponCode" disabled="true"></ion-input>

    <!-- Percentage / amount -->
    <div *ngIf="coupon?.type === 'percentage' || coupon?.type === 'amount'">
      <span *ngIf="coupon?.type === 'percentage'">-{{ coupon?.value }}% ({{ currency }}{{ (cartTotal/100) * coupon?.value | number:'1.2-2' }})</span>
      <span *ngIf="coupon?.type === 'amount'">-{{ currency }}{{ coupon?.value | number:'1.2-2' }}</span>
    </div>

    <!-- Remove -->
    <p tappable (click)="removeCoupon()">{{ 'REMOVE' | translate }}</p>
  </div>

  <!-- Free product -->
  <div *ngIf="coupon?.type === 'product'" class="coupon-product">
    <p class="item-name ion-text-wrap ion-no-margin">{{ coupon?.freeProduct?.name }} {{ 'VALUE_OF' | translate }} {{ currency }}{{ coupon?.freeProduct?.price }}</p>
  </div>

  <!-- Multiple products -->
  <div *ngIf="coupon?.type === 'category'" class="coupon-product multiple" tappable (click)="productSelector.open()">
    <div class="text">
      <p class="item-name ion-text-wrap ion-no-margin">{{ coupon?.selectedCouponProduct?.name }} {{ 'VALUE_OF' | translate }} {{ currency }}{{ coupon?.selectedCouponProduct?.price }}</p>
      <p class="free-product">{{ 'CHOOSE_OTHER_PRODUCT' | translate }}</p>
    </div>
    <i class="far fa-hand-pointer click-icon"></i>

    <!-- Category choice -->
    <ion-select #productSelect class="product-select" [(ngModel)]="coupon.selectedCouponProduct" [interfaceOptions]="interfaceOptions">
      <ion-select-option *ngFor="let product of coupon?.freeProducts" [value]="product" >{{ product.name }}</ion-select-option>
    </ion-select>
  </div>

</div>

<!-- Line -->
<hr *ngIf="enterCoupon && !errorMsg" class="line-grey">
<hr *ngIf="errorMsg" class="line-red">
<hr *ngIf="!enterCoupon" class="line-green">

<!-- Errors -->
<p *ngIf="errorMsg" class="coupon-err">{{ errorMsg }}</p>
<p *ngIf="couponRemovedByCartChange" class="coupon-err">{{ 'COUPON_REMOVED_BY_CART_CHANGE' | translate }}</p>

<!-- Messages -->
<p *ngIf="hasSuccesfullCouponResult() && hasUsageLeftOnCoupon()" class="coupon-msg">{{ 'COUPON_USAGE_LEFT' | translate:{amount: coupon?.usageLeft} }}</p>
