import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DELIVERY_METHODS } from 'src/app/constants/delivery-methods';
import { CustomerStore } from 'src/app/modules/customer/store/customer.store';
import { DeliveryTimeStore } from 'src/app/modules/delivery-time/store/delivery-time.store';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';
import { MenuStore } from 'src/app/modules/menu/store/menu.store';
import { DeliveryMethodService } from 'src/app/services/delivery-method.service';
import { DeliveryTimePickerService } from 'src/app/services/delivery-time-picker.service';
import { FirmService } from 'src/app/services/firm.service';
import { ModalService } from 'src/app/services/modal.service';
import { Config } from 'src/config';

@Injectable({
  providedIn: 'root',
})
export class DeliveryAddressService {
  private readonly firm: any = {};

  constructor(
    private modalService: ModalService,
    private customerStore: CustomerStore,
    private loadingController: LoadingController,
    private firmService: FirmService,
    private firmStore: FirmStore,
    private menuStore: MenuStore,
    private storage: Storage,
    private deliveryMethodService: DeliveryMethodService,
    private deliveryTimeStore: DeliveryTimeStore,
    private deliveryTimePickerService: DeliveryTimePickerService,
  ) {
    this.firm = { ...this.firmStore.currentFirm };
  }

  public async openDeliveryAddressSelector(showSwitcher: boolean) {
    const modal = await this.modalService.openAddressModal(
      this.customerStore.addresses,
      false,
      false,
      showSwitcher,
      this.customerStore.selectedAddress,
    );

    modal
      .onDidDismiss()
      .then(async ({ data }: any) => {
        if (data.cancelled) {
          return;
        }

        let firm: any;
        const loader = await this.loadingController.create({
          message: '',
        });

        await loader.present();

        if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
          const response = await this.firmService.getFirmByAddress(data);

          if (!response) {
            return loader.dismiss();
          }

          await this.storage.set('firm', (response as any).data.id);
          firm = await this.firmService.getFirmData();
        }

        this.customerStore.setSelectedAddress(data);

        await this.deliveryMethodService.updateSelectedDeliveryMethod(DELIVERY_METHODS.DELIVERY);

        await loader.dismiss();

        if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
          await this.firmService.setFirm(firm.id);
          return this.openDeliveryDateTimeModal(firm);
        }

        return this.openDeliveryDateTimeModal(this.firm);
      });

    return modal;
  }

  private async openDeliveryDateTimeModal(firm: any) {
    if (this.deliveryTimeStore.currentDeliveryTime$.getValue() && Config.SINGLE_APP) {
      return this.redirectToFirm(firm);
    }

    if (!firm.theme.show_timepicker_modal) {
      await this.deliveryTimePickerService.selectFirstAvailableTime();
      return this.redirectToFirm(firm);
    }

    const modal = await this.modalService.openDeliveryDateTimeModal();

    modal
      .onDidDismiss()
      .then(() => this.redirectToFirm(firm));
  }

  private redirectToFirm(firm: any) {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return this.firmService.openFirm(firm);
    }

    return this.firmService.navigateToMenu(firm);
  }
}
