import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eo-round-button',
  templateUrl: './round-button.component.html',
  styleUrls: ['./round-button.component.scss'],
})
export class RoundButtonComponent {
  @Input() iconName: string;
  @Output() clicked = new EventEmitter<void>();

  clickedHandler() {
    this.clicked.emit();
  }
}
