<div tappable class="standard container-shadow" (click)="goToFeaturedProduct(item)">
  <div class="img img-center" [style.background-image]="'url('+ item?.images[0].detail +')'"></div>
  <div class="cat-info ion-text-center">
    <p class="name font-13">{{ item?.name }}</p>
    <div>
      <p class="amount font-12" *ngIf="!item?.has_promotional_price">{{ firm?.currency.symbol }}{{ item?.price | number:'1.2-2' }}</p>
      <p class="amount font-12" *ngIf="item?.has_promotional_price">{{ firm?.currency.symbol }}{{ item?.promotion.price | number:'1.2-2' }}</p>
    </div>
  </div>
</div>
