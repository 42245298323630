import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AddressFormComponent } from 'src/app/modules/customer/components/address-form/address-form.component';
import { DeliveryTimeModule } from 'src/app/modules/delivery-time/delivery-time.module';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule,
    TranslateModule,
    DeliveryTimeModule,
  ],
  declarations: [
    AddressModalComponent,
    AddressFormComponent,
  ],
  exports: [
    AddressModalComponent,
    AddressFormComponent,
  ]
})
export class CustomerModule { }
