import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { DeliveryTimeStore } from 'src/app/modules/delivery-time/store/delivery-time.store';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.page.html',
  styleUrls: ['./timepicker.page.scss'],
})
export class TimepickerPage implements OnInit {
  timestamp: any;
  minTime: any;
  maxTime: any;
  chosen_option: any;
  isToday: boolean;

  constructor(
    private navParams: NavParams,
    private deliveryTimeStore: DeliveryTimeStore,
    private modalCtrl: ModalController
  ) { }

  async ngOnInit() {
    this.timestamp = await this.navParams.get('timestamp');
    this.isToday = await this.navParams.get('isToday');

    if (this.isToday) {
      this.minTime = await this.navParams.get('minTime');
      this.maxTime = await this.navParams.get('maxTime');
    }
  }

  ionViewWillEnter() {
    this.chosen_option = this.deliveryTimeStore.currentDeliveryTime$.getValue() === 'asap' ? 'asap' : 'custom';
  }

  chooseTime(option) {
    this.chosen_option = option;
  }

  async saveTime() {
    let data = this.timestamp;

    if (this.chosen_option === 'asap') {
      data = 'asap';
    }

    await this.modalCtrl.dismiss(data);
  }
}
