import { Injectable } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { TagService } from './tag.service';
import { AddToCartService } from './add-to-cart.service';
import { Units } from '../constants/units';

@Injectable({
  providedIn: 'root'
})
export class BasketItemsService {

  basketAmount: any;
  basketItems: any;

  constructor(private storage: Storage,
              private addToCartService: AddToCartService,
              private toastCtrl: ToastController,
              private translate: TranslateService,
              private vibration: Vibration,
              private tagService: TagService) {
                this.getAmount();
              }

  /* Getter */
  async get() {
    const allBasketItems = await this.storage.get('basketItems');

    if (allBasketItems === null || allBasketItems === undefined ) {
      return [];
    }

    const firmId = await this.storage.get('firm');

    return allBasketItems.filter((basketObj) => basketObj.firm_id === firmId);
  }

  getNumberOfItemsInTheBasket(): number {
    const firmBasketItems = this.basketItems;

    return firmBasketItems.reduce((prev, current) => prev + current.item.amount, 0);
  }

  /* Save basket item to storage */
  async add(item: any, firm) {
    return new Promise(async (resolve) => {
      if(firm.group_same_products_for_order) {
        item = await this.groupIdenticalProducts(item);
      }

      let basketItems = await this.storage.get('basketItems');
      let firm_id = await this.storage.get('firm');
      let basketObj = {
        firm_id: firm_id,
        item: item
      }

      if(basketItems === null || basketItems === undefined) {
        basketItems = [];
      }

      basketItems.push(basketObj);
      await this.storage.set('basketItems', basketItems);
      await this.getAmount();
      this.addToCartService.cartAnimation.next();
      this.tagService.addEvent('eo_app_add_item_to_cart');
      resolve('');
    });
  }

  /* Remove item from basket */
  async remove(basketItems) {
    await this.removeAllProducts();
    let allBasketItems = await this.storage.get('basketItems');

    basketItems.forEach(item => {
      allBasketItems.push(item);
    });

    await this.storage.set('basketItems', allBasketItems);
    this.getAmount();
  }

  async updateBasket(basketItems) {
    await this.removeAllProducts();
    let allBasketItems = await this.storage.get('basketItems');
    basketItems.forEach(item => {
      allBasketItems.push(item);
    });
    await this.storage.set('basketItems', allBasketItems);
    this.getAmount();
  }

  removeAll() {
    this.removeAllProducts();
  }

  /* Remove all items from basket */
  async removeAllProducts() {
    let firm_id = await this.storage.get('firm');

    // ITEMS
    this.basketItems = await this.storage.get('basketItems');

    if(this.basketItems) {
      // Backward iteration for deleting all items (foreach doesn't work with splice)
      for (var i = this.basketItems.length - 1; i >= 0; i--) {
        if(this.basketItems[i].firm_id === firm_id) {
          let index = this.getIndex(this.basketItems, this.basketItems[i]);
          // remove item
          if(index > -1) {
            this.basketItems.splice(index, 1);
          }
        }
      }
    }
    await this.storage.set('basketItems', this.basketItems);

    this.getAmount();
  }

  getIndex(basketItems, item: any) {
    return basketItems.indexOf(item);
  }

  /* Get basket amount */
  async getAmount(basketItems?) {
    if(basketItems) {
      this.basketItems = basketItems;
    }
    else {
      this.basketItems = await this.get();
    }

    var amount = 0;

    this.basketItems.forEach(bi => {
      if(bi.item.unit_id !== Units.KG && bi.item.unit_id !== Units.GRAM) {
        amount = Number(amount) + Number(bi.item.amount);
      }
      else {
        amount = Number(amount) + 1;
      }
    });

    this.setAmount(amount);
  }

  setAmount(amount) {
    this.basketAmount = amount;
  }

  setIngredientTypes(ingredients) {
    ingredients.forEach(ingr => {
      if(ingr.type === 'paid') {
        ingr.action = "add";
      }
      else if (ingr.type === 'main') {
        ingr.action = "add";
        ingr.selected = 1;
      }
    });

    return ingredients;
  }

  async groupIdenticalProducts(item) {
    var group = false;
    var basket_item;

    return new Promise(async (resolve) => {
      let basketItems = await this.storage.get('basketItems');

      if(basketItems && basketItems.length > 0) {
        await basketItems.forEach(async bi => {
          if( bi.item.id === item.id && bi.item.comment === item.comment) {

            var ingredients = true;
            if(JSON.stringify(bi.item.ingredients) != JSON.stringify(item.ingredients)) {
              ingredients = false;
            }

            var options = true;
            if(JSON.stringify(bi.item.product_options) != JSON.stringify(item.product_options)) {
              options = false;
            }

            if(ingredients && options) {
              item.amount = await Number(item.amount) + Number(bi.item.amount);

              if(item.has_promotional_price) {
                item.total_price = Number(item.item.promotion.price) * Number(item.amount);
              }
              else {
                item.total_price = Number(item.item.price) * Number(item.amount);
              }

              group = true;
              basket_item = bi;
            }
          }
        });
      }

      if(group) {
        // remove old item
        let index = await this.getIndex(basketItems, basket_item);
        if(index > -1) {
          basketItems.splice(index, 1);
        }

        await this.remove(basketItems);
      }

      resolve(item);
    });
  }

  getTotalBasketAmount(firmId, basketItems) {
    let basketAmount = 0;

    if(basketItems && basketItems.length > 0) {
      basketItems.forEach(basketItem => {
        if(basketItem.firm_id === firmId) {
          if (basketItem.item.unit_id !== Units.KG && basketItem.item.unit_id !== Units.GRAM) {
            basketAmount += Number(basketItem.item.amount);
          } else basketAmount += 1;
        }
      });
    }

    return basketAmount;
  }

  getProductAmountFromBasket(productId, basketItems) {
    let basketAmount = 0;

    if(basketItems && basketItems.length > 0) {
      const productsInCart = basketItems.filter(item => item.item.id === productId);
      if(productsInCart && productsInCart.length > 0) {
        productsInCart.forEach(product => {
          if (product.item.unit_id !== Units.KG && product.item.unit_id !== Units.GRAM) {
            basketAmount += Number(product.item.amount);
          } else basketAmount += 1;
        });
      }
    }

    return basketAmount;
  }

  isMaxItemAmountExceeded(max, itemAmount) {
    return max ? itemAmount >= max : false;
  }

  isMaxCartAmountExceeded(max, cartAmount) {
    return max ? cartAmount >= max : false;
  }

  /* Order */
  async setInitBasketItem(item_amount, item, firm, init_product, totalPrice, ingredients, message, options_selected) {
    var basketItem = {
      id: item.id,
      cat_id: item.category.category_id,
      item: item,
      init_item: init_product,
      name: item.name,
      amount: item_amount,
      total_price: totalPrice,
      ingredients: ingredients,
      comment: message,
      unit_id: item.unit.id,
      product_options: options_selected,
      basketItem_price: item.price
    } as any;

    // Get price of product
    if(item.has_promotional_price) {
      basketItem.total_price = item.promotion.price;
      basketItem.basketItem_price = item.promotion.price;
    }

    return basketItem;
  }

  async addToBasket(originalBasketItem, ingredients, amount, totalPrice, options_selected, firm, index?, has_promotional_price?) {
    ingredients = await this.setIngredientTypes(ingredients);

    // Make the new item
    let basketItem = {
      id: originalBasketItem.id,
      cat_id: originalBasketItem.cat_id,
      item: originalBasketItem.item,
      init_item: originalBasketItem.init_item,
      name: originalBasketItem.name,
      amount: Number(amount),
      basketItem_price: originalBasketItem.basketItem_price,
      total_price: totalPrice,
      ingredients: ingredients,
      comment: originalBasketItem.comment,
      unit_id: originalBasketItem.unit_id,
      product_options: options_selected,
    } as any;

    // Show toast
    this.add(basketItem, firm)
      .then(() => {
        this.vibration.vibrate(500);
      })
      .catch(async () => {
        let toast = await this.toastCtrl.create({
          message: this.translate.instant('DIALOGS.ADD_BASKET_ERROR'),
          duration: 2000,
          position: 'top'
        });
        return await toast.present();
      });
  }
}
