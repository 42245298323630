<ion-content>
  <eo-modal
    [submitText]="'CHOOSE' | translate"
    (submitted)="handleOnSubmit()"
  >
    <ion-grid content class="full-width">
      <ion-row>
        <ion-col size="auto">
          <i class="fas fa-store-alt font-20"></i>
        </ion-col>
        <ion-col size="auto" class="font-15">
          <strong>{{ 'CHOOSE_LOCATION' | translate }}</strong>
        </ion-col>
      </ion-row>
      <ion-row class="shop-list">
        <ion-col>
          <ion-radio-group [(ngModel)]="selectedFirm">
            <div *ngFor="let firm of firms">
              <div
                class="firm-select flex ion-justify-content-between ion-align-items-center"
                tappable
                (click)="selectFirm(firm.id)"
                *ngIf="!firm.is_offline && firmHasDeliveryMethod(firm)"
              >
                <div>
                  <span>{{ firm.name }}</span><br />
                  <span class="font-10">({{ firm.address }}, {{ firm.zip_code }} {{ firm.locality }})</span>
                </div>
                <ion-radio mode="md" slot="end" [value]="firm.id"></ion-radio>
              </div>
            </div>
          </ion-radio-group>
        </ion-col>
      </ion-row>
    </ion-grid>
  </eo-modal>
</ion-content>
