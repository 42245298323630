import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SubHeaderComponent } from 'src/app/components/sub-header/sub-header.component';
import { DeliveryTimeModule } from 'src/app/modules/delivery-time/delivery-time.module';
import { DirectivesModule } from '../directives/directives.module';
import { BasketListItemComponent } from './basket-list-item/basket-list-item.component';
import { FeaturedCategoriesComponent } from './featured-categories/featured-categories.component';
import { FeaturedProductComponent } from './featured-product/featured-product.component';
import { FeaturedProductsComponent } from './featured-products/featured-products';
import { FirmsBlockItemComponent } from './firms-block-item/firms-block-item.component';
import { FirmsListItemComponent } from './firms-list-item/firms-list-item.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { HeaderComponent } from './header/header.component';
import { ItemComponent } from './item/item';
import { LanguageComponent } from './language/language';
import { LoyaltyItemComponent } from './loyalty-item/loyalty-item.component';
import { OrderComponent } from './order/order';
import { PoweredByComponent } from './powered-by/powered-by';
import { ReservationComponent } from './reservation/reservation';
import { SearchComponent } from './search/search.component';
import { ShopMessageComponent } from './shop-message/shop-message';
import { FavoriteProductsComponent } from './favorite-products/favorite-products.component';
import { PipesModule } from '../pipes/pipes.module';
import { TooltipsModule } from 'ionic-tooltips';
import { NavSliderComponent } from './nav-slider/nav-slider.component';
import { CouponComponent } from './coupon/coupon.component';
import { OptionGroupComponent } from './option-group/option-group.component';
import { RouterModule } from '@angular/router';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { UnitSelectorComponent } from './quantity-selector/unit-selector.component';
import { QuantitySelectorComponent } from './quantity-selector/quantity-selector.component';
import { WeightSelectorComponent } from './quantity-selector/weight-selector.component';
import { SquareBtnComponent } from './square-btn/square-btn.component';
import { ChangeDeliveryMethodComponent } from './change-delivery-method/change-delivery-method.component';
import { OutOfStockLabelComponent } from './out-of-stock-label/out-of-stock-label.component';
import { SharedModule } from '../shared/shared.module';
import { ModifierComponent } from './modifier/modifier.component';
import { SnoozedLabelComponent } from './snoozed-label/snoozed-label.component';

@NgModule({
  declarations: [
    ItemComponent,
    LanguageComponent,
    OrderComponent,
    FeaturedProductsComponent,
    FeaturedCategoriesComponent,
    ShopMessageComponent,
    PoweredByComponent,
    ReservationComponent,
    FirmsListItemComponent,
    FirmsBlockItemComponent,
    HamburgerComponent,
    BasketListItemComponent,
    LoyaltyItemComponent,
    HeaderComponent,
    SubHeaderComponent,
    FeaturedProductComponent,
    SearchComponent,
    FavoriteProductsComponent,
    NavSliderComponent,
    CouponComponent,
    OptionGroupComponent,
    SkeletonComponent,
    QuantitySelectorComponent,
    UnitSelectorComponent,
    WeightSelectorComponent,
    SquareBtnComponent,
    ChangeDeliveryMethodComponent,
    OutOfStockLabelComponent,
    ModifierComponent,
    SnoozedLabelComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    DirectivesModule,
    PipesModule,
    TooltipsModule.forRoot(),
    RouterModule,
    SharedModule,
    DeliveryTimeModule,
  ],
  exports: [
    ItemComponent,
    LanguageComponent,
    OrderComponent,
    FeaturedProductsComponent,
    FeaturedCategoriesComponent,
    ShopMessageComponent,
    PoweredByComponent,
    ReservationComponent,
    FirmsListItemComponent,
    FirmsBlockItemComponent,
    HamburgerComponent,
    BasketListItemComponent,
    LoyaltyItemComponent,
    HeaderComponent,
    SubHeaderComponent,
    FeaturedProductComponent,
    SearchComponent,
    FavoriteProductsComponent,
    NavSliderComponent,
    CouponComponent,
    OptionGroupComponent,
    SkeletonComponent,
    QuantitySelectorComponent,
    UnitSelectorComponent,
    WeightSelectorComponent,
    SquareBtnComponent,
    ChangeDeliveryMethodComponent,
    OutOfStockLabelComponent,
    ModifierComponent,
    SnoozedLabelComponent,
  ]
})
export class ComponentsModule { }
