import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { SignInWithApple, AppleSignInResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { AlertController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/observable/interval';
import { CustomerStore } from 'src/app/modules/customer/store/customer.store'
import { environment } from 'src/environments/environment';
import { Config } from '../../config';
import { AlertService } from './alert.service';
import { ErrorService } from './error.service';
import { HeadersService } from './headers.service';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  endpoint_customer = "/customer";
  endpoint_checkEmail = "/customer/checkemail";
  endpoint_checkActivation = "/checkactivation";
  endpoint_linkFirm = "/checkfirm";
  endpoint_SM_login = "/customer/login/";
  endpoint_check_SM_token = "/customer/check_login_sm";
  endpoint_save_devicetoken = "/device_token";
  endpoint_messages = "/shop_messages";
  endpoint_apple = "/customer/check_apple_sign_in";
  endpoint_apple_verify = "/app-apple-signin";
  endpoint_login = "/customer/login";
  endpoint_reset_pw = "/customer/resetpassword";
  endpoint_login_guest = "/customer_guest";

  unreadMessages: any;
  iabRef: any;
  sub: any;

  loggedIn = new Subject();
  loggedInObservable = this.loggedIn.asObservable();

  constructor(private navCtrl: NavController,
              private customerStore: CustomerStore,
              private http: HttpClient,
              private storage: Storage,
              private errorService: ErrorService,
              private headers: HeadersService,
              private iab: InAppBrowser,
              private alertService: AlertService,
              private googlePlus: GooglePlus,
              private alert: AlertController,
              private safariViewController: SafariViewController,
              private translate: TranslateService,
              private navService: NavService,
              private signInWithApple: SignInWithApple) {}

  /* Save cust_id to localStorage */
  saveLogin(custId, uuid) {
    this.storage.set('login', {
      activated: true,
      id: custId,
      uuid: uuid
    });
  }

  async login(username, password, firm) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.post(environment.API_URL + this.endpoint_login, { client_id: 'app', username: username, password: password }, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('customerToken', data.data.customerToken);
        await this.linkFirm(data.data.id);
        await this.saveLogin(data.data.id, data.data.uuid);
        await this.checkMessages(data.data.id);

        const customer = await this.get(data.data.id);
        const addresses = await this.getAllCustomerAddresses(customer.id, customer.uuid);

        this.customerStore.setAddresses(addresses);

        this.saveDeviceToken(data.data.id);

        if(this.navService.loginRedirect === 'basket') {
          this.navCtrl.navigateRoot('/basket');
        }
        else {
          this.navService.navigateToMenu(firm);
        }

        return data;
      })
      .catch(async err => {
        if(!err.error.success) {
          this.alertService.presentCustomAlert(err.error.message);
        }
      });
    }
  }

  async loginAsGuest() {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.get(environment.API_URL + this.endpoint_login_guest, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('customerToken', data.customerToken);
        return data;
      })
      .catch(async err => {
        if(!err.error.success) {
          this.alertService.presentCustomAlert(err.error.message);
        }
      });
    }
  }

  async resetPassword(email) {
    if(email) {
      if(this.errorService.checkConnection()) {
        let headers = await this.getHeaders();
        return this.http.post(environment.API_URL + this.endpoint_reset_pw, { email: email }, { headers: headers })
        .toPromise()
        .then(async () => {
          const alert = await this.alert.create({
            subHeader: this.translate.instant('PASSWORD_RESET_LINK') + email + '.',
            buttons: [
              {
                text: this.translate.instant('BUTTONS.OK'),
                handler: () => {
                }
              }
            ]
          });
          return await alert.present();
        })
        .catch(async err => {
          let error = await this.errorService.checkCustomerErrors(err, true);
          if(error === 'TOKEN_INVALID') {
            this.resetPassword(email);
          }
        });
      }
    }
    else {
      const alert = await this.alert.create({
        subHeader: this.translate.instant('NO_EMAIL'),
        buttons: [
          {
            text: this.translate.instant('BUTTONS.OK'),
            handler: () => {
            }
          }
        ]
      });
      return await alert.present();
    }

  }

  /* Get customer */
  async get(cust_id) {
    if(this.errorService.checkConnection()) {
      let headers: any = await this.getHeaders();
      headers = await headers.append('customerToken', await this.storage.get('customerToken'));
      return this.http.get(environment.API_URL + this.endpoint_customer + "/" + cust_id, { headers: headers })
        .toPromise()
        .then(async (data: any) => {
          this.loggedIn.next(data.data);
          this.customerStore.setCustomer(data.data);
          return data.data;
        })
        .catch(async err => {
          let error = await this.errorService.checkCustomerErrors(err);
          if(error === 'TOKEN_INVALID') {
            this.get(cust_id);
          }
        });
    }
  }

  /* Add new customer */
  async add(user, google?) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      if(google) {
        user.google = true;
      }
      return this.http.post(environment.API_URL + this.endpoint_customer, user, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('customerToken', data.customerToken);
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkCustomerErrors(err);
        if(error === 'TOKEN_INVALID') {
          this.add(user);
        }
      });
    }
  }

  /* Update existing customer */
  async update(cust_id, user) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      headers = await headers.append('customerToken', await this.storage.get('customerToken'));
      return this.http.put(environment.API_URL + this.endpoint_customer + "/" + cust_id, user, { headers: headers })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkCustomerErrors(err);

        if(error === 'TOKEN_INVALID') {
          this.update(cust_id, user);
        }
      });
    }
  }

  async setPushForCustomer(cust_id, permission) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      headers = await headers.append('customerToken', await this.storage.get('customerToken'));
      return this.http.put(`${environment.API_URL}${this.endpoint_customer}/${cust_id}/push`, { allow_push_notifications: permission }, { headers: headers })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkCustomerErrors(err);
        if(error === 'TOKEN_INVALID') {
          this.setPushForCustomer(cust_id, permission);
        }
      });
    }
  }

  /* Set opt in */
  async setOptInForCustomer(user) {
    await this.update(user.id, {
      id: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      opt_in_eo: user.opt_in_eo,
      opt_in_merchant: user.opt_in_merchant
    });
  }

  /* Check if customer exists */
  async linkFirm(cust_id) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.get(environment.API_URL + this.endpoint_customer + '/' + cust_id + this.endpoint_linkFirm, { headers: headers })
      .toPromise()
      .then((data: any) => {
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkCustomerErrors(err);

        if(error === 'CUSTOMER_NOT_FOUND') {
          return err;
        }
        else if(error === 'TOKEN_INVALID') {
          this.linkFirm(cust_id);
        }
      });
    }
  }

  /* Check if customer exists */
  async checkEmail(email: string, google?) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();

      let body: any = {
        email: email,
        google: google ? true : false
      }

      return this.http.post(environment.API_URL + this.endpoint_checkEmail, body, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('customerToken', data.customerToken);
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkEmailErrors(err);

        if(error === 'CUSTOMER_NOT_FOUND') {
          return err;
        }
        else if(error === 'TOKEN_INVALID') {
          this.checkEmail(email);
        }
      });
    }
  }

  /* Check if user has clicked the activation link */
  async checkActivation(cust_id) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.get(environment.API_URL + this.endpoint_customer + "/" + cust_id + this.endpoint_checkActivation, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('customerToken', data.customerToken);
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkEmailErrors(err);

        if(error === 'CUSTOMER_NOT_ACTIVATED' || error === 'CUSTOMER_NOT_FOUND') {
          return err.error;
        }
        else if(error === 'TOKEN_INVALID') {
          this.checkActivation(cust_id);
        }
      });
    }
  }

  /* Initiate social media login */
  async initiateSocialLogin(social_media) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.post(environment.API_URL + this.endpoint_SM_login + social_media, '', { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('socialToken', data.sessionToken);
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkCustomerErrors(err);

        if(error === 'TOKEN_INVALID') {
          this.initiateSocialLogin(social_media);
        }
      });
    }
  }

  /* Check social media token */
  async checkSocialToken() {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.post(environment.API_URL + this.endpoint_check_SM_token, { session_token: await this.storage.get('socialToken') }, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('customerToken', data.customerToken);
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkEmailErrors(err);

        if(error === 'CUSTOMER_NOT_ACTIVATED' || error === 'CUSTOMER_NOT_FOUND') {
          return err.error;
        }
        else if(error === 'TOKEN_INVALID') {
          this.checkSocialToken();
        }
      });
    }
  }

  /* Add new device token */
  async saveDeviceToken(cust_id) {
    let device_token = await this.storage.get('deviceToken');
    if(this.errorService.checkConnection() && device_token) {
      let headers = await this.getHeaders();
      headers = await headers.append('customerToken', await this.storage.get('customerToken'));
      return this.http.post(environment.API_URL + this.endpoint_customer + "/" + cust_id + this.endpoint_save_devicetoken, { device_token: device_token }, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        return data;
      });
    }
  }

  async storeAndRetrieveUserByAppleInfo(appleResponse: AppleSignInResponse) {
    if (this.errorService.checkConnection()) {
      const headers = await this.getHeaders();
      const body = {
        code: appleResponse.authorizationCode,
        id_token: appleResponse.identityToken,
        email: appleResponse.email,
        first_name: appleResponse.fullName?.givenName,
        last_name: appleResponse.fullName?.familyName
      };

      return this.http.post(environment.API_URL + this.endpoint_apple_verify, body, { headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('customerToken', data.customerToken);
        return data;
      })
      .catch(async err => {
        const error = await this.errorService.checkFirmErrors(err);

        if (error === 'TOKEN_INVALID') {
          await this.storeAndRetrieveUserByAppleInfo(appleResponse);
        }
      });
    }
  }

  /* Get messages */
  async getMessages(cust_id) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      headers = await headers.append('customerToken', await this.storage.get('customerToken'));
      return this.http.get(environment.API_URL + this.endpoint_customer + '/' + cust_id + this.endpoint_messages, { headers: headers })
      .toPromise()
      .then((data: any) => {
        return data.data;
      })
      .catch(async err => {
        let error = await this.errorService.checkFirmErrors(err);

        if(error === 'TOKEN_INVALID') {
          this.getMessages(cust_id);
        }
      });
    }
  }

  /* Read a message */
  async readMessage(cust_id, message_id) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      headers = await headers.append('customerToken', await this.storage.get('customerToken'));
      return this.http.put(environment.API_URL + this.endpoint_customer + '/' + cust_id + this.endpoint_messages + '/' + message_id, '', { headers: headers })
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkFirmErrors(err);

        if(error === 'TOKEN_INVALID') {
          this.readMessage(cust_id, message_id);
        }
      });
    }
  }

  async getMessageAmount() {
    let messages = await this.storage.get('unread_messages');
    var unread = [];

    messages.forEach(message => {
      if(!message.read) {
        unread.push(message);
      }
    });

    this.unreadMessages = unread.length;
  }

  async getLoginStorage() {
    return this.storage.get('login');
  }

  async getLoggedInCustomer() {
    let login: any = await this.storage.get('login');
    if(login) {
      if(login.activated) {
        await this.linkFirm(login.id);
        await this.get(login.id);
      }
    }
  }

  async checkLoginMessages() {
    // if user logged in, check push notifications
    let login = await this.storage.get('login');

    if(login) {
      if(login.activated) {
        this.checkMessages(login.id);
      }
    }
  }

  async checkMessages(cust_id) {
    let messages = await this.getMessages(cust_id);
    await this.storage.set('unread_messages', messages);
    this.getMessageAmount();
  }

  async getHeaders() {
    let headers: any = await this.headers.getHeaders();
    headers = await headers.append('passwordToken', await this.headers.checkToken());
    headers = await headers.append('demoPasswordToken', await this.headers.getDemoToken());
    headers = await headers.append('firmId', String(await this.storage.get('firm')));
    headers = await headers.append('language', await this.headers.getLanguage());
    if(Config.STORE_ID) {
      headers = await headers.append('storeId', Config.STORE_ID);
    }
    return headers;
  }

  /* SOCIAL MEDIA */
  appleSignin(firm) {
    this.signInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    })
    .then((res: AppleSignInResponse) => {
      void this.checkAppleLogin(res, firm);
    })
    .catch((error: any) => {
      // 1000 === ASAuthorizationErrorUnknown
      // 1001 === ASAuthorizationErrorCanceled
      // 1002 === ASAuthorizationErrorInvalidResponse
      // 1003 === ASAuthorizationErrorNotHandled
      if (error.code !== '1001') {
        void this.alertService.presentAppleValidationError();
      }
    });
  }

  async checkAppleLogin(appleResponse: AppleSignInResponse, firm) {
    const response: any = await this.storeAndRetrieveUserByAppleInfo(appleResponse);

    await this.saveLogin(response.customer.id, response.customer.uuid);
    await this.linkFirm(response.customer.id);
    await this.checkMessages(response.customer.id);
    this.loggedIn.next(response.customer);
    await this.saveDeviceToken(response.customer.id);

    if (this.navService.loginRedirect === 'basket') {
      await this.navCtrl.navigateRoot('/basket');
    } else {
      this.navService.navigateToMenu(firm);
    }
  }

  async facebookSignin(firm) {
    let response: any = await this.initiateSocialLogin("facebook");

    this.safariViewController.isAvailable()
      .then((available: boolean) => {
        if(available) {
          this.safariViewController.show({
            url: response.login_url,
            hidden: false,
            animated: false,
            transition: 'curl',
            enterReaderModeIfAvailable: true,
            tintColor: '#ff0000'
          })
          .subscribe((data) => {
            if(data.event === 'loaded') {
              this.checkFacebookLogin(firm);
            }
          },
          (error: any) => console.error(error)
          );
        }
        else {
          // use fallback browser, example InAppBrowser
          this.iabRef = this.iab.create(response.login_url, '_blank', 'usewkwebview=no');
          this.checkFacebookLogin(firm);
        }
      }
    );
  }

  async checkFacebookLogin(firm) {
    this.sub = Observable.interval(5000).subscribe(async () => {
      let response: any = await this.checkSocialToken();

      // check if app is activated or not
      if(response.code) {
        if(response.code === 'CUSTOMER_NOT_ACTIVATED') {}
        else if(response.code === 'CUSTOMER_NOT_FOUND') {
          this.safariViewController.hide();
          if(this.sub) {
            this.sub.unsubscribe();
          }
          if(this.iabRef) {
            this.iabRef.close();
          }
        }
      }
      else {
        this.safariViewController.hide();
        if(this.sub) {
          this.sub.unsubscribe();
        }
        if(this.iabRef) {
          this.iabRef.close();
        }

        await this.saveLogin(response.customer.id, response.customer.uuid);
        await this.linkFirm(response.customer.id);
        await this.checkMessages(response.customer.id);
        this.loggedIn.next(response.customer);
        this.saveDeviceToken(response.customer.id);

        if(this.navService.loginRedirect === 'basket') {
          this.navCtrl.navigateRoot('/basket');
        }
        else {
          this.navService.navigateToMenu(firm);
        }
      }
    });
  }

  googleSignin(firm) {
    this.googlePlus.login({})
    .then(async (res) => {
      let user: any = {
        email: res.email,
        first_name: res.givenName,
        last_name: res.familyName
      }

      let response: any = await this.checkEmail(user.email, true);

      if(!response.id) {
        let new_user: any = await this.add(user, true);

        await this.linkFirm(new_user.id);
        this.saveDeviceToken(new_user.id);
        await this.saveLogin(new_user.id, new_user.uuid);
        await this.checkMessages(new_user.id);
        this.navCtrl.navigateRoot('/user-profile');
      }
      else {
        await this.storage.set('customerToken', response.customerToken);
        await this.linkFirm(response.id);
        this.saveDeviceToken(response.id);
        await this.saveLogin(response.id, response.uuid);
        await this.checkMessages(response.id);

        if(this.navService.loginRedirect === 'basket') {
          this.navCtrl.navigateRoot('/basket');
        }
        else {
          this.navService.navigateToMenu(firm);
        }
      }
    })
    .catch(() => {
      this.alertService.presentGoogleError();
    });
  }

  async getAllCustomerAddresses(custId, custUuid) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      headers = headers.append('customerToken', await this.storage.get('customerToken'));
      headers = headers.append('customerId', String(custId));

      return this.http.get(`${environment.API_URL}/customers/${custUuid}/addresses`, { headers })
        .toPromise()
        .then((data: any) => {
          return data.data;
        })
        .catch((err) => {
          this.alertService.presentCustomAlert(err.error.message);
        });
    }
  }

  async updateCustomerAddress(custId, custUuid, address) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      headers = headers.append('customerToken', await this.storage.get('customerToken'));
      headers = headers.append('customerId', String(custId));

      return this.http.put(`${environment.API_URL}/customers/${custUuid}/addresses/${address.id}`, {
        is_default: address.is_default,
        description: address.description,
        street: address.street,
        house_number: address.house_number,
        bus: address.bus,
        zip_code: address.zip_code,
        locality: address.locality,
        country_id: address.country?.id,
      }, { headers })
      .toPromise()
      .then((data: any) => {
        return data.data;
      })
      .catch((err) => {
        if (err.error.code === 'VALIDATION_ERROR') {
          this.alertService.presentValidationError(err.error.errors);
        } else {
          this.alertService.presentCustomAlert(err.error.message);
        }
      });
    }
  }

  async addCustomerAddress(custId, custUuid, address) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      headers = headers.append('customerToken', await this.storage.get('customerToken'));
      headers = headers.append('customerId', String(custId));

      return this.http.post(`${environment.API_URL}/customers/${custUuid}/addresses`, {
        is_default: address.is_default,
        description: address.description,
        street: address.street,
        house_number: address.house_number,
        bus: address.bus,
        zip_code: address.zip_code,
        locality: address.locality,
        country_id: address.country?.id,
      }, { headers })
        .toPromise()
        .then((data: any) => {
          return data.data;
        })
        .catch((err) => {
          if (err.error.code === 'VALIDATION_ERROR') {
            this.alertService.presentValidationError(err.error.errors);
          } else {
            this.alertService.presentCustomAlert(err.error.message);
          }
        });
    }
  }

  async updateBusinessDetails(custId, custUuid, businessDetails) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      headers = headers.append('customerToken', await this.storage.get('customerToken'));
      headers = headers.append('customerId', String(custId));

      return this.http.put(`${environment.API_URL}/customer/${custUuid}/vat`, businessDetails, { headers })
        .toPromise()
        .then((data: any) => {
          return data;
        })
        .catch((err) => {
          if (err.error.code === 'VALIDATION_ERROR') {
            this.alertService.presentValidationError(err.error.errors);
          } else {
            this.alertService.presentCustomAlert(err.error.message);
          }
        });
    }
  }

  async getUserIdFromStorage() {
    const login = await this.storage.get('login');
    return login?.id;
  }
}
