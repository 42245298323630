import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Config } from 'src/config';
import { ErrorService } from './error.service';
import { HeadersService } from './headers.service';
import { NavService } from './nav.service';
import { Availability } from '../core/availability';
import { DeliveryTimeStore } from '../modules/delivery-time/store/delivery-time.store';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  endpoint = '/sync/products';
  endpoint_cat = '/category/';

  all_products: any;
  products: any;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private storage: Storage,
    private headers: HeadersService,
    private navService: NavService,
    private deliveryTimeStore: DeliveryTimeStore,
    private dateService: DateService
  ) { }

  async getAllProducts(categories) {
    this.all_products = [];
    await this.getProducts(categories);
    return this.all_products;
  }
  getProducts(categories: any) {
    categories.forEach(cat => {
      if(cat.subcategories.length > 0) {
        this.getProducts(cat.subcategories);
      }
      else {
        cat.products.forEach(prod => {
          this.all_products.push(prod);
        });
      }
    });
  }

  async getProductFromStorage() {
    const standard = await this.storage.get('standard');

    if (standard) {
      return this.storage.get('standard_products');
    }

    return this.storage.get('current_products');
  }

  /* API REQUESTS */
  async getProductData() {
    let headers = await this.getHeaders();
    return this.http.get(environment.API_URL + this.endpoint, { headers: headers })
    .toPromise()
    .then((data: any) => {
      // Set download timestamp
      let standard = this.storage.get('standard');
      if(standard) {
        this.storage.set('last_download_products', moment().format());
      }

      // Set featured categories
      let featured_categories = {
        featured_category_method: data.featured_category_method,
        featured_categories: data.featured_categories
      }
      this.storage.set('featured_categories', featured_categories);

      return data.data;
    })
    .catch(async err => {
      let error = await this.errorService.checkProductsErrors(err);
      if(error === 'NO_PRODUCTS') {
        return [];
      }
      else if(error === 'PASSWORD_CORRECT') {
        let products = await this.getProductData();
        return products;
      }
    });
  }

  async getCategoryById(categoryId) {
    let headers = await this.getHeaders();
    return this.http.get(`${environment.API_URL}${this.endpoint_cat}${categoryId}`, { headers: headers })
    .toPromise()
    .then((data: any) => {
      return data.data[0];
    })
    .catch(async err => {
      let error = await this.errorService.checkProductsErrors(err);
      if(error === 'NO_PRODUCTS') {
        return [];
      }
      else if(error === 'PASSWORD_CORRECT') {
        let products = await this.getProductData();
        return products;
      }
    });
  }

  async getProductsByCategoryId(categoryId) {
    let response: any = await this.getCategoryById(categoryId);

    if(response.products && response.products.length > 0) {
      return response.products;
    }
    else if(response.subcategories && response.subcategories.length > 0) {
      var array = [];
      await response.subcategories.forEach(subcat => {
        subcat.products.forEach(prod => {
          prod.name = `${prod.name} (${subcat.name})`;
          array.push(prod);
        });
      });
      return array;
    }
  }

  async getHeaders() {
    let headers: any = await this.headers.getHeaders();
    headers = await headers.append('passwordToken', await this.headers.checkToken());
    headers = await headers.append('demoPasswordToken', await this.headers.getDemoToken());
    headers = await headers.append('firmId', String(await this.storage.get('firm')));
    headers = await headers.append('language', await this.headers.getLanguage());
    if(Config.STORE_ID) {
      headers = await headers.append('storeId', Config.STORE_ID);
    }
    return headers;
  }

  /* FUNCTIONS */
  removeProduct(original_products, original_product) {
    let index = original_products.indexOf(original_product);
    if (index !== -1) {
      original_products.splice(index, 1);
    }
  }
  getImage(images) {
    var thumb;

    images.forEach(img => {
      if(img.main) {
        thumb = img.thumb;
      }
    });

    return thumb;
  }
  getDetailImage(images) {
    var detail;

    images.forEach(img => {
      if(img.main) {
        detail = img.detail;
      }
    });

    return detail;
  }
  search(categories, search_query) {
    var found_categories = [];
    var counter = 0;

    categories.forEach(category => {
      if(category.subcategories.length === 0) {
        let products = category.products.filter((item) => {
          return (item.name.toLowerCase().indexOf(search_query.toLowerCase()) > -1);
        });
        if(products.length > 0) {
          let found_cat: any = {
            category_name: category.name,
            products: products
          }
          if(category.availabilities) {
            found_cat.availabilities = category.availabilities;
          }
          found_categories[counter] = found_cat;
        }
      }
      else {
        var subcategories = [];
        var sub_counter = 0;

        category.subcategories.forEach(subcategory => {
          let products = subcategory.products.filter((item) => {
            return (item.name.toLowerCase().indexOf(search_query.toLowerCase()) > -1);
          });
          if(products.length > 0) {
            let found_subcat = {
              category_name: subcategory.name,
              products: products
            }
            subcategories[sub_counter] = found_subcat;
          }
          sub_counter = sub_counter + 1;
        });
        if(subcategories.length > 0) {
          let found_cat: any = {
            category_name: category.name,
            subcategories: subcategories
          }
          if(category.availabilities) {
            found_cat.availabilities = category.availabilities;
          }
          found_categories[counter] = found_cat;
        }
      }
      counter = counter + 1;
    });

    return found_categories;
  }

  hasValidIngredientGroups(item) {
    let hasValidIngredientGroups = false;

    if (item.ingredient_groups?.length > 0) {
      item.ingredient_groups.forEach((ingr_gr) => {
        ingr_gr.ingredients.forEach((ingr) => {
          if (ingr.type === 'free' || ingr.type === 'paid') {
            hasValidIngredientGroups = true;
          }
        });
      });
    }

    return hasValidIngredientGroups;
  }

  hasValidOptionGroups(item) {
    return item.option_groups?.length > 0;
  }

  hasValidModifiers(item) {
    const hasValidIngredientGroups = this.hasValidIngredientGroups(item);
    const hasValidOptionGroups = this.hasValidOptionGroups(item);
    return hasValidIngredientGroups || hasValidOptionGroups;
  }

  allowOpenOrderModal(item, firm) {
    return this.hasValidModifiers(item) || firm.show_comments_step_product_basket;
  }

  getAvailabilities(category, parentCategory, usesDeliverect): Availability[] {
    return usesDeliverect ? parentCategory?.availabilities : category?.availabilities;
  }

  isCategoryAvailable(category, parentCategory, usesDeliverect): boolean {
    if (usesDeliverect) {
      return this.isAvailable(parentCategory?.availabilities);
    }

    return this.isAvailable(category?.availabilities);
  }

  isAvailable(availabilities: Availability[]): boolean {
    if (availabilities.length === 0) {
      return true;
    }

    const deliveryTime = this.deliveryTimeStore.currentDeliveryTime$.getValue();

    let date;

    if (deliveryTime === 'asap') {
      date = moment();
    } else {
      date = moment(deliveryTime);
    }

    const availableDays = availabilities.filter((day) => day.day_id === date.isoWeekday());

    if (!availableDays || availableDays.length === 0) {
      return false;
    }

    let available = false;

    availableDays.forEach((day) => {
      const fromDate = this.dateService.setTimeOnDate(new Date(date), day.from_time);
      const toDate = this.dateService.setTimeOnDate(new Date(date), day.to_time);
      if (date.isSame(fromDate) || date.isBetween(fromDate, toDate)) {
        available = true;
      }
    });

    return available;
  }

  isSnoozed(item): boolean {
    if (!item.snooze_start || !item.snooze_end) {
      return false;
    }

    const deliveryTime = this.deliveryTimeStore.currentDeliveryTime$.getValue();

    if (deliveryTime === 'asap') {
      return moment().isBetween(item.snooze_start, item.snooze_end);
    }

    return moment(deliveryTime).isBetween(item.snooze_start, item.snooze_end);
  }

  isDateWithinCurrentYear(date) {
    return moment(date).isBefore(moment().add(1, 'y'));
  }

  setDeliverectCategories(categoryList) {
    this.navService.deliverect_categories = categoryList.map((cat, index) => ({ name: cat.name, index }));
  }

  getDeliverectCategories(originalCategoryList, index) {
    if (!index) {
      index = 0;
    }

    return {
      categoryList: originalCategoryList[index].subcategories,
      parentCategory: originalCategoryList[index]
    };
  }
}
