import { Component, Input } from '@angular/core';
import { FirmService } from '../../services/firm.service';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'featured-products',
  templateUrl: 'featured-products.html',
  styleUrls: ['featured-products.scss']
})
export class FeaturedProductsComponent {

  @Input() categoryList;

  slideOpts = {
    initialSlide: 0,
    centeredSlides: true,
    centeredSlidesBounds: true,
    width: "130"
  };

  firm: any;
  products: any;
  featuredProducts: any;

  constructor(public productsService: ProductsService,
              private firmService: FirmService) {}

  async ngOnInit() {
    this.firm = await this.firmService.getLocalFirm();
    this.getFeaturedProducts();

  }

  ngOnChanges() {
    this.getFeaturedProducts();
  }

  async getFeaturedProducts() {
    this.products = await this.productsService.getAllProducts(this.categoryList);
    this.featuredProducts = [];

    this.featuredProducts = this.products.filter((product) => {
      return product.featured === true;
    });
  }

}
