import { DELIVERY_METHODS } from 'src/app/constants/delivery-methods';
import { AbstractDeliveryTimeService } from './abstract-delivery-time.service';

export class PickupTimeService extends AbstractDeliveryTimeService {
  public hasOrderTimeslots(): boolean {
    return this.firm.delivery_methods.pickup.order_timeslots.active;
  }

  public hasCustomDays(): boolean {
    return this.firm.delivery_methods.pickup.has_custom_days;
  }

  public getCustomDays(): any[] {
    return this.firm.delivery_methods.pickup.custom_days;
  }

  public selectDayFromPeriodsByDate(date: string): any {
    return this.dateService.getSelectedDay(date, this.firm.periods.pickup_hours);
  }

  public isLimitedToSameDayOrdering(): boolean {
    return this.firm.delivery_methods.pickup &&
      this.firm.delivery_methods.pickup.orders_only_during_opening_hours;
  }

  public getDeliveryMethod(): string {
    return DELIVERY_METHODS.PICKUP;
  }
}
