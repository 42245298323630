import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TableDeliveryMethodStore } from 'src/app/modules/delivery-method/store/table-delivery-method.store';

@Component({
  selector: 'app-table-methods',
  templateUrl: './table-methods.page.html',
  styleUrls: ['./table-methods.page.scss'],
})
export class TableMethodsPage implements OnInit {
  @Input() methods: any;
  @Input() showSwitcher: boolean;

  selectedMethod: any;
  selectedMethodId: number;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private tableDeliveryMethodStore: TableDeliveryMethodStore
  ) { }

  ngOnInit() {}

  selectMethod(method) {
    this.selectedMethod = method;
    this.selectedMethodId = method.id;
  }

  save() {
    this.tableDeliveryMethodStore.setSelectedTableMethod(this.selectedMethod).then(async () => {
      await this.tableDeliveryMethodStore.setSelectedTableNumber(undefined);
      await this.modalCtrl.dismiss({ cancelled: false, selectedMethod: this.selectedMethod });
    });
  }

  async cancel() {
    await this.modalCtrl.dismiss({ cancelled: true });
  }

  async goToMethodSelection(): Promise<void> {
    await this.modalCtrl.dismiss({ cancelled: true });
    await this.router.navigate(['/method-selection']);
  }
}
