import { Directive, Renderer2, ElementRef, OnInit } from '@angular/core';
import { AddToCartService } from '../../services/add-to-cart.service';

@Directive({
  selector: '[basketAnimation]'
})
export class AnimationDirective implements OnInit {

  constructor(
    private renderer: Renderer2, 
    private el: ElementRef,
    private addToCartService: AddToCartService
  ) {
    this.addToCartService.cartAnimationObservable.subscribe(() => {
      this.enableAnimation();
    });
  }

  ngOnInit() {}

  enableAnimation() {
    this.renderer.addClass(this.el.nativeElement, 'jello-vertical');

    setTimeout(() => {
        this.renderer.removeClass(this.el.nativeElement, 'jello-vertical');
    }, 500);
  }

}