import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  CustomDaysPickerComponent
} from 'src/app/modules/delivery-time/components/custom-days-picker/custom-days-picker.component';
import {
  DeliveryDateTimePickerComponent
} from 'src/app/modules/delivery-time/components/delivery-date-time-picker/delivery-date-time-picker.component';
import {
  DeliveryTimeModalComponent
} from 'src/app/modules/delivery-time/components/delivery-time-modal/delivery-time-modal.component';
import {
  MethodDeliverySwitcherComponent
} from 'src/app/modules/delivery-time/components/method-delivery-switcher/method-delivery-switcher.component';
import {
  TimeslotsPickerComponent
} from 'src/app/modules/delivery-time/components/timeslots-picker/timeslots-picker.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CustomDaysPickerComponent,
    DeliveryDateTimePickerComponent,
    DeliveryTimeModalComponent,
    MethodDeliverySwitcherComponent,
    TimeslotsPickerComponent,
  ],
  exports: [
    DeliveryTimeModalComponent,
    MethodDeliverySwitcherComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    TranslateModule,
  ],
})
export class DeliveryTimeModule { }
