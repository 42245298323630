import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'container-menu',   loadChildren: () => import('./pages/container-menu/container-menu.module').then(m => m.ContainerMenuPageModule) },
  { path: 'menu',             loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule) },
  { path: 'menu/:firmId',      loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule) },
  { path: 'info',             loadChildren: () => import('./pages/info/info.module').then(m => m.InfoPageModule) },
  { path: 'opening-hours',    loadChildren: () => import('./pages/openinghours/openinghours.module').then(m => m.OpeninghoursPageModule) },
  { path: 'delivery-hours',   loadChildren: () => import('./pages/deliveryhours/deliveryhours.module').then(m => m.DeliveryhoursPageModule) },
  { path: 'settings',         loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
  { path: 'category',         loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule) },
  { path: 'category/:firmId',  loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryPageModule) },
  { path: 'item',             loadChildren: () => import('./pages/item/item.module').then(m => m.ItemPageModule) },
  { path: 'messages',         loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule) },
  { path: 'message',          loadChildren: () => import('./pages/message/message.module').then(m => m.MessagePageModule) },
  { path: 'basket',           loadChildren: () => import('./pages/basket/basket.module').then(m => m.BasketPageModule) },
  { path: 'thank',            loadChildren: () => import('./pages/thank/thank.module').then(m => m.ThankPageModule) },
  { path: 'login-language',   loadChildren: () => import('./pages/login-language/login-language.module').then(m => m.LoginLanguagePageModule) },
  { path: 'loyalty',          loadChildren: () => import('./pages/loyalty/loyalty.module').then(m => m.LoyaltyPageModule) },
  { path: 'order',            loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule) },
  { path: 'password',         loadChildren: () => import('./pages/password/password.module').then(m => m.PasswordPageModule) },
  { path: 'payment',          loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule) },
  { path: 'recent-orders',    loadChildren: () => import('./pages/recent-orders/recent-orders.module').then(m => m.RecentOrdersPageModule) },
  { path: 'reservation',      loadChildren: () => import('./pages/reservation/reservation.module').then(m => m.ReservationPageModule) },
  { path: 'login',            loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'signin',           loadChildren: () => import('./pages/signin/signin.module').then(m => m.SigninPageModule) },
  { path: 'checkout/:method', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) },
  { path: 'announcements',    loadChildren: () => import('./pages/announcements/announcements.module').then(m => m.AnnouncementsPageModule) },
  { path: 'user-profile',     loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfilePageModule) },
  { path: 'extra-info/:method',       loadChildren: () => import('./pages/extra-info/extra-info.module').then(m => m.ExtraInfoPageModule) },
  { path: 'subcategory',      loadChildren: () => import('./pages/subcategory/subcategory.module').then(m => m.SubcategoryPageModule) },
  { path: 'store-menu',       loadChildren: () => import('./pages/store-menu/store-menu.module').then(m => m.StoreMenuPageModule) },
  { path: 'news-feed',        loadChildren: () => import('./pages/news-feed/news-feed.module').then(m => m.NewsFeedPageModule) },
  { path: 'method-selection', loadChildren: () => import('./pages/method-selection/method-selection.module').then(m => m.MethodSelectionPageModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
