<!-- Make reservation -->
<div class="reservation" *ngIf="!enableSuccess" #content id="content">

    <div class="content">
        <div class="title-box" style="padding: 0 16px">
            <p>{{ 'MAKE_A_RESERVATION' | translate }}</p>
        </div>

        <hr>

        <!-- Reservation info -->
        <div class="reservation-info">

            <p>{{ 'MAKE_A_RESERVATION_2' | translate }}</p>

            <!-- Amount of guests -->
            <div>
                <p class="inline">{{ 'FOR' | translate }}</p>
                <div class="reservation-box inline" tappable (click)="openAmountPicker()">
                    <p class="inline v-align">{{ chosen_amount }}</p>
                    <i class="fas fa-chevron-down icon v-align"></i>
                </div>
                <p class="inline">{{ 'PERSONS' | translate }}</p>
            </div>

            <!-- Date -->
            <div>
                <p class="inline">{{ 'ON' | translate }}</p>
                <div class="reservation-box inline" tappable (click)="datepicker.open()">
                    <p class="inline v-align margin-right">{{ date_label }}</p>
                    <i class="fas fa-chevron-down icon v-align"></i>
                </div>
                <ion-datetime #datepicker id="datepicker"
                    pickerFormat="DD MMMM YYYY"
                    style="display: none"
                    placeholder="{{ 'DATE' | translate }}"
                    [(ngModel)]="date"
                    (ionChange)="getTimeSlots()"
                    [min]="minDate"
                    [max]="maxDate"
                    monthNames="{{ 'MONTHS.JANUARY' | translate }},{{ 'MONTHS.FEBRUARY' | translate }},{{ 'MONTHS.MARCH' | translate }},{{ 'MONTHS.APRIL' | translate }},{{ 'MONTHS.MAY' | translate }},{{ 'MONTHS.JUNE' | translate }},{{ 'MONTHS.JULY' | translate }},{{ 'MONTHS.AUGUST' | translate }},{{ 'MONTHS.SEPTEMBER' | translate }},{{ 'MONTHS.OCTOBER' | translate }},{{ 'MONTHS.NOVEMBER' | translate }},{{ 'MONTHS.DECEMBER' | translate }}">
                </ion-datetime>
            </div>

            <!-- Time -->
            <div>
                <p class="inline">{{ 'AT' | translate }}</p>
                <div class="reservation-box inline" tappable (click)="openTimePicker()" [ngClass]="times?.length === 0 && enableTime ? 'disabled' : 'enabled'">
                    <p class="inline v-align margin-right">{{ chosen_time }}</p>
                    <i class="fas fa-chevron-down icon v-align"></i>
                </div>
                <p class="msg" *ngIf="times?.length === 0 && enableTime">{{ 'NO_SLOTS_FOUND' | translate }}</p>
            </div>

            <!-- Notes -->
            <div>
                <p>{{ 'WITH_NOTES' | translate }}</p>
                <div class="reservation-input-box">
                    <ion-input class="v-align" type="text" placeholder="{{ 'NO_NOTES' | translate }}" [(ngModel)]="remarks"></ion-input>
                    <i class="fas fa-pen icon v-align"></i>
                </div>
            </div>
        </div>

        <!-- User information -->
        <div class="user-info">

            <ion-item class="half">
                <ion-label position="floating">{{ 'PROFILE.FIRST_NAME' | translate }} *</ion-label>
                <ion-input type="text" [(ngModel)]="first_name" (ionChange)="checkDisable()"></ion-input>
            </ion-item>
            <ion-item class="half">
                <ion-label position="floating">{{ 'PROFILE.LAST_NAME' | translate }} *</ion-label>
                <ion-input type="text" [(ngModel)]="last_name" (ionChange)="checkDisable()"></ion-input>
            </ion-item>
            <ion-item class="full">
                <ion-label position="floating">{{ 'PROFILE.EMAIL' | translate }} *</ion-label>
                <ion-input type="email" [(ngModel)]="email" (ionChange)="checkDisable()"></ion-input>
            </ion-item>
            <ion-item class="full">
                <ion-label position="floating">{{ 'PROFILE.PHONE' | translate }} *</ion-label>
                <ion-input type="tel" [(ngModel)]="phone" (ionChange)="checkDisable()"></ion-input>
            </ion-item>
        </div>

        <!-- Error message -->
        <div class="error-msg" *ngIf="enableError">
            <p>{{ error_msg }}</p>
        </div>

    </div>

    <!-- Confirm button -->
    <ion-footer class="fixed-btn-box ion-text-center" *ngIf="showButton">
        <ion-button class="custom-btn" (click)="confirmReservation()" [disabled]="!disableBtn || (times?.length === 0 && enableTime)">
            {{ 'BUTTONS.CONFIRM' | translate }}
        </ion-button>
    </ion-footer>
</div>

<!-- Success -->
<div class="success ion-text-center" *ngIf="enableSuccess">
    <p class="thank-title ion-padding">{{ 'BOOKING_SUCCES' | translate }}</p>

    <i class="fas fa-check-circle check-icon ion-padding"></i>

    <div class="info ion-padding">
        <p>{{ 'BOOKING_THANKS' | translate }}</p>
        <p>{{ 'THANK.EMAIL' | translate:{ email: email } }}</p>
        <p class="date"><b>{{ date_label }} {{ chosen_time }}</b></p>
    </div>

    <div class="fixed-btn-box ion-text-center">
        <ion-button (click)="navService.navigateToMenu(firm)">
            {{ 'CONTINUE' | translate }}
        </ion-button>
    </div>
</div>
