import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { DeliveryTimeServiceInterface } from 'src/app/modules/delivery-time/services/delivery-time-service.interface';
import { DeliveryTimeServiceResolver } from 'src/app/modules/delivery-time/services/delivery-time-service.resolver';
import { TimepickerPage } from 'src/app/pages/timepicker/timepicker.page';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'delivery-date-time-picker',
  templateUrl: './delivery-date-time-picker.component.html',
  styleUrls: ['./delivery-date-time-picker.component.scss'],
})
export class DeliveryDateTimePickerComponent implements OnInit, OnDestroy {
  @Input() isLimitedToSameDayOrdering: boolean;
  @Input() firm: any;
  @Input() showHeader = true;

  @ViewChild('dateDayPicker') dateDayPicker;
  @ViewChild('dateTimePicker') dateTimePicker;

  public firstAvailableTimeResponse: any;
  private deliveryTimeService: DeliveryTimeServiceInterface;

  public deliveryTime = new BehaviorSubject<string>(null);

  public loading = true;
  public dateDay = '';
  public dateTime = '';
  public dateDayModel = '';
  public dateTimeModel = '';
  public minTime = '';
  public maxTime = '';
  public minDate = '';
  public maxDate = moment().add(1, 'month').format('YYYY-MM-DD');

  private subscribers: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private dateService: DateService,
    private alertCtrl: AlertController,
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
    private translate: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.subscribers[0] = this.deliveryTime.subscribe((deliveryTime: string) => {
      if (deliveryTime === 'asap') {
        this.dateDay = this.translate.instant('TODAY');
        this.dateTime = this.translate.instant('ASAP');
      } else {
        this.dateDay = this.dateService.getDateDay(deliveryTime);
        this.dateTime = this.dateService.formatDateTime(deliveryTime);
      }
    });

    this.calculateDateTime();
  }

  public ngOnDestroy(): void {
    this.subscribers.forEach((subscriber: any) => subscriber.unsubscribe());
  }

  private calculateDateTime(): void {
    this.deliveryTimeServiceResolver.resolveDeliveryTimeService(this.firm)
      .then(async (service: DeliveryTimeServiceInterface) => {
        this.loading = true;

        this.deliveryTimeService = service;
        this.firstAvailableTimeResponse = await service.calculateTimestamp();

        this.minDate = moment(this.firstAvailableTimeResponse.timestamp).format('YYYY-MM-DD');

        let deliveryTime = service.getDeliveryTime();

        if (!deliveryTime && this.firm.asap) {
          deliveryTime = 'asap';
        }

        if (!deliveryTime) {
          deliveryTime = moment().format();
        }

        this.deliveryTime.next(deliveryTime);

        if (deliveryTime === 'asap' && this.dateService.isToday(this.firstAvailableTimeResponse.timestamp)) {
          this.setMinMaxTime(this.firstAvailableTimeResponse.timestamp);
          service.updateDeliveryTime(deliveryTime);
        } else {
          if (
            moment(deliveryTime).unix() < moment(this.firstAvailableTimeResponse.timestamp).unix()
            || deliveryTime === 'asap'
          ) {
            this.deliveryTime.next(moment(this.firstAvailableTimeResponse.timestamp).format());
            this.updateDelay();
          }

          this.setMinMaxTime(this.deliveryTime.value);
        }

        if (this.isLimitedToSameDayOrdering && !this.dateService.isToday(this.firstAvailableTimeResponse.timestamp)) {
          await this.triggerShopClosedAlert('ORDER_TODAY_NOT_POSSIBLE');
          this.isLimitedToSameDayOrdering = false;
        }

        this.loading = false;
      });
  }

  public openDateDayPicker(): void {
    this.dateDayPicker.open();
  }

  public async openDateTimePicker(): Promise<void> {
    if (
      this.firm?.asap
      && (
        this.isLimitedToSameDayOrdering
        || this.dateService.isToday(this.deliveryTime.value)
        || this.deliveryTime.value === 'asap'
      )
    ) {
      const timepicker = await this.modalCtrl.create({
        component: TimepickerPage,
        backdropDismiss: true,
        showBackdrop: true,
        cssClass: 'modal-height',
        componentProps: {
          timestamp: this.minTime,
          isToday: true,
          minTime: this.minTime,
          maxTime: this.maxTime
        }
      });
      await timepicker.present();

      timepicker.onDidDismiss().then(async ({ data }) => {
        if (data === 'asap') {
          this.deliveryTime.next('asap');
          this.deliveryTimeService?.updateDeliveryTime(data);
        } else {
          this.dateTimeModel = moment(data, 'HH:mm').format();
        }
      });

      return;
    }

    this.dateTimePicker.open();
  }

  public updateDelay(): void {
    this.deliveryTimeService?.updateDeliveryTime(moment(this.deliveryTime.value).format());
  }

  public setMinMaxTime(timestamp: string): void {
    if (this.dateService.isToday(timestamp)) {
      this.minTime = this.dateService.formatDateTime(this.firstAvailableTimeResponse.timestamp);
    } else {
      this.minTime = this.dateService.getOpeningTime(
        this.deliveryTimeService.selectDayFromPeriodsByDate(moment(timestamp).format())
      );
    }

    this.maxTime = this.dateService.getClosingTime(
      this.deliveryTimeService.selectDayFromPeriodsByDate(moment(timestamp).format())
    );
  }

  public async handleOnDateDayChange(): Promise<void> {
    if (this.deliveryTime.value === 'asap' && this.dateService.isToday(this.dateDayModel)) {
      return;
    }

    if (this.deliveryTime.value === 'asap') {
      this.deliveryTime.next(moment(this.minTime, 'HH:mm').format());
    }

    const day = moment(this.dateDayModel).format('DD/MM/YYYY');
    const time = moment(this.deliveryTime.value).format('HH:mm');

    const deliveryTime = moment(`${day} - ${time}`, 'DD/MM/YYYY - HH:mm').format();

    if (!await this.checkIfOrderingTimeIsValid(deliveryTime)) {
      return;
    }

    this.setMinMaxTime(this.dateDayModel);
    this.deliveryTime.next(deliveryTime);
    this.updateDelay();
  }

  public async handleOnDateTimeChange(): Promise<void> {
    if (this.deliveryTime.value === 'asap') {
      this.deliveryTime.next(moment().format());
    }

    let day = moment(this.deliveryTime.value).format('DD/MM/YYYY');

    if (this.isLimitedToSameDayOrdering) {
      day = moment().format('DD/MM/YYYY');
    }

    const time = moment(this.dateTimeModel).format('HH:mm');

    const deliveryTime = moment(`${day} - ${time}`, 'DD/MM/YYYY - HH:mm').format();

    if (!await this.checkIfOrderingTimeIsValid(deliveryTime)) {
      return;
    }

    this.deliveryTime.next(deliveryTime);
    this.updateDelay();
  }

  private async checkIfOrderingTimeIsValid(time: string): Promise<boolean> {
    const isOrderingTimeValid = await this.deliveryTimeService.isOrderingTimeValid(time);

    if (!isOrderingTimeValid) {
      await this.triggerShopClosedAlert('SHOP_NOT_OPEN');

      return false;
    }

    return true;
  }

  private async triggerShopClosedAlert(messageKey: string): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('INFO.TITLE'),
      message: this.translate.instant(messageKey),
      buttons: [this.translate.instant('BUTTONS.OK')],
    });

    await alert.present();
  }
}
