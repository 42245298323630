import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BasketItemsService } from '../../services/basket-items.service';

@Component({
  selector: 'eo-unit-selector',
  template: `
    <button 
        [class.remove]="enableDelete && quantity <= 1"
        (click)="quantity > 1 ? subtract() : enableDelete ? deleteHandler() : null" 
        [disabled]="!enableDelete && disableSubtractBtn()">
      <i class="fas fa-minus"></i>
    </button>

    <ion-input [(ngModel)]="quantity" [readonly]="true"></ion-input>

    <button (click)="add()" [disabled]="disableAddBtn()">
      <i class="fas fa-plus"></i>
    </button>
  `,
  styleUrls: ['./quantity-selector.component.scss'],
  styles: [
    `
      .remove {
        background-color: red !important;
      }
    ` 
  ],
})
export class UnitSelectorComponent {

  @Input() quantity: number;
  @Input() minQuantity: number;
  @Input() maxQuantity: number;
  @Input() maxCartQuantity: number;
  @Input() totalCartQuantity: number;
  @Input() totalItemQuantity: number;
  @Input() enableDelete = false;

  @Output() quantityChanged = new EventEmitter<number>();
  @Output() deleted = new EventEmitter<any>();

  constructor(private basketItemsService: BasketItemsService) { }
  
  subtract() {
    this.quantity = Number(this.quantity) - 1;
    this.changeHandler();
  }

  add() {
    this.quantity = Number(this.quantity) + 1;
    this.changeHandler();
  }

  disableSubtractBtn() {
    return  this.quantity <= 1 || 
            this.quantity <= this.minQuantity;
  }

  disableAddBtn() {
    return  this.basketItemsService.isMaxItemAmountExceeded(this.maxQuantity, this.totalItemQuantity) || 
            this.basketItemsService.isMaxCartAmountExceeded(this.maxCartQuantity, this.totalCartQuantity);
  }

  changeHandler() {
    this.quantityChanged.emit(this.quantity);
  }

  deleteHandler() {
    this.deleted.emit();
  }

}
