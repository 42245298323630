import { Component, OnInit, Input } from '@angular/core';
import { FirmService } from '../../services/firm.service';
import { ModalController } from '@ionic/angular';
import { FirmInfoPage } from '../../pages/firm-info/firm-info.page';

@Component({
  selector: 'app-firms-list-item',
  templateUrl: './firms-list-item.component.html',
  styleUrls: ['./firms-list-item.component.scss'],
})
export class FirmsListItemComponent implements OnInit {

  @Input() firm: any;

  constructor(public firmService: FirmService,
              private modalCtrl: ModalController) { }

  ngOnInit() {}

  async openInfoModal() {
    const infoModal = await this.modalCtrl.create({
      component: FirmInfoPage,
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: "info-modal-height",
      componentProps: {
        'firm': this.firm
      }
    });
    await infoModal.present();
  }

}
