import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { GoogleMaps, Spherical } from '@ionic-native/google-maps/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Push } from '@ionic-native/push/ngx';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/browser';
import { TooltipsModule } from 'ionic-tooltips';
import { CustomerModule } from 'src/app/modules/customer/customer.module';
import { DeliveryMethodModule } from 'src/app/modules/delivery-method/delivery-method.module';
import { FirmModule } from 'src/app/modules/firm/firm.module';
import { Config } from 'src/config';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

Sentry.init({
  dsn: 'https://af5f1718031f40a9af11afe0cc8b0613@o440362.ingest.sentry.io/5409100',
  environment: `${environment.ENV}_V${Config.VERSION}_API${Config.API_VERSION}`
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.configureScope(scope => {
      if (Config.FIRM_ID) {
        scope.setExtra('firmId', Config.FIRM_ID);
      }
      if (Config.STORE_ID) {
        scope.setExtra('storeId', Config.STORE_ID);
      }
    });

    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ComponentsModule,
    FirmModule,
    CustomerModule,
    DeliveryMethodModule,
    DirectivesModule,
    IonicModule.forRoot(
      { animated: true }
    ),
    IonicStorageModule.forRoot(),
    TooltipsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    SplashScreen,
    Network,
    Device,
    InAppBrowser,
    Keyboard,
    Push,
    Deeplinks,
    Geolocation,
    GooglePlus,
    GoogleMaps,
    GoogleAnalytics,
    SocialSharing,
    Diagnostic,
    Vibration,
    Spherical,
    SafariViewController,
    WebView,
    SignInWithApple,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
