<ion-content>
  <eo-modal
    [submitButtonEnabled]="modalButtonEnabled"
    [submitText]="'CONTINUE' | translate"
    (submitted)="handleOnSubmit()"
  >
    <method-delivery-switcher topLeft *ngIf="showSwitcher"></method-delivery-switcher>
    <ion-grid content class="full-width">
      <ion-row class="ion-align-items-center">
        <ion-col size="2">
          <ion-icon src="/assets/images/delivery.svg"></ion-icon>
        </ion-col>
        <ion-col class="font-16">
          <strong *ngIf="addresses.length === 0">{{ 'FILL_IN_ADDRESS' | translate }}</strong>
          <strong *ngIf="addresses.length > 0">{{ 'SELECT_AN_ADDRESS' | translate }}</strong>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="addresses.length === 0">
        <ion-col>
          <address-form
            [showAddressName]="showAddressName"
            [showAddressDefault]="showAddressDefault"
            [address]="address"
            (onChange)="handleAddressFormOnChange($event)"
          ></address-form>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="addresses.length > 0">
        <ion-col>
          <div class="address-container">
            <eo-slider>
              <eo-address
                *ngFor="let address of addresses"
                [address]="address"
                [displaySelected]="isAddressSelected(address)"
                (selected)="selectAddress($event)"
                (edit)="presentEditAddressModal($event)">
              </eo-address>
              <div class="address-card add-card"
                   (click)="presentAddDeliveryAddressModal()">
                <div class="address-content">
                  <div class="add-icon">
                    <i class="fas fa-plus-circle add-icon"></i>
                  </div>
                  <strong>{{ 'ADD_ADDRESS' | translate }}</strong>
                </div>
              </div>
            </eo-slider>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </eo-modal>
</ion-content>
