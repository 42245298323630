<ion-content>
  <eo-modal
    [submitButtonEnabled]="true"
    [submitText]="'BUTTONS.NEXT' | translate"
    (submitted)="handleOnSubmit()"
  >
    <div content>
      <div class="title-font">{{ 'READY_TO_ORDER' | translate }}</div>
      <method-selector
        (onSelect)="handleOnMethodSelect()"
      ></method-selector>
    </div>
  </eo-modal>
</ion-content>
