import { EventEmitter, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class TableDeliveryMethodStore {
  public currentSelectedTableNumber = null;
  public currentSelectedTableMethod = null;

  public updateSelectedTableNumberEvent = new EventEmitter();
  public updateSelectedTableMethodEvent = new EventEmitter();

  constructor(
    private storage: Storage,
  ) { }

  public async resetSelectedTableMethod(): Promise<void> {
    this.currentSelectedTableMethod = null;
    await this.storage.set('selectedTableMethod', undefined);
  }

  public async resetSelectedTableNumber(): Promise<void> {
    this.currentSelectedTableNumber = null;
    await this.storage.set('selectedTableNumber', undefined);
  }

  public async setSelectedTableNumber(tableNumber: any): Promise<void> {
    this.currentSelectedTableNumber = tableNumber;
    this.updateSelectedTableNumberEvent.emit(tableNumber);
    await this.storage.set('selectedTableNumber', tableNumber);
  }

  public async setSelectedTableMethod(tableMethod: any): Promise<void> {
    this.currentSelectedTableMethod = tableMethod;
    this.updateSelectedTableMethodEvent.emit(tableMethod);
    await this.storage.set('selectedTableMethod', tableMethod);
  }

  public async getSelectedTableMethod(): Promise<void> {
    return this.storage.get('selectedTableMethod');
  }

  public async getSelectedTableNumber(): Promise<any> {
    return this.storage.get('selectedTableNumber');
  }
}
