import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Address } from 'src/app/core/models/address';
import { AddressModalComponent } from 'src/app/modules/customer/components/address-modal/address-modal.component';
import {
  DeliveryTimeModalComponent
} from 'src/app/modules/delivery-time/components/delivery-time-modal/delivery-time-modal.component';
import { FirmsListModalComponent } from 'src/app/modules/firm/components/firms-list-modal/firms-list-modal.component';
import { PickupPointsPage } from '../pages/pickup-points/pickup-points.page';
import { FirmService } from './firm.service';
import { TableMethodsPage } from '../pages/table-methods/table-methods.page';
import { TableNumbersPage } from '../pages/table-numbers/table-numbers.page';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(
    private modalCtrl: ModalController,
    private firmService: FirmService,
    private storage: Storage,
  ) { }

  async openAddressModal(
    addresses: Address[],
    showAddressName: boolean,
    showAddressDefault: boolean,
    showSwitcher: boolean,
    address: Address|null = null,
  ) {
    const modal = await this.modalCtrl.create({
      component: AddressModalComponent,
      cssClass: 'contrast-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        addresses,
        address,
        showAddressName,
        showSwitcher,
        showAddressDefault,
      }
    });

    await modal.present();

    return modal;
  }

  async openFirmListModal(deliveryMethod: string) {
    const modal = await this.modalCtrl.create({
      component: FirmsListModalComponent,
      cssClass: 'contrast-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        deliveryMethod,
      }
    });

    await modal.present();

    return modal;
  }

  async openDeliveryDateTimeModal() {
    const dateTimeModal = await this.modalCtrl.create({
      component: DeliveryTimeModalComponent,
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: 'contrast-modal'
    });

    await dateTimeModal.present();

    return dateTimeModal;
  }

  async openPickPointSelectionModal(pickupPoints, firm, showSwitcher) {
    const pickupPointsModal = await this.modalCtrl.create({
      component: PickupPointsPage,
      cssClass: 'contrast-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        pickupPoints,
        showSwitcher,
      }
    });

    pickupPointsModal.onDidDismiss().then((data: any) => {
      if(!data.data.cancelled) {
        this.firmService.navigateToMenu(firm);
      }
    });

    return await pickupPointsModal.present();
  }

  async openTableMethodSelectionModal(methods, firm, showSwitcher) {
    if (!methods.find(m => m.id === 0)) {
      methods.unshift({
        id: 0,
        name: firm.labels.at_table,
        location_number_name: firm.labels.table_number,
        location_numbers: firm.table_numbers
      });
    }

    const tableMethodsModal = await this.modalCtrl.create({
      component: TableMethodsPage,
      cssClass: 'contrast-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        methods,
        showSwitcher,
      }
    });

    tableMethodsModal.onDidDismiss().then(async (data: any) => {
      if (!data.data.cancelled) {
        if (data.data.selectedMethod.location_numbers && data.data.selectedMethod.location_numbers.length > 0) {
          await this.openTableNumberSelectionModal(
            data.data.selectedMethod.location_numbers,
            data.data.selectedMethod.location_number_name,
            firm,
            showSwitcher
          );
        } else {
          await this.storage.set('selectedTableNumber', undefined);
          await this.firmService.navigateToMenu(firm);
        }
      }
    });

    return await tableMethodsModal.present();
  }

  async openTableNumberSelectionModal(tableNumbers, label, firm, showSwitcher) {
    const tableNumbersModal = await this.modalCtrl.create({
      component: TableNumbersPage,
      cssClass: 'contrast-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        tableNumbers,
        label,
        showSwitcher,
        required: firm.table_number_required
      }
    });

    tableNumbersModal.onDidDismiss().then((data: any) => {
      if (!data.data.cancelled) {
        this.firmService.navigateToMenu(firm);
      }
    });

    await tableNumbersModal.present();
  }
}
