import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Config } from '../../config';
import { ErrorService } from './error.service';
import { HeadersService } from './headers.service';
import { ThemingService } from './theming.service';
import { FirmService } from './firm.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmsService {

  endpoint = "/firms";

  constructor(private http: HttpClient,
              private storage: Storage,
              private headers: HeadersService,
              private errorService: ErrorService,
              private themingService: ThemingService,
              private firmService: FirmService) { }

  /* Get all firms */
  async getFirms() {
    let headers: any = await this.headers.getHeaders();
    headers = await headers.append('storeId', Config.STORE_ID);
    headers = await headers.append('language', await this.headers.getLanguage());
    return this.http.get(environment.API_URL + this.endpoint, { headers: headers })
    .toPromise()
    .then((data: any) => {
      if(data && data.data && data.data.distributor) {
        this.themingService.setTheme(data.data.distributor.theme);
        this.storage.set('firms', data.data.firms);
      }

      return data.data;
    })
    .catch(async err => {
      await this.errorService.checkFirmsErrors(err);
      return err;
    });
  }
}
