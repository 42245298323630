import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Day } from '../core/interfaces/day.interface';
import { DeliveryTimeStore } from '../modules/delivery-time/store/delivery-time.store';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
    private translate: TranslateService,
    private deliveryTimeStore: DeliveryTimeStore
  ) { }

  getDateDay(selectedDate): string {
    if (this.isToday(selectedDate)) {
      return this.translate.instant('TODAY');
    }

    return moment(selectedDate).format('DD/MM');
  }

  formatDateDay(dateDay: string): string {
    if (dateDay === this.translate.instant('TODAY')) {
      return moment().format('DD/MM/YYYY');
    }

    return moment(dateDay).format('DD/MM/YYYY');
  }

  formatDateTime(selectedDate): string {
    return moment(selectedDate).format('HH:mm');
  }

  getSelectedDay(selectedDate, hours) {
    const date = new Date(selectedDate);
    const dayId = date.getDay() !== 0 ? date.getDay() : 7;
    return hours.filter((day) => day.day_id === dayId)[0];
  }

  getOpeningTime(day) {
    if (this.isOnlyOpened('am', day) || this.isOpenedAMandPM(day)) {
      return day.am.from_time;
    } else if (this.isOnlyOpened('pm', day)) {
      return day.pm.from_time;
    }
  }

  getClosingTime(day) {
    if (this.isOnlyOpened('pm', day) || this.isOpenedAMandPM(day)) {
      return day.pm.to_time;
    } else if (this.isOnlyOpened('am', day)) {
      return day.am.to_time;
    }
  }

  isToday(selectedDay) {
    const today = new Date();
    const day = new Date(selectedDay);
    return (
      day.getDate() === today.getDate() && day.getMonth() === today.getMonth() && day.getFullYear() === today.getFullYear()
    );
  }

  isClosed(day): boolean {
    if (!day.am) {
      return day.pm.type === 'closed';
    }

    if (!day.pm) {
      return day.am.type === 'closed';
    }

    return day.am.type === 'closed' && day.pm.type === 'closed';
  }

  public isOpenedAm(day: Day): boolean {
    return day?.am?.type === 'open';
  }

  public isOpenedPm(day: Day): boolean {
    return day?.pm?.type === 'open';
  }

  public isOnlyOpened(type: 'am' | 'pm', day: Day): boolean {
    if (type === 'am') {
      return this.isOpenedAm(day) && !this.isOpenedPm(day);
    } else {
      return !this.isOpenedAm(day) && this.isOpenedPm(day);
    }
  }

  public isOpenedAMandPM(day: Day): boolean {
    return this.isOpenedAm(day) && this.isOpenedPm(day);
  }

  formatTime(time) {
    return moment(time).format('HH:mm');
  }

  setTimeOnDate(date: Date, time: string): Date {
    const timeArr = time.split(':')
      .map((value) => Number(value));

    date.setHours(timeArr[0]);
    date.setMinutes(timeArr[1]);

    return date;
  }

  getDateTillSnoozeEnd(snoozeEnd): string {
    const deliveryTime = this.deliveryTimeStore.currentDeliveryTime$.getValue();

    if (deliveryTime === 'asap') {
      return moment().to(snoozeEnd);
    }

    return moment(deliveryTime).to(snoozeEnd);
  }
}
