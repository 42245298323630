import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PickupPointStore } from 'src/app/modules/delivery-method/store/pickup-point.store';

@Component({
  selector: 'app-pickup-points',
  templateUrl: './pickup-points.page.html',
  styleUrls: ['./pickup-points.page.scss'],
})
export class PickupPointsPage {
  @Input() pickupPoints: any;
  @Input() showSwitcher: boolean;

  selectedPickupPoint: any;
  selectedPickupPointId: number;

  constructor(
    private modalCtrl: ModalController,
    private pickupPointStore: PickupPointStore,
  ) {
    this.pickupPointStore.getSelectedPickupPoint().then(
      (pickupPoint: any) => {
        if (pickupPoint) {
          this.selectPickupPoint(pickupPoint);
        }
      }
    );
  }

  selectPickupPoint(pickup): void {
    this.selectedPickupPoint = pickup;
    this.selectedPickupPointId = pickup.id;
  }

  async save(): Promise<void> {
    await this.pickupPointStore.setSelectedPickupPoint(this.selectedPickupPoint);
    await this.dismiss(false);
  }

  async dismiss(cancelled): Promise<void> {
    await this.modalCtrl.dismiss({ cancelled });
  }
}
