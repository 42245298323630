import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TableDeliveryMethodStore } from 'src/app/modules/delivery-method/store/table-delivery-method.store';

@Component({
  selector: 'app-table-numbers',
  templateUrl: './table-numbers.page.html',
  styleUrls: ['./table-numbers.page.scss'],
})
export class TableNumbersPage implements OnInit {
  @Input() tableNumbers: any;
  @Input() label: string;
  @Input() required: boolean;
  @Input() showSwitcher: boolean;

  selectedTableNumber: any;

  constructor(
    private tableDeliveryMethodStore: TableDeliveryMethodStore,
    private modalCtrl: ModalController,
    private router: Router,
  ) { }

  ngOnInit() {
    this.tableDeliveryMethodStore.getSelectedTableNumber()
      .then((tableNumber: any) => {
        this.selectedTableNumber = tableNumber;
      });
  }

  selectTableNumber(tableNumber) {
    this.selectedTableNumber = tableNumber;
  }

  async save() {
    await this.tableDeliveryMethodStore.setSelectedTableNumber(this.selectedTableNumber);
    await this.dismiss(false);
  }

  isButtonDisabled(): boolean {
    return !this.selectedTableNumber && this.required;
  }

  async dismiss(cancelled) {
    await this.modalCtrl.dismiss({ cancelled });
  }

  async goToMethodSelection(): Promise<void> {
    await this.modalCtrl.dismiss({ cancelled: true });
    await this.router.navigate(['/method-selection']);
  }
}
