import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FirmService } from '../../services/firm.service';
import { ModalController } from '@ionic/angular';
import { FirmInfoPage } from '../../pages/firm-info/firm-info.page';

@Component({
  selector: 'app-firms-block-item',
  templateUrl: './firms-block-item.component.html',
  styleUrls: ['./firms-block-item.component.scss'],
})
export class FirmsBlockItemComponent {

  @ViewChild('default', { static: true }) defaultTemplate: TemplateRef<any>;
  @ViewChild('locationPageStyle2', { static: true }) locationPageStyle2: TemplateRef<any>;

  @Input() firm: any;
  @Input() template: any = 'style1';

  constructor(public firmService: FirmService,
              private modalCtrl: ModalController) {}

  async openInfoModal() {
    const infoModal = await this.modalCtrl.create({
      component: FirmInfoPage,
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: "info-modal-height",
      componentProps: {
        'firm': this.firm
      }
    });
    await infoModal.present();
  }

  openFirm() {
    if(this.firm && !this.firm.is_demo) {
      this.firmService.openFirm(this.firm);
    }
  }

  getTemplateRef(template) {
    switch(template) {
      case 'style1': return this.defaultTemplate;
      case 'style2': return this.locationPageStyle2;
    }
  }

}