import { Component, Input } from '@angular/core';
import { ProductsService } from '../../services/products.service';
import * as moment from 'moment';
import { DateService } from '../../services/date.service';

@Component({
  selector: 'eo-snoozed-label',
  templateUrl: './snoozed-label.component.html',
  styleUrls: ['./snoozed-label.component.scss'],
})
export class SnoozedLabelComponent {
  @Input() snoozeEnd: string;

  moment = moment;

  constructor(public productsService: ProductsService, private dateService: DateService) {}

  getDateTillSnoozeEnd() {
   return this.dateService.getDateTillSnoozeEnd(this.snoozeEnd);
  }
}
