import { Component, Input } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';

@Component({
  selector: 'shop-message',
  templateUrl: 'shop-message.html',
  styleUrls: ['shop-message.scss']
})
export class ShopMessageComponent {

  @Input() firm;

  shop_notification: any;

  constructor(public storage: Storage) {}

  async ngOnInit() {
    await this.checkNotification();
    await this.setNotification();
  }

  async ngOnChanges() {
    await this.checkNotification();
    await this.setNotification();
  }

  async checkNotification() {
    if(this.firm.notification) {
      var notifications = await this.storage.get('shop_notifications');

      if(notifications === null || notifications === undefined) {
        notifications = [];
      }

      var firm_id = this.firm.id;
      let firm_notification = await notifications.filter(function (notification) {
        return notification.firm_id === firm_id;
      })

      if(firm_notification.length === 0) {
        let new_notification = {
          firm_id: this.firm.id,
          message: this.firm.notification.message,
          bg_color: this.firm.notification.bg_color,
          text_color: this.firm.notification.text_color,
          enabled: true,
          last_update: moment().format()
        }

        notifications.push(new_notification);
        this.storage.set('shop_notifications', notifications);
      }
      else {
        if(firm_notification[0].last_update < moment(this.firm.notification.last_modified).format() || 
          firm_notification[0].message !== this.firm.notification.message) {
          let new_notification = {
            firm_id: this.firm.id,
            message: this.firm.notification.message,
            bg_color: this.firm.notification.bg_color,
            text_color: this.firm.notification.text_color,
            enabled: true,
            last_update: moment().format()
          }
          await this.removeNotification();

          let notifications = await this.storage.get('shop_notifications');

          if(notifications === null || notifications === undefined) {
            notifications = [];
          }

          notifications.push(new_notification);
          await this.storage.set('shop_notifications', notifications);
        }
      }
    }
    else {
      this.removeNotification();
    }
  }

  async removeNotification() {
    let notifications = await this.storage.get('shop_notifications');

    if(notifications === null || notifications === undefined) {
      notifications = [];
    }

    notifications.forEach(notification => {
      if(notification.firm_id === this.firm.id) {
        let index = notifications.indexOf(notification);
        if (index !== -1) {
          notifications.splice(index, 1);
        }
      }
    });

    this.storage.set('shop_notifications', notifications);
  }

  async disableNotification() {
    this.shop_notification.enabled = false;
    await this.removeNotification();
    let notifications = await this.storage.get('shop_notifications');

    if(notifications === null || notifications === undefined) {
      notifications = [];
    }

    notifications.push(this.shop_notification);
    await this.storage.set('shop_notifications', notifications);
  }

  async setNotification() {
    let notifications = await this.storage.get('shop_notifications');
    if(notifications === null || notifications === undefined) {
      notifications = [];
    }
    notifications.forEach(notification => {
      if(notification.firm_id === this.firm.id) {
        this.shop_notification = notification;
      }
    });
  }

}
