import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirmService } from '../../services/firm.service';
import { ProductsService } from '../../services/products.service';
import { Availability } from '../../core/availability';
import { DeliveryTimeStore } from '../../modules/delivery-time/store/delivery-time.store';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() found_categories: any;
  @Input() all_categories: any;
  @Input() parentCategory: any;

  @Output() cancelOutput: any;

  firm: any;
  currentDeliveryTime = this.deliveryTimeStore.currentDeliveryTime$.getValue();

  constructor(private firmService: FirmService,
              public productsService: ProductsService,
              private deliveryTimeStore: DeliveryTimeStore) {
    this.cancelOutput = new EventEmitter<any>();
  }

  async ngOnInit() {
    this.firm = await this.firmService.getLocalFirm();
  }

  isCategoryAvailable(category): boolean {
    return this.productsService.isCategoryAvailable(category, this.parentCategory, this.firm?.uses_deliverect);
  }

  getAvailabilities(category): Availability[] {
    return this.productsService.getAvailabilities(category, this.parentCategory, this.firm?.uses_deliverect);
  }
}
