<ng-container [ngTemplateOutlet]="default"></ng-container>

<ng-template #default>
  <div class="item-box" tappable (click)="openProductSelect()">

    <!-- Discount -->
    <ion-row *ngIf="type === 'discount'">
      <ion-col class="full" size="12">
        <p *ngIf="discountType === 'amount'" class="discount-label font-12 v-align ion-text-center">{{ 'LOYALTY_DISCOUNT' | translate }} {{ currency }}{{ discountValue }} {{ 'DISCOUNT' | translate }}!</p>
        <p *ngIf="discountType === 'percentage'" class="discount-label font-12 v-align  ion-text-center">{{ 'LOYALTY_DISCOUNT' | translate }} {{ discountValue | number:'1.0-0' }}% {{ 'DISCOUNT' | translate }}!</p>
      </ion-col>
    </ion-row>

    <!-- Product -->
    <ion-row *ngIf="type === 'product' || type === 'category'">
      <ion-col size="3" sizeMd="2" sizeLg="1" class="left ion-no-padding" *ngIf="enableImage && selectedProduct?.images">
        <div class="item-img" [style.background-image]="'url('+ productsService.getImage(selectedProduct?.images) +')'"></div>
      </ion-col>
      <ion-col size="9" sizeMd="10" sizeLg="11" class="right">
        <div class="center-container">
          <p class="item-name font-12 ion-text-wrap ion-no-margin">{{ selectedProduct?.name }}</p>
          <p>{{ 'FULL_LOYALTY_CARD' | translate }}</p>
          <p *ngIf="listHasMultipleProducts()" class="free-product">{{ 'CHOOSE_OTHER_PRODUCT' | translate }}</p>
        </div>
        <p *ngIf="!listHasMultipleProducts()" class="total-price"><b>{{ 'FREE' | translate }}</b></p>
        <i *ngIf="listHasMultipleProducts()" class="far fa-hand-pointer click-icon"></i>
      </ion-col>
      <ion-col class="loyalty-msg" *ngIf="!isLoyaltyProductInStock()">
        <p>{{ 'PRODUCT_CURRENTLY_NOT_IN_STOCK' | translate }}</p>
        <p>{{ 'LOYALTY_SAVED_FOR_NEXT_ORDER' | translate }}</p>
      </ion-col>
    </ion-row>

    <!-- Multiple products select -->
    <ion-select class="product-select"
      *ngIf="listHasMultipleProducts()"
      [(ngModel)]="selectedProduct"
      (ngModelChange)="productChanged.emit(selectedProduct)"
      [interfaceOptions]="interfaceOptions"
      #productSelect>
      <ion-select-option *ngFor="let product of productList" [value]="product">{{product.name}}</ion-select-option>
    </ion-select>

  </div>
</ng-template>
