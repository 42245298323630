import { Component, ViewChild } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NavController, PickerController } from '@ionic/angular';
import { PickerOptions } from '@ionic/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CustomerService } from '../../services/customer.service';
import { FirmService } from '../../services/firm.service';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'reservation',
  templateUrl: 'reservation.html',
  styleUrls: ['reservation.scss']
})
export class ReservationComponent {

  @ViewChild('datepicker') datepicker;

  firm: any;

  // Reservation info
  amounts: any = [];
  chosen_amount: string;
  date: any;
  date_label: any;
  times: any;
  chosen_time: string;
  enableTime: boolean = false;

  minDate: any;
  maxDate: any;

  // User info
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  remarks: string;

  enableSuccess: boolean = false;
  enableError: boolean = false;
  disableBtn: boolean = false;
  error_msg: any;

  showButton: boolean = true;

  constructor(public navCtrl: NavController,
              public firmService: FirmService,
              public storage: Storage,
              public customerService: CustomerService,
              public navService: NavService,
              private pickerCtrl: PickerController,
              private keyboard: Keyboard,
              private translate: TranslateService) {
    this.firmService.getLocalFirm().then((firm) => {
      this.firm = firm;

      for (var i = this.firm.reservations.min_persons; i <= this.firm.reservations.max_persons; i++) {
        let amount = {
          text: i,
          value: i
        }
        this.amounts.push(amount);
      }

      this.chosen_amount = this.amounts[0].value;

      this.calculateMinAndMaxDates();
    });

    this.storage.get('login').then((login: any) => {
      if(login) {
        if(login.activated) {
          this.getUser(login.id);
        }
      }
    })

    this.keyboard.onKeyboardWillShow().subscribe(() => {
      this.showButton = false;
    });
    this.keyboard.onKeyboardWillHide().subscribe(() => {
      this.showButton = true;
    });
  }

  calculateMinAndMaxDates() {
    let advance_time = this.firm.reservations.booking_in_advance_hours;

    let add_days = Math.floor(advance_time/24);
    this.minDate = moment().add(add_days, 'day').format();
    this.maxDate = moment(this.minDate).add(1, 'year').format();
    this.date = this.minDate;
    this.getTimeSlots();
  }

  async getUser(id) {
    await this.customerService.linkFirm(id);
    let response: any = await this.customerService.get(id);
    this.first_name = response.first_name;
    this.last_name = response.last_name;
    this.email = response.email;
    this.phone = response.phone;
  }

  async getTimeSlots() {
    this.date_label = moment(this.date).format('dddd D MMMM');

    if(this.date != undefined && this.chosen_amount != undefined) {
      this.times = await this.firmService.getTimeSlots({ date: moment(this.date).format('DD/MM/YYYY'), guests: this.chosen_amount });
      this.chosen_time = this.times[0].value;
      this.enableTime = true;
    }
    else {
      this.enableTime = false;
      this.chosen_time = undefined;
    }

    this.checkDisable();
  }

  checkDisable() {
    if( this.chosen_amount != undefined &&
      this.chosen_time != undefined &&
      this.date != undefined &&
      this.first_name != undefined && this.first_name != "" &&
      this.last_name != undefined && this.last_name != "" &&
      this.email != undefined && this.email != "" &&
      this.phone != undefined && this.phone != "") {
      this.disableBtn = true;
    }
    else {
      this.disableBtn = false;
    }
  }

  async confirmReservation() {
    let reservation = {
      date: moment(this.date).format('DD/MM/YYYY'),
      guests: this.chosen_amount,
      time: this.chosen_time,
      name: this.first_name + " " + this.last_name,
      email: this.email,
      phone: this.phone,
      remarks: this.remarks
    }
    let response = await this.firmService.confirmReservation(reservation);

    if(response) {
      this.error_msg = response;
      this.enableSuccess = false;
      this.enableError = true;
    }
    else {
      this.error_msg = undefined;
      this.enableError = false;
      this.enableSuccess = true;
    }
  }

  async openAmountPicker() {
    let  opts: PickerOptions = {
      buttons: [
        {
          text: this.translate.instant('BUTTONS.CANCEL'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('BUTTONS.OK')
        }
      ],
      columns: [
        {
          name: 'amount',
          options: this.amounts
        }
      ]
    };

    let picker = await this.pickerCtrl.create(opts);
    picker.present();

    picker.onDidDismiss().then(async () => {
      let col = await picker.getColumn('amount');
      this.chosen_amount = col.options[col.selectedIndex].text;
      this.getTimeSlots();
    });
  }

  async openTimePicker() {
    let  opts: PickerOptions = {
      buttons: [
        {
          text: this.translate.instant('BUTTONS.CANCEL'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('BUTTONS.OK')
        }
      ],
      columns: [
        {
          name: 'timeslots',
          options: this.times
        }
      ]
    };

    let picker = await this.pickerCtrl.create(opts);
    picker.present();

    picker.onDidDismiss().then(async () => {
      let col = await picker.getColumn('timeslots');
      this.chosen_time = col.options[col.selectedIndex].text;
    });
  }
}
