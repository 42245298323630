<ion-grid>
  <ion-row>

    <ion-col size="6" *ngIf="displayPickupMethod()">
      <eo-square-btn
        icon="takeaway"
        [label]="'TAKEAWAY' | translate"
        [disabled]="disablePickupMethod()"
        (clicked)="selectPickupMethod()">
      </eo-square-btn>
    </ion-col>

    <ion-col size="6" *ngIf="displayDeliveryMethod()">
      <eo-square-btn
        icon="delivery"
        [label]="'DELIVERY' | translate"
        [disabled]="disableDeliveryMethod()"
        (clicked)="selectDeliveryMethod()">
      </eo-square-btn>
    </ion-col>

    <ion-col size="6" *ngIf="displayPickupPointMethod()">
      <eo-square-btn
        icon="pickuppoint"
        [label]="'PICKUP_POINT' | translate"
        [disabled]="disablePickupMethod()"
        (clicked)="selectPickupPointMethod()">
      </eo-square-btn>
    </ion-col>

    <ion-col size="6" *ngIf="displayTableMethod()">
      <eo-square-btn
        icon="qrcode"
        [label]="'ORDER_ON_THE_SPOT' | translate"
        [disabled]="disableTableMethod()"
        (clicked)="selectTableMethod()">
      </eo-square-btn>
    </ion-col>

    <ion-col size="6" *ngIf="displayViewMenu()">
      <eo-square-btn
        icon="book"
        [label]="'BUTTONS.VIEW_MENU' | translate"
        (clicked)="viewMenu()">
      </eo-square-btn>
    </ion-col>

  </ion-row>
</ion-grid>
