import { Injectable } from '@angular/core';
import { DeliveryTimeServiceInterface } from 'src/app/modules/delivery-time/services/delivery-time-service.interface';
import { DeliveryTimeServiceResolver } from 'src/app/modules/delivery-time/services/delivery-time-service.resolver';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DeliveryTimePickerService {
  private service: DeliveryTimeServiceInterface;

  constructor(
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
    private firmStore: FirmStore,
  ) {
  }

  public async getAvailableTimeslotsByTime(timestamp: string): Promise<any[]> {
    return await this.service.getFirmTimeslots(timestamp);
  }

  public async getIntervalForTimeslotIdAndTime(timeslotId: number, timestamp: string): Promise<any[]> {
    return this.filterValidIntervals(
      await this.service.getTimeIntervals(timeslotId, timestamp)
    );
  }

  public async selectFirstAvailableTimeslot(): Promise<void> {
    const time = await this.getFirstAvailableTime();
    const timeslots = await this.getAvailableTimeslotsByTime(time);

    if (timeslots.length === 0) {
      return;
    }

    const intervals = await this.getIntervalForTimeslotIdAndTime(timeslots[0].id, time);

    if (intervals.length === 0) {
      return;
    }

    this.service.updateDeliveryTime(moment(`${intervals[0].date} ${intervals[0].time}`, 'YYYY-MM-DD HH:mm').format());
  }

  public async selectFirstAvailableTime(): Promise<void> {
    await this.resolveService();

    if (this.service.hasOrderTimeslots()) {
      return this.selectFirstAvailableTimeslot();
    }

    let time = await this.getFirstAvailableTime();

    if (time !== 'asap') {
      time = moment(time).format();
    }

    this.service.updateDeliveryTime(time);
  }

  public async getFirstAvailableTime(): Promise<string> {
    if (this.firmStore.currentFirm.asap && !this.service.hasOrderTimeslots()) {
      return 'asap';
    }

    const calculate = await this.service.calculateTimestamp();

    return calculate.timestamp;
  }

  public async resolveService(): Promise<this> {
    this.service = await this.deliveryTimeServiceResolver
      .resolveDeliveryTimeService(this.firmStore.currentFirm);

    return this;
  }

  private filterValidIntervals(intervals: any[]): any[] {
    return intervals.filter((interval: any) => !interval.blocked && interval.available);
  }
}
