<div class="menu ion-padding">
    <ng-container *ngTemplateOutlet="getTemplateRef(firm?.template_navigation) || default">
    </ng-container>
</div>

<ng-template #default>

    <!-- Login section -->
    <div class="login" *ngIf="!user">
        <p class="greeting">{{ 'HI' | translate }}!</p>
        <p class="discover title-font">{{ 'DISCOVER' | translate }}</p>

        <ion-button class="login-btn" (click)="navService.loginRedirect='menu'; openPage('/login'); closeMenu()">{{ 'LOGIN' | translate }}</ion-button>
        <ion-button class="create-btn" (click)="navService.loginRedirect='menu'; openPage('/signin'); closeMenu()">{{ 'CREATE_ACCOUNT' | translate }}</ion-button>
    </div>

    <!-- Profile -->
    <div class="user-box" *ngIf="user">
        <p class="logout font-14" tappable (click)="logout()">{{ 'LOGOUT' | translate }}</p>
        <p class="greeting">{{ 'HI' | translate }},</p>
        <p class="name" tappable (click)="openPage('user-profile'); closeMenu()">{{ user?.first_name }}! <span>({{ 'EDIT_PROFILE' | translate }})</span></p>

        <!-- Loyalty -->
        <ion-row style="margin-top: 20px" *ngIf="firm?.use_loyalty" tappable (click)="openPage('/loyalty'); closeMenu()">
            <ion-col size="4" class="ion-text-center">
                <div class="loyalty-icon-box">
                    <i class="fas fa-gift icon v-align"></i>
                </div>
            </ion-col>
            <ion-col size="8">
                <div class="stamps v-align">
                    <p class="font-13">{{ user?.loyalty?.current_points }}/{{ firm?.loyalty?.points_required }} {{ 'STAMPS' | translate }}</p>
                    <div class="bar outer-bar">
                        <div class="bar inner-bar" style.width="{{user?.loyalty?.progress}}%"></div>
                    </div>
                </div>
            </ion-col>
        </ion-row>
    </div>

    <div class="selection-btns">
      <!-- Change firm -->
      <div class="change-firm"
           *ngIf="showChangeFirm()"
           (click)="$event.stopPropagation(); changeShop();"
           tappable>
        <p>{{ 'CHANGE_SHOP' | translate }}</p>
        <p>{{ firm?.name }} <i class="fas fa-caret-down icon"></i></p>
      </div>
    </div>

    <!-- Buttons -->
    <div class="menu-btns margin">
        <button [routerLink]="getProductsRouterLink()" (click)="navService.navigateToMenu(firm); closeMenu()" *ngIf="!firm?.uses_deliverect" routerLinkActive="activeLink">
            <i class="fas fa-list menu-icon"></i>
            <p class="shop-btn">{{ 'PRODUCTS' | translate }}</p>
        </button>
        <div *ngIf="firm?.uses_deliverect">
            <button
                *ngFor="let cat of navService.deliverect_categories"
                [routerLink]="getProductsRouterLink()"
                [queryParams]="{index: cat.index}"
                (click)="navService.navigateToMenu(firm, cat.index); closeMenu()"
                routerLinkActive="activeLink">
                <i class="fas fa-list menu-icon"></i>
                <p class="shop-btn">{{ cat.name }}</p>
            </button>
        </div>
        <button *ngIf="firm?.reservations?.active" [routerLink]="['/reservation']" routerLinkActive="activeLink" (click)="closeMenu()">
            <i class="fas fa-list menu-icon"></i>
            <p class="shop-btn">{{ 'BOOK_TABLE' | translate }}</p>
        </button>
        <button [routerLink]="['/basket']" routerLinkActive="activeLink" (click)="closeMenu()">
            <i class="fas fa-shopping-basket menu-icon"></i>
            <p class="shop-btn">{{ 'MY_CART' | translate }}</p>
        </button>
        <button *ngIf="firm?.use_loyalty" [routerLink]="['/loyalty']" routerLinkActive="activeLink" (click)="closeMenu()" [disabled]="!user || !firm?.use_loyalty">
            <i class="fas fa-gift menu-icon"></i>
            <p class="shop-btn">{{ 'LOYALTY_CARD' | translate }}</p>
        </button>
        <button *ngIf="firm?.show_newsfeed" [routerLink]="['/news-feed']" routerLinkActive="activeLink" (click)="closeMenu()">
            <i class="far fa-newspaper menu-icon"></i>
            <p class="shop-btn">{{ 'NEWS_FEED' | translate }}</p>
        </button>
        <button [routerLink]="['/messages']" routerLinkActive="activeLink" (click)="closeMenu()" [disabled]="!user">
            <i class="fas fa-bell menu-icon"></i>
            <p class="shop-btn">{{ 'NOTIFICATIONS' | translate }}</p>
            <span class="message-badge" *ngIf="customerService.unreadMessages > 0">{{ customerService.unreadMessages }}</span>
        </button>
        <button [routerLink]="['/recent-orders']" routerLinkActive="activeLink" (click)="closeMenu()" [disabled]="!user">
            <i class="fas fa-box-open menu-icon"></i>
            <p class="shop-btn">{{ 'ORDERS' | translate }}</p>
        </button>
        <button [routerLink]="['/info']" routerLinkActive="activeLink" (click)="closeMenu()">
            <i class="fas fa-info-circle menu-icon"></i>
            <p class="shop-btn">{{ 'INFO.TITLE' | translate }}</p>
        </button>
        <button [routerLink]="['/settings']" routerLinkActive="activeLink" (click)="closeMenu()">
            <i class="fas fa-cog menu-icon"></i>
            <p class="shop-btn">{{ 'SETTINGS.TITLE' | translate }}</p>
        </button>
    </div>

    <!-- Consents & language -->
    <ion-grid class="settings ion-no-padding">

        <!-- Language -->
        <ion-row style="height: 30px">
            <ion-col size="6" class="ion-no-padding">
                <p class="font-12 v-align">{{ 'LANGUAGE.TITLE' | translate }}</p>
            </ion-col>
            <ion-col size="6" class="ion-no-padding">
                <ion-select *ngIf="firm?.languages" [(ngModel)]="currentLanguage" [compareWith]="compare" (click)="setLanguageTitle()" (ionChange)="setLanguage($event.detail.value)" class="v-align" [interfaceOptions]="interfaceOptions">
                    <ion-select-option *ngFor="let lang of firm?.languages" [value]="lang">{{ lang.name }}</ion-select-option>
                </ion-select>
            </ion-col>
        </ion-row>

        <!-- Push -->
        <ion-row *ngIf="user">
            <ion-col size="9" class="ion-no-padding">
                <p class="font-12 v-align">{{ 'PUSH_NOTIFICATIONS' | translate }}</p>
            </ion-col>
            <ion-col size="3" class="ion-text-center ion-no-padding">
                <ion-toggle class="toggle-btn v-align" [(ngModel)]="pushPermission" [checked]="pushPermission" (ionChange)="togglePushPermission($event)"></ion-toggle>
            </ion-col>
        </ion-row>

        <!-- Opt in -->
        <ion-row *ngIf="user">
            <ion-col size="9" class="ion-no-padding">
                <p class="font-12 v-align">{{ 'GDPR.MERCHANT_CHECKBOX_MENU' | translate:{ firm_name: firm?.name } }}</p>
            </ion-col>
            <ion-col size="3" class="ion-text-center ion-no-padding">
                <ion-toggle class="toggle-btn v-align" [(ngModel)]="user.opt_in_merchant" [checked]="user?.opt_in_merchant" (ionChange)="customerService.setOptInForCustomer(user)"></ion-toggle>
            </ion-col>
        </ion-row>

        <!-- Standard firm -->
        <ion-row *ngIf="showChangeShop()">
            <ion-col size="9" class="ion-no-padding">
                <p class="font-12 v-align">{{ 'CONTAINER.SET_STANDARD' | translate }}</p>
            </ion-col>
            <ion-col size="3" class="ion-text-center ion-no-padding">
                <ion-toggle class="toggle-btn v-align" [checked]="standard_firm" (ionChange)="changeStandardFirm($event)"></ion-toggle>
            </ion-col>
        </ion-row>

    </ion-grid>
</ng-template>

<ng-template #sideMenuStyle2>

    <div class="sideMenuStyle2">

        <!-- Login section -->
        <div class="login" *ngIf="!user">
            <p class="greeting">{{ 'HI' | translate }}!</p>
            <p class="discover title-font">{{ 'DISCOVER' | translate }}</p>

            <ion-button class="login-btn" (click)="navService.loginRedirect='menu'; openPage('/login'); closeMenu()">{{ 'LOGIN' | translate }}</ion-button>
            <ion-button class="create-btn" (click)="navService.loginRedirect='menu'; openPage('/signin'); closeMenu()">{{ 'CREATE_ACCOUNT' | translate }}</ion-button>
        </div>

        <!-- Profile -->
        <div class="user-box" *ngIf="user">
            <!-- Loyalty -->
            <ion-row style="margin-top: 20px" *ngIf="firm?.use_loyalty" tappable (click)="openPage('/loyalty'); closeMenu()">
                <ion-col size="4" class="ion-text-center">
                    <div class="loyalty-icon-box">
                        <i class="fas fa-gift icon v-align"></i>
                    </div>
                </ion-col>
                <ion-col size="8">
                    <div class="stamps v-align">
                        <p class="font-13">{{ user?.loyalty?.current_points }}/{{ firm?.loyalty?.points_required }} {{ 'STAMPS' | translate }}</p>
                        <div class="bar outer-bar">
                            <div class="bar inner-bar" style.width="{{user?.loyalty?.progress}}%"></div>
                        </div>
                    </div>
                </ion-col>
            </ion-row>

            <p class="name" tappable (click)="openPage('user-profile'); closeMenu()">{{ user?.first_name }} <span>{{ user?.last_name }}</span></p>
        </div>

        <div class="selection-btns">

            <!-- Change firm -->
            <div class="change-firm"
                *ngIf="showChangeShop()"
                (click)="$event.stopPropagation(); changeShop();"
                tappable>
                <p>{{ firm?.address }}, {{ firm?.locality }}</p>
                <i class="fas fa-caret-down icon"></i>
            </div>

            <eo-change-delivery-method-btn
                *ngIf="firm?.theme?.show_delivery_method_first"
                template="style2">
            </eo-change-delivery-method-btn>
        </div>

        <hr class="line-padding">

        <!-- Buttons -->
        <div class="menu-btns">
            <button [routerLink]="getProductsRouterLink()" (click)="navService.navigateToMenu(firm); closeMenu()" *ngIf="!firm?.uses_deliverect" routerLinkActive="activeLink">
                <i class="fas fa-list menu-icon"></i>
                <p class="shop-btn">{{ 'SELECTION' | translate }}</p>
            </button>
            <div *ngIf="firm?.uses_deliverect">
                <button
                    *ngFor="let cat of navService.deliverect_categories"
                    [routerLink]="getProductsRouterLink()"
                    [queryParams]="{index: cat.index}"
                    (click)="navService.navigateToMenu(firm, cat.index); closeMenu()"
                    routerLinkActive="activeLink">
                    <i class="fas fa-list menu-icon"></i>
                    <p class="shop-btn">{{ cat.name }}</p>
                </button>
            </div>
            <button *ngIf="firm?.reservations?.active" [routerLink]="['/reservation']" routerLinkActive="activeLink" (click)="closeMenu()">
                <ion-icon class="menu-icon" name="md-list"></ion-icon>
                <p class="shop-btn">{{ 'BOOK_TABLE' | translate }}</p>
            </button>
            <button [routerLink]="['/basket']" routerLinkActive="activeLink" (click)="closeMenu()">
                <i class="fas fa-shopping-basket menu-icon"></i>
                <p class="shop-btn">{{ 'MY_CART' | translate }}</p>
            </button>
            <button *ngIf="firm?.use_loyalty" [routerLink]="['/loyalty']" routerLinkActive="activeLink" (click)="closeMenu()" [disabled]="!user || !firm?.use_loyalty">
                <i class="fas fa-gift menu-icon"></i>
                <p class="shop-btn">{{ 'LOYALTY_CARD' | translate }}</p>
            </button>
            <button *ngIf="firm?.show_newsfeed" [routerLink]="['/news-feed']" routerLinkActive="activeLink" (click)="closeMenu()">
                <i class="far fa-newspaper menu-icon"></i>
                <p class="shop-btn">{{ 'NEWS_FEED' | translate }}</p>
            </button>
            <button [routerLink]="['/messages']" routerLinkActive="activeLink" (click)="closeMenu()" [disabled]="!user">
                <i class="fas fa-bell menu-icon"></i>
                <p class="shop-btn">{{ 'NOTIFICATIONS' | translate }}</p>
                <span class="message-badge" *ngIf="customerService.unreadMessages > 0">{{ customerService.unreadMessages }}</span>
            </button>
            <button [routerLink]="['/recent-orders']" routerLinkActive="activeLink" (click)="closeMenu()" [disabled]="!user">
                <i class="fas fa-box-open menu-icon"></i>
                <p class="shop-btn">{{ 'ORDERS' | translate }}</p>
            </button>
            <button [routerLink]="['/info']" routerLinkActive="activeLink" (click)="closeMenu()">
                <i class="fas fa-info-circle menu-icon"></i>
                <p class="shop-btn">{{ 'RESTAURANT_INFO' | translate }}</p>
            </button>
            <button *ngIf="user" (click)="logout()">
                <i class="fas fa-sign-out-alt menu-icon"></i>
                <p class="shop-btn">{{ 'LOGOUT' | translate }}</p>
            </button>
            <button [routerLink]="['/settings']" routerLinkActive="activeLink" (click)="closeMenu()">
                <i class="fas fa-cog menu-icon"></i>
                <p class="shop-btn">{{ 'SETTINGS.TITLE' | translate }}</p>
            </button>
        </div>

        <hr>

        <!-- Second section of buttons -->
        <div class="menu-btns">
            <button (click)="shareShopUrl()" *ngIf="firm?.has_website">
                <i class="fas fa-share-alt menu-icon"></i>
                <p class="shop-btn">{{ 'SHARE' | translate }}</p>
            </button>
            <button *ngIf="firm?.faq_url" (click)="openFAQ()">
                <i class="fas fa-question-circle menu-icon"></i>
                <p class="shop-btn">{{ 'FAQ' | translate }}</p>
            </button>
        </div>

        <!-- Consents & language -->
        <ion-grid class="settings ion-no-padding">

            <!-- Language -->
            <ion-row style="height: 30px">
                <ion-col size="6" class="ion-no-padding">
                    <p class="font-12 v-align">{{ 'LANGUAGE.TITLE' | translate }}</p>
                </ion-col>
                <ion-col size="6" class="ion-no-padding">
                    <ion-select *ngIf="firm?.languages" [(ngModel)]="currentLanguage" [compareWith]="compare" (click)="setLanguageTitle()" (ionChange)="setLanguage($event.detail.value)" class="v-align" [interfaceOptions]="interfaceOptions">
                        <ion-select-option *ngFor="let lang of firm?.languages" [value]="lang">{{ lang.name }}</ion-select-option>
                    </ion-select>
                </ion-col>
            </ion-row>

            <!-- Push -->
            <ion-row *ngIf="user">
                <ion-col size="9" class="ion-no-padding">
                    <p class="font-12 v-align">{{ 'PUSH_NOTIFICATIONS' | translate }}</p>
                </ion-col>
                <ion-col size="3" class="ion-text-center ion-no-padding">
                    <ion-toggle class="toggle-btn v-align" [(ngModel)]="pushPermission" [checked]="pushPermission" (ionChange)="togglePushPermission($event)"></ion-toggle>
                </ion-col>
            </ion-row>

            <!-- Opt in -->
            <ion-row *ngIf="user">
                <ion-col size="9" class="ion-no-padding">
                    <p class="font-12 v-align">{{ 'GDPR.MERCHANT_CHECKBOX_MENU' | translate:{ firm_name: firm?.name } }}</p>
                </ion-col>
                <ion-col size="3" class="ion-text-center ion-no-padding">
                    <ion-toggle class="toggle-btn v-align" [(ngModel)]="user.opt_in_merchant" [checked]="user?.opt_in_merchant" (ionChange)="customerService.setOptInForCustomer(user)"></ion-toggle>
                </ion-col>
            </ion-row>

            <!-- Standard firm -->
            <ion-row *ngIf="showChangeShop()">
                <ion-col size="9" class="ion-no-padding">
                    <p class="font-12 v-align">{{ 'CONTAINER.SET_STANDARD' | translate }}</p>
                </ion-col>
                <ion-col size="3" class="ion-text-center ion-no-padding">
                    <ion-toggle class="toggle-btn v-align" [checked]="standard_firm" (ionChange)="changeStandardFirm($event)"></ion-toggle>
                </ion-col>
            </ion-row>

        </ion-grid>

    </div>

</ng-template>
