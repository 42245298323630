<div class="sub-toolbar flex ion-justify-content-between font-13 ion-align-items-center">
  <div
    *ngIf="hasMethodSelected()"
    class="delivery-method"
    tappable
    (click)="handleOnDeliveryMethodTap()"
  >
    <div class="flex ion-align-items-center" *ngIf="selectedDeliveryMethod === DELIVERY_METHODS.PICKUP">
      <ion-icon src="../../assets/images/takeaway.svg"></ion-icon>
      <div class="text">{{ 'TAKEAWAY' | translate }}</div>
    </div>
    <div class="flex ion-align-items-center" *ngIf="selectedDeliveryMethod === DELIVERY_METHODS.DELIVERY">
      <ion-icon src="../../assets/images/delivery.svg"></ion-icon>
      <div class="text">{{ 'DELIVERY' | translate }}<br /><span class="font-10">({{ deliveryAddress$ | async }})</span></div>
    </div>
    <div class="flex ion-align-items-center" *ngIf="selectedDeliveryMethod === DELIVERY_METHODS.TABLE">
      <ion-icon src="../../assets/images/qrcode.svg"></ion-icon>
      <div class="text">{{ tableMethodName$ | async }} <span class="font-10">({{ tableNumber$ | async }})</span></div>
    </div>
    <div class="flex ion-align-items-center" *ngIf="selectedDeliveryMethod === DELIVERY_METHODS.PICKUP_POINT">
      <ion-icon src="../../assets/images/pickuppoint.svg"></ion-icon>
      <div class="text">{{ 'PICKUP_POINT' | translate }}</div>
    </div>

    <i class="fas fa-chevron-down font-10"></i>
  </div>

  <div
    *ngIf="!hasMethodSelected()"
    class="delivery-method"
    tappable
    (click)="goToMethodSelection()"
  >
    {{ 'CHANGE_DELIVERY_METHOD_TITLE' | translate }}
  </div>

  <div *ngIf="hasOneDeliveryMethodSelectedButNotEatIn()" tappable (click)="openDateTimeModal()">
    <i class="far fa-clock"></i>&nbsp;&nbsp;{{ currentDeliveryTime$ | async }}
  </div>
</div>
