import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MenuController, NavController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';
import { CustomerService } from '../../services/customer.service';
import { FirmService } from '../../services/firm.service';
import { Config } from 'src/config';
import { BasketItemsService } from '../../services/basket-items.service';
import { Router } from '@angular/router';
import { NavService } from '../../services/nav.service';
import { AuthService } from '../../services/auth.service';
import { UpdateService } from '../../services/update.service';
import { ShareService } from '../../services/share.service';
import { BrowserService } from '../../services/browser.service';
import { PushService } from '../../services/push.service';
import { DELIVERY_METHODS } from '../../constants/delivery-methods';
import { DeliveryMethodService } from '../../services/delivery-method.service';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss'],
})
export class HamburgerComponent {

  @ViewChild('default', { static: true }) defaultTemplate: TemplateRef<any>;
  @ViewChild('sideMenuStyle2', { static: true }) sideMenuStyle2: TemplateRef<any>;

  user: any;
  firm: any;
  currentLanguage: any;
  multifirm: boolean = Config.IS_MULTIFIRM;
  pushPermission: boolean;

  /* set as default */
  initTrigger: any = false;
  standard_firm: boolean;
  setStandardFirmBtn: boolean;
  interfaceOptions: any;

  currentDeliveryMethod = '';

  constructor(
    public navCtrl: NavController,
    private menu: MenuController,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private storage: Storage,
    public customerService: CustomerService,
    private firmService: FirmService,
    private router: Router,
    private basketItemsService: BasketItemsService,
    public navService: NavService,
    private shareService: ShareService,
    private browserService: BrowserService,
    private pushService: PushService,
    private authService: AuthService,
    private updateService: UpdateService,
    private deliveryMethodService: DeliveryMethodService,
    private languageService: LanguageService,
    private firmStore: FirmStore,
  ) {
    this.firmService.firmObservable.subscribe(async (firm) => {
      this.firm = firm;
      this.checkStandardFirm();
      this.currentLanguage = await this.storage.get('language');
    });

    this.authService.loggedOutObservable.subscribe(() => {
      this.user = undefined;
    });

    this.customerService.loggedInObservable.subscribe(async (user) => {
      this.user = user;
      this.firm = await this.firmService.getLocalFirm();
      if(this.firm && this.firm.use_loyalty && this.user && this.user.loyalty) {
        this.user.loyalty.progress = (this.user.loyalty.current_points/this.firm.loyalty.points_required)*100;
      }
      this.checkStandardFirm();
    });

    this.deliveryMethodService.selectDeliveryMethodUpdate
      .subscribe((method: string) => {
        this.currentDeliveryMethod = method;
      });
  }

  async ionViewWillEnter() {
    this.initTrigger = true;
    this.firm = await this.firmService.getLocalFirm();
    this.checkStandardFirm();
    this.currentLanguage = await this.storage.get('language');
    this.currentDeliveryMethod = await this.deliveryMethodService.getSelectedDeliveryMethod();
  }

  showChangeFirm() {
    return Config.IS_MULTIFIRM && !this.firmStore.distributor.theme.show_delivery_method_first;
  }

  getPushPermission() {
    this.pushService.getPermissionForUser(this.user).then((permission) => {
      this.pushPermission = permission;
    });
  }

  showChangeShop() {
    return Config.IS_MULTIFIRM && this.currentDeliveryMethod !== DELIVERY_METHODS.DELIVERY;
  }

  openPage(page) {
    this.router.navigate([page]);
  }

  changeShop() {
    if(Config.IS_MULTIFIRM) {
      this.navCtrl.navigateRoot('/container-menu');
    }
    else {
      this.navCtrl.navigateRoot('/store-menu');
    }
  }

  togglePushPermission(event) {
    this.user.allow_push_notifications = event.detail.checked;
    this.customerService.setPushForCustomer(this.user.id, event.detail.checked);
    this.pushService.getDevicePermission().then((permission) => {
      if(!permission && event.detail.checked) {
        this.pushService.promptPermission();
        this.closeMenu();
      }
    });
  }

  // Check if current firm equals standard firm
  async checkStandardFirm() {
    var standard_firm = await this.storage.get('standard_firm');
    if(standard_firm) {
      if(standard_firm.id === this.firm.id) {
        this.standard_firm = true;
      }
      else {
        this.standard_firm = false;
      }
    }
    else {
      this.standard_firm = false;
    }
  }

  async setLanguage(language) {
    if(this.translate.currentLang != language.code) {
      if(this.router.url != '/container-menu' && this.router.url != '/') {
        // remove all from basket
        let basketItems = await this.basketItemsService.get();
        if(basketItems.length > 0) {
          this.basketItemsService.removeAll();
        }

        // set to new language
        this.languageService.setLanguage(language.code);
        await this.storage.set('language', language);
        this.currentLanguage = language;

        // get firm & products with new language
        let firm_id = await this.storage.get('firm');
        await this.firmService.setFirm(firm_id);
        this.updateService.menuUpdate.next();
        this.navService.navigateToMenu(this.firm);
        this.deliveryMethodService.deliveryMethodUpdate.next();
      }
    }
  }

  async changeStandardFirm(event) {
    if(this.initTrigger) {
      // Standard firm -> current firm
      if(!event.detail.checked) {
        var standard_products = await this.storage.get('standard_products');
        let firm = await this.firmService.getLocalFirm();

        // set localStorage
        await this.firmService.setCurrentFirm(firm, standard_products);

        // Empty standard firm
        await this.storage.set('standard_firm', null);
        await this.storage.set('standard_products', null);
        await this.storage.set('last_download_firm', null);
      }
      // Current firm -> standard firm
      else {
        let current_firm = await this.storage.get('current_firm');
        let current_products = await this.storage.get('current_products');

        // Empty current firm
        await this.storage.set('current_firm', null);
        await this.storage.set('current_products', null);

        // set localStorage
        if(current_firm && current_products) {
          await this.firmService.setStandardFirm(current_firm, current_products);
        }
      }
    }
  }

  /* Logout current user */
  async logout() {
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant('DIALOGS.LOGOUT_TITLE'),
      message: this.translate.instant('DIALOGS.LOGOUT_MESSAGE'),
      buttons: [
        {
          text: this.translate.instant('LANGUAGE.ALERT_NO'),
          handler: () => {
            // Annuleer
          }
        },
        {
          text: this.translate.instant('LANGUAGE.ALERT_YES'),
          handler: async () => {
            this.authService.logout();

            if(this.menu.isOpen()) {
              this.menu.close();
            }
          }
        }
      ]
    });

    return await confirm.present();
  }

  compare(a: {code: any, name: string}, b: {code: any, name: string}) {
    if(a.code === b.code) {
      return true;
    }
    else {
      return false;
    }
  }

  setLanguageTitle() {
    this.interfaceOptions = {
      header: this.translate.instant('SELECT_A_LANGUAGE')
    }
  }

  shareShopUrl() {
    const firmInfo = this.firm.info.find((i) => i.content_type === 'firm_info');
    this.shareService.shareShopUrl(this.firm.name, this.firm.website_details.sitename, firmInfo.content, this.firm.logo_fullsize);
  }

  openFAQ() {
    this.browserService.open(this.firm.faq_url);
  }

  getTemplateRef(template) {
    switch(template) {
      case 'style1': return this.defaultTemplate;
      case 'style2': return this.sideMenuStyle2;
    }
  }

  getProductsRouterLink() {
    return this.firm && this.firm.template_categories === 'style2' ? ['/category'] : ['/menu'];
  }

  closeMenu() {
    this.menu.close();
  }

}
