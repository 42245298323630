import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'pickerDate'
})
export class PickerDatePipe implements PipeTransform {

  transform(value: any, language) {
    return moment(value).format('DD/MM');
  }

}
