import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Config } from '../../config';
import { AlertService } from './alert.service';
import { ErrorService } from './error.service';
import { HeadersService } from './headers.service';
import { Network } from '@ionic-native/network/ngx';
import { FirmService } from './firm.service';
import { ProductsService } from './products.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  endpoint_update = '/checkupdate';
  endpoint_last_mod = "/firm/lastmodification";

  menuUpdate = new Subject();
  menuUpdateObservable = this.menuUpdate.asObservable();

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private storage: Storage,
    private headers: HeadersService,
    private alertService: AlertService,
    private network: Network,
    private firmService: FirmService,
    private productsService: ProductsService
  ) {}

  /* check last modifications for updates */
  async checkLastModification() {
    return new Promise(async (resolve, reject) => {
      // no internet connection - do nothing
      if(this.network.type === 'unknown' || this.network.type === 'none') {}
      // internet connection - check for updates
      else {
        // get last modifications from server
        let response: any = await this.getLastModificationTimestamps();

        // get last download timestamps
        let last_download_firm = await this.storage.get('last_download_firm');
        let last_download_products = await this.storage.get('last_download_products');

        if(response) {
          let firm_changed = false;
          let products_changed = false;

          // check if update of firm is needed
          if(last_download_firm < response.last_firm_update || last_download_firm === null) {
            // get firm
            let firm: any = await this.firmService.getFirmData();

            // set local firm & distributor
            await this.storage.set('distributor', firm.distributor);

            let standard = await this.storage.get('standard');

            if(standard) {
              await this.storage.set('standard_firm', firm);
            }
            else {
              await this.storage.set('current_firm', firm);
            }

            firm_changed = true;
          }

          // check if update of products is needed
          if(last_download_products < response.last_product_update || last_download_products === null){
            // get products
            let products = await this.productsService.getProductData();

            // set local products
            let standard = await this.storage.get('standard');

            if(standard) {
              await this.storage.set('standard_products', products);
            }
            else {
              await this.storage.set('current_products', products);
            }

            products_changed = true;
          }

          resolve({ firm_changed: firm_changed, products_changed: products_changed});
        }
        else {
          reject();
        }

      }
    });
  }

  /* Check last modification of the firm data */
  async getLastModificationTimestamps() {
    let headers: any = await this.headers.getHeaders();
    headers = await headers.append('firmId', String(await this.storage.get('firm')));
    headers = await headers.append('passwordToken', await this.headers.checkToken());
    headers = await headers.append('demoPasswordToken', await this.headers.getDemoToken());
    if(Config.STORE_ID) {
      headers = await headers.append('storeId', Config.STORE_ID);
    }

    return this.http.get(environment.API_URL + this.endpoint_last_mod, { headers: headers })
    .toPromise()
    .then(data => {
      return data;
    })
    .catch(async err => {
      await this.errorService.checkLastModificationErrors(err);
    });
  }

  /* Check app version */
  async checkAppVersion() {
    let headers = await this.headers.getHeaders();
    if(Config.SINGLE_APP) {
      headers = await headers.append('firmId', Config.FIRM_ID);
    }
    else {
      headers = await headers.append('storeId', Config.STORE_ID);
    }

    return this.http.post(environment.API_URL + this.endpoint_update, { version : Config.VERSION }, { headers: headers })
    .toPromise()
    .then(async (data: any) => {
      if(data.update_required) {
        this.alertService.presentUpdateError(data);
        return true;
      }
      else {
        return false;
      }
    })
    .catch(async err => {
      await this.errorService.checkLastModificationErrors(err);
    });
  }
}
