import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FirmStore {
  public currentFirm: any = null;
  public distributor: any = null;
  public demoFirms: any = null;
  public firms: any[] = [];

  public setDistributor(distributor: any): void {
    this.distributor = distributor;
  }

  public setCurrentFirm(currentFirm: any): void {
    this.currentFirm = currentFirm;
  }

  public setFirms(firms: any[]): void {
    this.firms = firms;
  }

  public setDemoFirms(demoFirms: any): void {
    this.demoFirms = demoFirms;
  }
}

