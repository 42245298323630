import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  constructor(private storage: Storage, 
              private productsService: ProductsService) { }

  async getFavorites(categories) {
    var favoriteList = await this.get();

    if(!favoriteList.length) {
      return undefined;
    }
    else {
      var all_products = await this.productsService.getAllProducts(categories);

      await favoriteList.forEach(async favObj => {
        let products_found = await all_products.filter(product => product.id === favObj.item_id);

        if(products_found.length > 0) {
          favObj.item = products_found[0];
        }
        else {
          let index = favoriteList.indexOf(favObj);
          if (index !== -1) {
            favoriteList.splice(index, 1);
          }
          await this.remove(favObj);
        }
      });

      return favoriteList;
    }
  }

  async get() {
    let allFavorites = await this.storage.get('favorites');
    var firmFavorites;

    if(allFavorites === null || allFavorites === undefined) {      
      firmFavorites = [];
    }
    else {
      let firm_id = await this.storage.get('firm');
      firmFavorites = allFavorites.filter(function (favObj) {
        return favObj.firm_id === firm_id;
      })
    }

    return firmFavorites;
  }

  async add(item_id) {
    let favorites = await this.storage.get('favorites');

    if(favorites === null || favorites === undefined) {
      favorites = [];
    }

    // get firm id and make favorite object
    let firm_id = await this.storage.get('firm');
    let favoriteObj = {
      firm_id: firm_id,
      item_id: item_id
    }

    // list is not empty
    if(favorites.length != 0) {

      // check if item is in list
      var found = favorites.some(function (favItem) {
        return (favItem.firm_id === firm_id) && (favItem.item_id === item_id);
      })

      // item not found - push
      if (!found) {
        favorites.push(favoriteObj);
      }
      // item found - remove
      else {
        favorites.forEach(favObj => {
          if(favObj.firm_id === firm_id && favObj.item_id === item_id) {
            let index = favorites.indexOf(favObj);
            if (index !== -1) {
                favorites.splice(index, 1);
            }
          }
        });
      }
    }
    // list is empty, add item
    else {
      favorites.push(favoriteObj);
    }

    this.storage.set('favorites', favorites);
  }

  async remove(favorite) {
    let favorites = await this.storage.get('favorites');

    if(favorites === null || favorites === undefined) {
      favorites = [];
    }

    favorites.forEach(favObj => {
      if(favObj.firm_id === favorite.firm_id && favObj.item_id === favorite.item_id) {
        let index = favorites.indexOf(favObj);
        if (index !== -1) {
          favorites.splice(index, 1);
        }
      }
    });

    this.storage.set('favorites', favorites);
  }
}
