import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor(
    private storage: Storage,
    private ss: SocialSharing
  ) { }

  shareShopUrl(shopName, siteName, description, image) {
    this.storage.get('language').then((language) => {
      const url = `${environment.SITE_URL}/${siteName}/${language.code}`;
      this.ss.share(description, shopName, image, url);
    });
  }
}
