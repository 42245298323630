import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { BasketItemsService } from './basket-items.service';
import { DemoPasswordService } from './demo-password.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private navCtrl: NavController,
              private router: Router,
              private authService: AuthService,
              private network: Network,
              private storage: Storage,
              private auth: AuthService,
              private basketItemsService: BasketItemsService,
              private alertService: AlertService,
              private demoPasswordService: DemoPasswordService) {

              }

  /* Check if internet connection is on */
  checkConnection() {
    if(this.network.type === 'unknown' || this.network.type === 'none') {
      this.alertService.presentConnectionError();
      return false;
    }
    else {
      return true;
    }
  }

  /* Provider checks */
  async checkCountriesErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkFirmsErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      this.router.navigate(['/container-menu']);
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else if(err.code === 'MISSING_STORE_ID' || status === 422) {
      // do nothing
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkEmailErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === "CUSTOMER_NOT_ACTIVATED") {
      return err.code;
    }
    else if(err.code === "CUSTOMER_NOT_FOUND") {
      this.logoutUser();
      return err.code;
    }
    else if(err.code === 'CUSTOMER_BLOCKED_FOR_FIRM') {
      this.alertService.presentBlockedError();
    }
    else if(err.code === "CUSTOMER_INVALID_FOR_FIRM") {
      this.alertService.presentInvalidCustomerError();
    }
    else if(err.code === "VALIDATION_ERROR") {
      this.alertService.presentValidationError(err.errors);
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkCustomerErrors(error, pw_reset?) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === "CUSTOMER_NOT_ACTIVATED") {
      this.alertService.presentCustomerNotActivatedRedirectError();
    }
    else if(err.code === "CUSTOMER_NOT_FOUND") {
      if(pw_reset) {
        this.alertService.presentCustomerNotFoundError();
      }
      else {
        this.alertService.presentCustomerNotFoundOrderError();
      }
      this.logoutUser();
    }
    else if(err.code === 'CUSTOMER_BLOCKED_FOR_FIRM') {
      this.alertService.presentBlockedError();
    }
    else if(err.code === "CUSTOMER_INVALID_FOR_FIRM") {
      this.alertService.presentInvalidCustomerError();
    }
    else if(err.code === "VALIDATION_ERROR") {
      this.alertService.presentValidationError(err.errors);
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.alertService.presentSessionError();
      this.logoutUser();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkFirmErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 404) {
      // ignore
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    if(err.code === "RESERVATION_NOT_POSSIBLE" || err.code === "VALIDATION_ERROR") {
      return 'RESERVATION_ERROR';
    }
    else if(err.code === "FIRM_MISSING_SETTINGS_FOR_DELIVERY_OR_PICKUP") {
      this.alertService.presentMissingSettingsError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.alertService.presentSessionError();
      this.logoutUser();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkPasswordErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 403) {
      return "403";
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkCalculateErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === 'DELIVERY_ZIPCODE_INVALID_FOR_FIRM') {
      return 'INVALID_ZIPCODE';
    }
    else if(err.code === 'DELIVERY_DISTANCE_TOO_FAR') {
      return err.code;
    }
    else if(err.code === 'ERROR_CALCULATING_DELIVERY_DISTANCE') {
      return err.code;
    }
    else if(err.code === 'ORDER_PRICE_TOO_LOW_FOR_DELIVERY') {
      return err.code;
    }
    else if(err.code === 'BASKET_HAS_INVALID_PRODUCTS') {
      this.alertService.presentInvalidProductsError();
      this.basketItemsService.removeAll();
    }
    else if(err.code === "FIRM_MISSING_SETTINGS_FOR_DELIVERY_OR_PICKUP") {
      this.alertService.presentMissingSettingsError();
    }
    else if(err.code === 'VALIDATION_ERROR') {
      this.alertService.presentFaultyOrderError();
    }
    else if(err.code === 'FIRM_NO_PICKUP') {
      this.alertService.presentNoPickupError();
    }
    else if(err.code === 'FIRM_NO_DELIVERY') {
      this.alertService.presentNoDeliveryError();
    }
    else if(err.code === 'FIRM_NO_TABLE') {
      this.alertService.presentNoTableError();
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.alertService.presentSessionError();
      this.logoutUser();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkOrderErrors(error, firm?) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(err.errors) {
      if(err.errors.error === 'SHOP_OFFLINE') {
        this.alertService.presentShopOfflineError(err.errors.message);
      }
    }

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === 'CUSTOMER_NOT_ACTIVATED') {
      this.alertService.presentCustomerNotActivatedError();
    }
    else if(err.code === 'ORDER_PRICE_TOO_LOW_FOR_DELIVERY') {
      this.alertService.presentMinDeliveryPriceError(firm, err.message);
    }
    else if(err.code === 'DELIVERY_ZIPCODE_INVALID_FOR_FIRM') {
      this.alertService.presentZipcodeError();
      return err.code;
    }
    else if(err.code === 'INVALID_TIMESTAMP') {
      return "INVALID_TIMESTAMP";
    }
    else if(err.code === "FIRM_MISSING_SETTINGS_FOR_DELIVERY_OR_PICKUP") {
      this.alertService.presentMissingSettingsError();
    }
    else if(err.code === 'FIRM_NOT_FOUND') {
      this.showFirmNotFoundError();

      // Delete order & basketItems
      this.storage.set('order', undefined)
      this.basketItemsService.removeAll();
    }
    else if(err.code === 'CUSTOMER_NOT_FOUND') {
      this.alertService.presentCustomerNotFoundOrderError();
      this.logoutUser();
    }
    else if(err.code === 'INVALID_UUID') {
      return "INVALID_UUID";
    }
    else if(err.code === 'BASKET_HAS_INVALID_PRODUCTS' || err.code === 'BASKET_HAS_INVALID_INGREDIENTS' || err.code === 'BASKET_HAS_INVALID_PRODUCT_OPTIONS' || err.code === 'VALIDATION_ERROR') {
      this.alertService.presentInvalidOrderError();
      this.basketItemsService.removeAll();
    }
    else if(err.code === 'ERROR_CALCULATING_DELIVERY_DISTANCE') {
      this.alertService.presentCalculateDistanceError();
    }
    else if(err.code === 'DELIVERY_DISTANCE_TOO_FAR') {
      this.alertService.presentDeliveryTooFarError();
    }
    else if(err.code === 'INVALID_PAYMENT_METHOD') {
      this.alertService.presentInvalidPaymentError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(err.code === "MAX_BASKET_QUANTITY_EXCEEDED") {
      this.alertService.presentBasketQuantityError(error.message);
    }
    else if(err.code === "COUNTRY_NOT_SUPPORTED") {
      this.alertService.presentCountryError();
    }
    else if(err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.alertService.presentSessionError();
      this.logoutUser();
    }
    else if(err.code === 'INVALID_COUPON_CODE') {
      this.alertService.presentInvalidCouponError();
    }
    else if(err.code === 'ORDER_PRICE_TOO_LOW_FOR_COUPON' || err.code === 'TABLE_NR_REQUIRED') {
      this.alertService.presentCustomAlert(err.message);
    }
    else if(err.code === 'COUPON_CODE_USED') {
      this.alertService.presentCouponAlreadyUsedError();
    }
    else if(status === 422) {
      return "BASKET_CHANGE";
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkErrors(status) {
    let errorTriggered = true;

    switch(status) {
      case 401: {
        await this.auth.getToken();
        break;
      }
      case 503: {
        this.alertService.showMaintenanceError();
        break;
      }
      case 500: {
        this.alertService.presentServerError();
        break;
      }
      default: {
        errorTriggered = false;
        break;
      }
    }

    return errorTriggered;
  }

  async checkDeleteErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(err.code === 'ORDER_NOT_FOUND') {
      // Do nothing
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(status === 0) {
      // Do nothing
    }
    else {
      // Do nothing
    }
  }

  async checkPaymentErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === 'INVALID_PAYMENT_METHOD') {
      this.alertService.presentInvalidPaymentError();
    }
    else if(err.code === 'ORDER_NOT_FOUND') {
      this.alertService.presentOrderNotFoundError();
      return err.code;
    }
    else if(err.code === 'ORDER_ALREADY_PAID') {
      return err.code;
    }
    else if(err.code === 'VALIDATION_ERROR') {
      this.alertService.presentValidationError(err.erros);
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.alertService.presentSessionError();
      this.logoutUser();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkLastModificationErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      this.demoPasswordService.presentDemoPasswordError();
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  async checkProductsErrors(error) {
    let status = error.status;
    let err = this.parseBody(error.error);

    if(status === 404) {
      return 'NO_PRODUCTS';
    }
    else if(status === 503) {
      this.alertService.showMaintenanceError();
    }
    else if(err.code === "FIRM_NOT_FOUND") {
      this.showFirmNotFoundError();
    }
    else if(err.code === "DEMO_PASSWORD_TOKEN_INVALID") {
      let response = await this.demoPasswordService.presentDemoPasswordError();

      if(response === 'PASSWORD_CORRECT') {
        return('PASSWORD_CORRECT');
      }

      return response;
    }
    else if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 0) {
      this.alertService.presentServerError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

  /* Firm not found */
  async showFirmNotFoundError() {
    this.alertService.presentFirmNotFoundError();

    // Check if standard firm or current firm
    let standard = await this.storage.get('standard');

    if(standard) {
      // Remove the standard firm
      await this.storage.set('standard', false);
      await this.storage.set('standard_firm', null);
      await this.storage.set('standard_products', null);
      await this.storage.set('last_download_firm', null);
    }
  }

  /* Handle customerToken invalid */
  async logoutUser() {
    this.authService.logout();
    this.navCtrl.navigateRoot('/login');
  }

  parseBody(originalBody) {
    var body;

    try {
      body = JSON.parse(originalBody);
    }
    catch(error) {
      body = originalBody;
    }

    return body;
  }

}
