<div class="flex ion-justify-content-center ion-align-items-center full-height">
  <ion-grid class="eo-modal-container">

    <ion-row class="ion-align-items-center">
      <ion-col>
        <ng-content select="[topLeft]"></ng-content>
      </ion-col>
      <ion-col class="close">
        <i tappable class="far fa-times-circle" (click)="handleOnClose()"></i>
      </ion-col>
    </ion-row>

    <ion-row class="content">
      <ng-content select="[content]"></ng-content>
    </ion-row>

    <ion-row *ngIf="customFooter" class="footer">
      <ng-content select="[footer]"> </ng-content>
    </ion-row>

    <ion-row *ngIf="!customFooter" class="footer">
      <ion-col>
        <eo-button
          [disabled]="!submitButtonEnabled"
          [label]="submitText"
          (clicked)="submitHandler()"
        ></eo-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
