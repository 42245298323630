<div class="flex ion-align-items-center" tappable (click)="handleSelect()">

  <div *ngIf="!displayQuantity()" class="flex ion-align-items-center ion-justify-content-center mr-5">
    <ion-checkbox
      *ngIf="isCheckbox() && multiMax === 1"
      [disabled]="disabled || isSnoozed"
      [checked]="checked">
    </ion-checkbox>

    <ion-radio
      *ngIf="isRadio()"
      [value]="id"
      mode="md">
    </ion-radio>
  </div>

  <div *ngIf="displayQuantity()" class="quantity mr-5" (click)="$event.stopPropagation(); $event.stopImmediatePropagation()">
    <button
      (click)="subtract()"
      [disabled]="quantity <= 0 || disabled">
      <i class="fas fa-minus"></i>
    </button>

    <ion-input [(ngModel)]="quantity" [readonly]="true"></ion-input>

    <button
      (click)="add()"
      [disabled]="hasReachedMultiMax() || reachedGroupMax || disabled">
      <i class="fas fa-plus"></i>
    </button>
  </div>

  <div *ngIf="enableImage" class="image-container flex ion-align-items-center ion-justify-content-center">
    <img *ngIf="image" [src]="image">
    <div *ngIf="!image" class="missing-img"></div>
  </div>

  <div class="flex ion-align-items-center ion-justify-content-between full-width ml-5">
    <div>
      <p class="title-txt">
        {{ label }}
        <i *ngIf="description"
           class="fas fa-question-circle description"
           [tooltip]="description"
           [hideOthers]="true"
           event="click"
           positionV="bottom">
        </i>
      </p>

      <eo-snoozed-label
        *ngIf="isSnoozed"
        [snoozeEnd]="snoozeEnd">
      </eo-snoozed-label>

      <eo-out-of-stock-label
        *ngIf="!inStock">
      </eo-out-of-stock-label>
    </div>

    <div class="price-container" *ngIf="price > 0 && !isSnoozed">
      <p class="font-12">
        + {{ currency }}{{ price | number:'1.0-2' }}
        <span *ngIf="unitId === units.GRAM">/{{ 'GR' | translate }}</span>
        <span *ngIf="unitId === units.KG">/{{ 'KG' | translate }}</span>
      </p>
    </div>
  </div>
</div>
