import { Component, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BasketItemsService } from '../../services/basket-items.service';
import { FirmService } from '../../services/firm.service';

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.page.html',
  styleUrls: ['./order-modal.page.scss']
})
export class OrderModalPage {

  @Input() category_id: any;
  @Input() init_product: any;
  @Input() amount: any;

  firm: any;
  item: any;
  basketItem: any;
  ingredients: any = [];
  options_selected: any = [];
  totalPrice: number;
  message: string;
  ingredients_enabled: boolean;
  options_enabled: boolean;

  constructor(public modalCtrl: ModalController, 
              public platform: Platform,
              private firmService: FirmService, 
              private basketItemsService: BasketItemsService) {}

  async ionViewWillEnter() {
    if(this.init_product) {
      this.firm = await this.firmService.getLocalFirm();
      this.item = JSON.parse(this.init_product);
      this.basketItem = await this.basketItemsService.setInitBasketItem(Number(this.amount), this.item, this.firm, this.init_product, this.totalPrice, this.ingredients, this.message, this.options_selected);    
    }
  }

  /* Add item(s) to the basket */
  async addToBasket() {
    setTimeout(async () => {
      this.basketItem.init_product = this.init_product;
      this.basketItemsService.addToBasket(this.basketItem, this.ingredients, Number(this.amount), this.totalPrice, this.options_selected, this.firm, this.item.has_promotional_price);
      this.modalCtrl.dismiss(true);
    }, 100);
  }
  
  /* Outputs */
  itemChanged(event) {
    this.basketItem = event.basketItem;
    this.ingredients = event.ingredients;
    this.options_selected = event.options;
    this.totalPrice = event.totalPrice;
  }

}