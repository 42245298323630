import { EventEmitter, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';

@Injectable({
  providedIn: 'root',
})
export class DeliveryMethodService {

  deliveryMethodUpdate = new Subject();
  deliveryMethodObservable = this.deliveryMethodUpdate.asObservable();

  selectDeliveryMethodUpdate: EventEmitter<string>;
  currentDeliveryMethod: string;

  constructor(
    private storage: Storage,
    private firmStore: FirmStore,
  ) {
    this.selectDeliveryMethodUpdate = new EventEmitter<string>();
  }

  async updateSelectedDeliveryMethod(method: string): Promise<string> {
    this.selectDeliveryMethodUpdate.emit(method);
    this.currentDeliveryMethod = method;
    return this.storage.set('selectedDeliveryMethod', method);
  }

  async getSelectedDeliveryMethod(): Promise<string> {
    if (this.currentDeliveryMethod) {
      return this.currentDeliveryMethod;
    }

    const storageValue = await this.storage.get('selectedDeliveryMethod');
    this.currentDeliveryMethod = storageValue;

    return storageValue;
  }

  isMethodEnabledForMultiFirm(method: string): boolean {
    let enabled = false;

    this.firmStore.firms.forEach((firm: any) => {
      if (firm.delivery_methods.indexOf(method) !== -1) {
        enabled = true;
      }
    });

    return enabled;
  }

  isPickupMethodActiveForFirm(firm): boolean {
    let pickupMainBranch = false;

    if (firm.pickup_points && firm.pickup_points.length > 0) {
      firm.pickup_points.forEach(pickupPoint => {
        if (pickupPoint.main_branch) {
          pickupMainBranch = true;
        }
      });
    }

    return this.isPickupMethodActive(firm.pickup, firm.pickup_points, pickupMainBranch);
  }

  isPickupMethodActive(pickupActive, pickupPoints, pickupMainBranch) {
    return (
      pickupActive &&
      (
        (pickupPoints && pickupPoints.length === 0) ||
        (pickupPoints && pickupPoints.length > 0 && pickupMainBranch)
      )
    );
  }

  isDeliveryMethodActive(firm) {
    return firm.delivery;
  }

  isPickupPointMethodActive(pickupActive, pickupPoints) {
    return pickupActive && pickupPoints && pickupPoints.length > 0;
  }

  isTableMethodActive(firm) {
    return firm.table;
  }

  isPickupMethodTemporarilyInactive(firm) {
    return firm.pickup_temporary_inactive;
  }

  isDeliveryMethodTemporarilyInactive(firm) {
    return firm.delivery_temporary_inactive;
  }

  isTableMethodTemporarilyInactive(firm) {
    return firm.table_temporary_inactive;
  }

}
