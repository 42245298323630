import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'eo-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
})
export class CouponComponent implements OnInit {

  @ViewChild('productSelect') productSelector;

  @Input() subTotal: number;
  @Input() cartTotal: number;
  @Input() currency: string;

  @Output() added = new EventEmitter<any>();
  @Output() removed = new EventEmitter<any>();

  coupon: any;
  couponCode: any = undefined;
  enterCoupon = true;
  errorMsg: any;
  interfaceOptions: any;
  couponRemovedByCartChange = false;

  constructor(
    private orderService: OrderService,
    private productsService: ProductsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.errorMsg = false;
    this.interfaceOptions = { header: this.translate.instant('CHOOSE_FREE_PRODUCT') };
  }

  async checkCoupon() {
    if(this.couponCode) {
      this.orderService.checkCoupon({ coupon_code: this.couponCode, total: this.subTotal }).then((coupon) => {
        this.resetCouponError();
        if(!coupon.valid) {
          this.enterCoupon = true;
          this.errorMsg = coupon.error;
        } else {
          this.enterCoupon = false;
          this.addCoupon(coupon);
        }
      });
    }
  }

  resetCouponError() {
    this.errorMsg = undefined;
    this.couponRemovedByCartChange = false;
  }

  addCoupon(coupon) {
    this.coupon = {
      code: this.couponCode,
      type: coupon.type
    };

    if(coupon.max_usage) this.coupon.usageLeft = coupon.usage_left;

    if(coupon.type === 'amount' || coupon.type === 'percentage') {
      this.coupon.value = coupon.value;
    }
    else if(coupon.type === 'product') {
      this.coupon.freeProduct = coupon.product;
    }
    else if(coupon.type === 'category') {
      this.productsService.getProductsByCategoryId(coupon.category.id).then((products) => {
        this.coupon.freeProducts = products;
        this.coupon.selectedCouponProduct = products[0];
      });
    }

    this.added.emit(this.coupon);
  }

  removeCoupon() {
    this.removed.emit(this.coupon);

    this.coupon = undefined;
    this.couponCode = undefined;
    this.enterCoupon = true;
    this.resetCouponError();
  }

  hasSuccesfullCouponResult() {
    return !this.enterCoupon && !this.errorMsg;
  }

  hasUsageLeftOnCoupon() {
    return this.coupon.usageLeft && this.coupon.usageLeft > 0;
  }

}
