<ion-toolbar>
  <ion-grid>
    <ion-row class="ion-justify-content-between ion-align-items-center">

      <ion-col size="2">
        <ion-back-button *ngIf="backBtn" (click)="navigateBack()" class="display-block" text=""></ion-back-button>
        <ion-menu-toggle *ngIf="!backBtn">
          <ion-button class="header-btn">
            <i class="fas fa-bars icon menu-icon fg-font"></i>
            <div class="hamburger-badge firm-colors-reverse" *ngIf="login?.activated && unreadMessages > 0">
              {{ unreadMessages }}
            </div>
          </ion-button>
        </ion-menu-toggle>
      </ion-col>

      <ion-col size="4">
        <img
          *ngIf="firm?.logo_fullsize"
          class="logo"
          [src]="firm?.logo_fullsize"
          alt=""
        />
      </ion-col>

      <ion-col size="4" class="padding" *ngIf="multifirm && firm?.theme.show_location_info_header">
        <div class="font-11 padding firm-selector"><strong>{{ firm?.name }}</strong></div>
      </ion-col>

      <ion-col size="2">
        <ion-button *ngIf="search" class="ion-align-self-center" (click)="templateService.toggleSearchbar()">
          <i class="fas fa-search icon menu-icon fg-font"></i>
        </ion-button>
      </ion-col>

    </ion-row>
  </ion-grid>
</ion-toolbar>

<sub-header *ngIf="!(menuStore.viewMenuActive | async) && !hideSubHeader && !firm?.is_offline" [firm]="firm"></sub-header>

<header-notification
  *ngIf="firm?.is_offline"
  [text]="firm?.offline_settings?.message ? firm?.offline_settings?.message : ('SHOP_OFFLINE' | translate)"
  [backgroundColor]="firm?.offline_settings?.bg_color"
  [textColor]="firm?.offline_settings?.text_color">
</header-notification>

<div *ngIf="!(menuStore.viewMenuActive | async) && !hideBasket" class="basket-icon">
  <ion-fab-button
    data-test="cart-btn"
    (click)="navCtrl.navigateForward('/basket')"
    basketAnimation>
    <div
      *ngIf="basketItemsService?.basketAmount > 0"
      class="amount-circle"
    >
      <strong>{{ basketItemsService.getNumberOfItemsInTheBasket() }}</strong>
    </div>
    <i class="fas fa-shopping-basket"></i>
  </ion-fab-button>
</div>
