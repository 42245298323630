import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Storage } from '@ionic/storage';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class HeadersService {

  constructor(private device: Device,
              private storage: Storage){}

  async getHeaders() {
    let headers = new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': "Bearer " + await this.storage.get('access_token'),
        'deviceOS': this.device.platform != null ? this.device.platform : 'IOS',
        'deviceOSVersion': this.device.version != null ? this.device.version : '2.0.0',
        'deviceModel': this.device.model != null ? this.device.model : 'Iphone 6',
        'appVersion': Config.VERSION,
        'apiVersion': Config.API_VERSION
    });

    return headers;
  }

  async checkToken() {
    let tokens = await this.storage.get('password_tokens');
    let firm_id = await this.storage.get('firm');

    var passwordToken = [];

    if(tokens != null) {
      tokens.forEach(token => {
        if(token.firm_id === firm_id) {
          passwordToken = token.passwordToken;
        }
      });
    }

    if(passwordToken) {
      return String(passwordToken);
    }
    else {
      return null;
    }
  }

  async getDemoToken() {
    var demoToken = await this.storage.get('demo_password_token');

    if(demoToken === null) {
      demoToken = "";
    }

    return demoToken;
  }

  async getLanguage() {
    const language = await this.storage.get('language');

    if (language) {
      return String(language.code).toLowerCase();
    }

    return 'nl';
  }
}
