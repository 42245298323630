import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Address } from 'src/app/core/models/address';
import { CustomerStore } from 'src/app/modules/customer/store/customer.store';
import { CustomerService } from 'src/app/services/customer.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'customer-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {
  @Input() addresses: Address[];
  @Input() address: Address;
  @Input() showAddressName = true;
  @Input() showAddressDefault = true;
  @Input() showSwitcher = false;

  modalButtonEnabled = false;

  constructor(
    private customerService: CustomerService,
    private customerStore: CustomerStore,
    private modalService: ModalService,
    private modalController: ModalController
  ) { }

  public ngOnInit(): void {
    if (this.address) {
      this.modalButtonEnabled = true;
    }

    this.addresses.forEach((address: Address) => {
      if (!this.customerStore.selectedAddress && address.is_default) {
        this.address = address;
        this.modalButtonEnabled = true;
      }

      if (this.customerStore.selectedAddress?.id === address.id) {
        this.address = address;
        this.modalButtonEnabled = true;
      }
    });
  }

  public selectAddress(address): void {
    this.address = address;
    this.address.is_default = true;
  }

  public async presentEditAddressModal(address): Promise<void> {
    const modal = await this.modalService.openAddressModal(
      [],
      true,
      true,
      false,
      address
    );

    modal.onWillDismiss().then(async ({ data }: any) => {
      if (data.cancelled) {
        return;
      }

      this.customerStore.updateAddress(data);

      return this.customerService.updateCustomerAddress(
        this.customerStore.customer.id,
        this.customerStore.customer.uuid,
        data
      );
    });
  }

  public async presentAddDeliveryAddressModal(): Promise<void> {
    const modal = await this.modalService.openAddressModal(
      [],
      true,
      true,
      false,
    );

    modal.onWillDismiss().then(async ({ data }: any) => {
      if (data.cancelled) {
        return;
      }

      const address = await this.customerService.addCustomerAddress(
        this.customerStore.customer.id,
        this.customerStore.customer.uuid,
        data
      );

      this.customerStore.addAddress(address);
    });
  }

  public isAddressSelected(address): boolean {
    return this.address?.id === address.id;
  }

  public async handleOnSubmit(): Promise<void> {
    await this.modalController.dismiss(this.address);
  }

  public handleAddressFormOnChange(event): void {
    this.modalButtonEnabled = event.validForm;
    this.address = event.address;
  }
}
