<div class="address-form">
  <form #addressForm="ngForm" novalidate>
    <ion-input
      *ngIf="showAddressName"
      [(ngModel)]="formAddress.description"
      [placeholder]="'ADDRESS_DESCRIPTION' | translate"
      (ionChange)="handleOnChange()"
      name="description"
      type="text"
      required>
    </ion-input>

    <ion-input
      [(ngModel)]="formAddress.street"
      [placeholder]="'STREET_NAME' | translate"
      (ionChange)="handleOnChange()"
      name="street"
      type="text"
      required>
    </ion-input>

    <div class="grid numbers-grid">
      <ion-input
        [(ngModel)]="formAddress.house_number"
        [placeholder]="'HOUSE_NUMBER_SHORT' | translate"
        (ionChange)="handleOnChange()"
        name="house_number"
        type="text"
        required>
      </ion-input>

      <ion-input
        [(ngModel)]="formAddress.bus"
        [placeholder]="'OPTIONAL_UNIT' | translate"
        (ionChange)="handleOnChange()"
        name="bus"
        type="text">
      </ion-input>
    </div>

    <div class="grid locality-grid">
      <ion-input
        [(ngModel)]="formAddress.zip_code"
        [placeholder]="'ZIPCODE' | translate"
        (ionChange)="handleOnChange()"
        name="zip_code"
        type="text"
        required>
      </ion-input>

      <ion-input
        [(ngModel)]="formAddress.locality"
        [placeholder]="'LOCALITY' | translate"
        (ionChange)="handleOnChange()"
        name="locality"
        type="text"
        required>
      </ion-input>
    </div>

    <ion-select
      [(ngModel)]="formAddress.country"
      [placeholder]="'COUNTRY' | translate"
      [selectedText]="formAddress?.country?.name"
      [interfaceOptions]="{header: ('COUNTRY' | translate)}"
      (ionChange)="handleOnChange()"
      name="country"
      required>
      <ion-select-option *ngFor="let country of countries" [value]="country">{{ country?.name }}</ion-select-option>
    </ion-select>

    <div *ngIf="showAddressDefault" class="default-toggle">
      <ion-toggle [(ngModel)]="formAddress.is_default" name="is_default" (ionChange)="setDefault($event.detail.checked)"></ion-toggle>
      <p>{{ 'SET_DEFAULT_ADDRESS' | translate }}</p>
    </div>
  </form>
</div>
