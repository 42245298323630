import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavService } from '../../services/nav.service';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'featured-categories',
  templateUrl: './featured-categories.component.html',
  styleUrls: ['./featured-categories.component.scss'],
})
export class FeaturedCategoriesComponent {

    @Input() firm;
    @Input() category_list;
    @Input() featured_categories;

    slideOpts = {
        initialSlide: 0,
        centeredSlides: true,
        centeredSlidesBounds: true,
        width: "130"
    };
  
    constructor(private navCtrl: NavController, 
                private navService: NavService,
                public productsService: ProductsService) {}
    
    async goToCategoryPage(cat: any) {
        this.navService.categories = this.category_list;
        this.navService.category = await this.category_list.find(original_cat => original_cat.category_id === cat.category_id);
        this.navCtrl.navigateForward('/category');
    }

}