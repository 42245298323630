import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemingService {

  constructor() { }

  setTheme(theme) {
    this.setColors(theme);
    this.setCustomFonts(theme);
  }

  setColors(theme) {
    document.body.style.setProperty('--main', this.convertHexToRGB(theme.main_color));
    document.body.style.setProperty('--contrast', this.convertHexToRGB(theme.main_color_contrast_color));

    document.body.style.setProperty('--main-gradient', this.convertHexToRGB(theme.main_gradient_color_from));
    document.body.style.setProperty('--main-gradient-from', this.convertHexToRGB(theme.main_gradient_color_from));
    document.body.style.setProperty('--main-gradient-to', this.convertHexToRGB(theme.main_gradient_color_to));
    document.body.style.setProperty('--main-gradient-contrast', this.convertHexToRGB(theme.main_gradient_contrast_color));

    document.body.style.setProperty('--custom', this.convertHexToRGB(theme.theme_color));
    document.body.style.setProperty('--custom-contrast', this.convertHexToRGB(theme.theme_color_contrast_color));

    document.body.style.setProperty('--container', this.convertHexToRGB(theme.container_color));
    document.body.style.setProperty('--container-contrast', this.convertHexToRGB(theme.container_contrast_color));

    document.body.style.setProperty('--tab', this.convertHexToRGB(theme.tab_color));
    document.body.style.setProperty('--tab-contrast', this.convertHexToRGB(theme.tab_contrast_color));

    document.body.style.setProperty('--success-gradient-from', this.convertHexToRGB(theme.success_gradient_color_from));
    document.body.style.setProperty('--success-gradient-to', this.convertHexToRGB(theme.success_gradient_color_to));
    document.body.style.setProperty('--success-gradient-contrast', this.convertHexToRGB(theme.success_gradient_contrast_color));

    document.body.style.setProperty('--wells', this.convertHexToRGB(theme.well_color));
    document.body.style.setProperty('--wells-contrast', this.convertHexToRGB(theme.well_contrast_color));

    document.body.style.setProperty('--btn-border-radius', theme.button_radius);
  }

  convertHexToRGB(hex) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return r + ',' + g + ',' + b;
  }

  async setCustomFonts(theme) {
    if (theme.font_header_title) {
      await this.setFont('Title Font', theme.font_header_title);
    }

    if (theme.font_general) {
      await this.setFont('General Font', theme.font_general);
    }
  }

  async setFont(name, url) {
    // @ts-ignore
    const font = new FontFace(name, `url(${url})`);
    (document as any).fonts.add(font);
    await font.load();
  }
}
