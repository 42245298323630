import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'eo-loyalty-item',
  templateUrl: './loyalty-item.component.html',
  styleUrls: ['./loyalty-item.component.scss'],
})
export class LoyaltyItemComponent {

  @ViewChild('productSelect') productSelector;

  @Output() productChanged = new EventEmitter<any>();

  @Input() enableImage: boolean;
  @Input() currency: string;
  @Input() type: string;
  @Input() discountType: string;
  @Input() discountValue: number;
  @Input() productList: any;

  selectedProduct: any;
  interfaceOptions: any;

  constructor(
    private translate: TranslateService,
    public productsService: ProductsService,
  ) {
    this.interfaceOptions = {
      header: this.translate.instant('CHOOSE_FREE_PRODUCT')
    }
  }

  ngOnChanges() {
    this.setInitialProduct();
  }

  setInitialProduct() {
    if (this.productList) this.setSelectedProduct(this.productList[0]);
  }

  setSelectedProduct(product) {
    this.selectedProduct = product;
    this.productChangedHandler();
  }

  isLoyaltyProductInStock() {
    return this.selectedProduct && this.selectedProduct.in_stock;
  }

  openProductSelect() {
    if(this.productList.length > 1) {
      this.productSelector.open();
    }
  }

  listHasMultipleProducts() {
    return this.productList && this.productList.length > 1;
  }

  productChangedHandler() {
    this.productChanged.emit(this.selectedProduct);
  }

}
