import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressComponent } from 'src/app/shared/address/address.component';
import { EoModalComponent } from 'src/app/shared/eo-modal/eo-modal.component';
import { ErrorModalComponent } from 'src/app/shared/error-modal/error-modal.component';
import { UnavailableMessageComponent } from './unavailable-message/unavailable-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { FirstAvailableDatePipe } from './first-available-date/first-available-date.pipe';
import { EoButtonComponent } from './eo-button/eo-button.component';
import { RoundButtonComponent } from './round-button/round-button.component';
import { IonicModule } from '@ionic/angular';
import { EoSegmentComponent } from './eo-segment/eo-segment.component';
import { FormsModule } from '@angular/forms';
import { EoSliderComponent } from './eo-slider/eo-slider.component';
import { HeaderNotificationComponent } from './header-notification/header-notification.component';

@NgModule({
  declarations: [
    UnavailableMessageComponent,
    FirstAvailableDatePipe,
    EoButtonComponent,
    RoundButtonComponent,
    EoSegmentComponent,
    EoSliderComponent,
    EoModalComponent,
    AddressComponent,
    ErrorModalComponent,
    HeaderNotificationComponent
  ],
  exports: [
    UnavailableMessageComponent,
    FirstAvailableDatePipe,
    EoButtonComponent,
    RoundButtonComponent,
    EoSegmentComponent,
    EoSliderComponent,
    EoModalComponent,
    AddressComponent,
    ErrorModalComponent,
    HeaderNotificationComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
  ]
})
export class SharedModule { }
