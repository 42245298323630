import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Config } from '../../config';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { HeadersService } from './headers.service';

@Injectable({
  providedIn: 'root'
})
export class DemoPasswordService {

  constructor(private http: HttpClient,
              private storage: Storage,
              private auth: AuthService,
              private headers: HeadersService,
              private alertService: AlertService,
              private alert: AlertController,
              private translate: TranslateService) { }

  async enterDemoPassword(password) {
    let headers = await this.headers.getHeaders();
    headers = await headers.append('firmId', String(await this.storage.get('firm')));
    headers = await headers.append('language', await this.headers.getLanguage());
    if(Config.STORE_ID) {
      headers = await headers.append('storeId', Config.STORE_ID);
    }

    return this.http.post(environment.API_URL + '/firm/checkdemopassword', { password: password }, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        await this.storage.set('demo_password_token', data.demoPasswordToken);
        return data;
      })
      .catch(async err => {
        let error = await this.checkErrors(err.status);

        if(error === 'TOKEN_INVALID') {
          this.enterDemoPassword(password);
        }
        else if(error === "403") {
          return "PASSWORD_INCORRECT";
        }
      });
  }

  presentDemoPasswordError(): Promise<any> {
    return new Promise(async (resolve, reject) =>{
      const alert = await this.alert.create({
        backdropDismiss: false,
        header: this.translate.instant('DEMO_LOGIN'),
        subHeader: this.translate.instant('ENTER_DEMO_PASSWORD'),
        inputs: [
          {
            name: 'password',
            placeholder: this.translate.instant('PASSWORD'),
            type: 'password'
          }
        ],
        buttons: [
          {
            text: this.translate.instant('LOGIN'),
            handler: data => {
              this.enterDemoPassword(data.password).then((data: any) => {
                if(data.demoPasswordToken) {
                  resolve('PASSWORD_CORRECT');
                }
              });

            }
          }
        ]
      });

      return await alert.present();
    })
  }

  async checkErrors(status) {
    if(status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    }
    else if(status === 403) {
      this.alertService.presentIncorrectPasswordError();
      return "403";
    }
    else if(status === 422) {
      this.alertService.showPasswordRequiredError();
      return "403";
    }
    else if(status === 0) {
      this.alertService.presentServerNotRespondingError();
    }
    else {
      this.alertService.presentUnknownError();
    }
  }

}
