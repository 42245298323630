import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddToCartService {

  cartAnimation = new Subject();
  cartAnimationObservable = this.cartAnimation.asObservable();
  
  getInitialQuantityForUnits(minQuantity): number {
    return minQuantity ? minQuantity : 1;
  }

  getInitialQuantityForInterval(minQuantity, interval): number {
    return minQuantity ? minQuantity : interval;
  }

  hasReachedGroupMin(group) {
    let selectedIngredients = 0;
    if(group && group.min_selection) {
      group.ingredients.forEach(ingr => {
        if(ingr.selected && ingr.type !== 'main') {
          selectedIngredients += Number(ingr.amount);
        }
      });
      return selectedIngredients >= group.min_selection;
    }
    else {
      return true;
    }
  }

  haveAllGroupsReachedGroupMin(groups) {
    let result = true;
    if(groups) {
      groups.forEach((group) => {
        const hasReached = this.hasReachedGroupMin(group);
        if(!hasReached) result = false;
      });
    }
    return result;
  }

}
