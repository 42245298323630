import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  enableSearchbar: boolean;

  constructor() {}

  toggleSearchbar() {
    this.enableSearchbar = !this.enableSearchbar;
  }

  getSearchbarClass() {
    if(this.enableSearchbar) {
      return 'search-active';
    }
    else if(!this.enableSearchbar) {
      return 'search-inactive';
    }
    else {
      return 'search-init';
    }
  }
  
}
