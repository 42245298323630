<ion-content>
  <eo-modal
    [submitText]="'CONTINUE' | translate"
    (submitted)="handleOnSubmit()"
  >
    <div *ngIf="loading" content class="full-width ion-text-center ion-padding">
      <ion-spinner name="crescent"></ion-spinner>
    </div>
    <div *ngIf="!loading" content class="full-width">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col size="auto"><i class="far fa-clock"></i></ion-col>
          <ion-col size="auto">
            <strong *ngIf="!isLimitedToSameDayOrdering()" class="font-15">{{ 'CHOOSE_DATE_TIME' | translate }}</strong>
            <strong *ngIf="isLimitedToSameDayOrdering()" class="font-15">{{ 'CHOOSE_TIME' | translate }}</strong>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid class="font-12">
        <ion-row>
          <ion-col>
            <timeslots-picker
              *ngIf="hasOrderTimeSlotsEnabled()"
              [showHeader]="false"
              [firm]="firm"
            ></timeslots-picker>
            <custom-days-picker *ngIf="hasCustomDaysEnabled()" [firm]="firm"></custom-days-picker>
            <delivery-date-time-picker
              *ngIf="!hasCustomDaysEnabled() && !hasOrderTimeSlotsEnabled()"
              [isLimitedToSameDayOrdering]="isLimitedToSameDayOrdering()"
              [showHeader]="false"
              [firm]="firm"
            ></delivery-date-time-picker>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </eo-modal>
</ion-content>
