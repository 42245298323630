import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Units } from '../../constants/units';

@Component({
  selector: 'eo-modifier',
  templateUrl: './modifier.component.html',
  styleUrls: ['./modifier.component.scss'],
})
export class ModifierComponent implements OnInit {
  @Input() type: string;
  @Input() description: string;
  @Input() id: number;
  @Input() label: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() inStock: boolean;
  @Input() isSnoozed: boolean;
  @Input() snoozeEnd: string;
  @Input() price: number;
  @Input() currency: string;
  @Input() unitId: number;
  @Input() enableImage: boolean;
  @Input() image: string;
  @Input() multiMax: number;
  @Input() reachedGroupMax: boolean;

  @Output() selected = new EventEmitter<any>();
  @Output() quantitySubtract = new EventEmitter<any>();
  @Output() quantityAdd = new EventEmitter<any>();

  units = Units;
  quantity = 0;

  constructor() {}

  ngOnInit() {
    this.setInitialQuantity();
  }

  setInitialQuantity() {
    if (this.checked) {
      this.quantity = 1;
    }
  }

  isCheckbox() {
    return this.type === 'checkbox';
  }

  isRadio() {
    return this.type === 'radio';
  }

  displayQuantity() {
    return this.isCheckbox() && (!this.multiMax || (this.multiMax > 1));
  }

  subtract() {
    this.quantity -= 1;
    this.quantitySubtract.emit();

    if (this.quantity === 0) {
      this.handleSelect();
    }
  }

  add() {
    if (this.quantity === 0) {
      this.handleSelect();
    }

    this.quantity += 1;

    if (this.quantity > 1) {
      this.quantityAdd.emit();
    }
  }

  hasReachedMultiMax() {
    return this.multiMax ? (this.quantity >= this.multiMax) : false;
  }

  handleSelect() {
    this.selected.emit();
  }
}
