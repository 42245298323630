<ng-container [ngTemplateOutlet]="getTemplateRef(template) || default"></ng-container>

<ng-template #default>
  <div class="super-prod-container container-shadow">
    <div class="prod-container">
      <div *ngIf="firm?.product_images_enabled">
        <div class="prod-image-container"
          [ngStyle]="{'background-image': 'url(' + productsService.getImage(item.item.images) + ')'}">
        </div>
      </div>
      <div class="full-width">

        <div class="prod-top-container full-width">
          <div class="full-width">
            <div class="prod-name-ingr">
              <p class="item-name font-12 ion-text-wrap ion-no-margin">{{ item.name }}</p>

              <!-- Ingredients -->
              <div *ngFor="let i of optionsAndIngredients">
                <div class="ingredient">
                  <p *ngIf="i.type === 'ingredient' && getIngredient(i.id)?.type === ingredientType.FREE && getIngredient(i.id)?.action === ingredientAction.REMOVE" class="basket-optional font-12">
                    - {{getIngredient(i.id)?.name }}
                  </p>
                </div>

                <div class="ingredient">
                  <p *ngIf="i.type === 'ingredient' && getIngredient(i.id)?.type === ingredientType.FREE && getIngredient(i.id)?.action === ingredientAction.ADD" class="basket-optional font-12">+
                    {{getIngredient(i.id)?.name }}
                    <span *ngIf="getIngredient(i.id)?.amount > 1">(x{{ getIngredient(i.id)?.amount }})</span>
                  </p>
                </div>

                <div class="ingredient">
                  <p *ngIf="i.type === 'ingredient' && getIngredient(i.id)?.type === ingredientType.PAID" class="basket-optional font-12">
                    + {{getIngredient(i.id)?.name }} {{ firm?.currency.symbol }}{{getIngredient(i.id)?.price | number:'1.2-2' }}
                    <span *ngIf="getIngredient(i.id)?.amount > 1">(x{{ getIngredient(i.id)?.amount }})</span>
                    <span *ngIf="item?.unit_id === units.GRAM">/{{ 'GR' | translate }}</span>
                    <span *ngIf="item?.unit_id === units.KG">/{{ 'KG' | translate }}</span>
                  </p>
                </div>

                <!-- Product options -->
                <div class="ingredient">
                  <p *ngIf="i.type === 'option'" class="basket-optional font-12">+ {{ getOption(i.id)?.selected_option?.name }}
                    <span *ngIf="getOption(i.id)?.selected_option?.price > 0">
                      {{ firm?.currency.symbol }}{{ getOption(i.id)?.selected_option?.price | number:'1.2-2' }}
                      <span *ngIf="item?.unit_id === units.GRAM">/{{ 'GR' | translate }}</span>
                      <span *ngIf="item?.unit_id === units.KG">/{{ 'KG' | translate }}</span>
                    </span>
                  </p>
                </div>
              </div>

            </div>
            <div class="trash-container" tappable (click)="removeItem()">
              <i class="far fa-trash-alt icon"></i>
            </div>
          </div>
        </div>

        <div class="prod-bottom-container">

          <div class="amount-container">
            <eo-quantity-selector>
              <eo-unit-selector
                *ngIf="item?.unit_id === units.UNIT || item?.unit_id === units.LITER || item?.unit_id === units.PERSON"
                [quantity]="item.amount"
                [minQuantity]="item.item.min"
                [maxQuantity]="item.item.max"
                [maxCartQuantity]="firm?.max_cart_amount"
                [totalCartQuantity]="basketAmount"
                [totalItemQuantity]="amountAlreadyInCart"
                (quantityChanged)="quantityChanged($event)">
              </eo-unit-selector>
              <eo-weight-selector
                *ngIf="item?.unit_id === units.GRAM || item?.unit_id === units.KG"
                [quantity]="item.amount"
                [minQuantity]="item.item.min"
                [maxQuantity]="item.item.max"
                [interval]="item.item.unit_interval"
                [unitId]="item.unit_id"
                (quantityChanged)="quantityChanged($event)">
              </eo-weight-selector>
            </eo-quantity-selector>
          </div>

          <!-- Add note -->
          <div class="note-icon-container">
            <ion-button
              *ngIf="enableNotes==false"
              data-test="remark-btn"
              (click)="enableNotes=true; enableEditNote=true">
              <i class="fas fa-pencil-alt"></i>
            </ion-button>
          </div>

          <!-- Price -->
          <div class="price-container font-12">
            <p>{{ 'BASKET.TOTAL' | translate }}: {{ firm?.currency.symbol }}{{ price | number:'1.2-2' }}
            </p>
          </div>

        </div>

      </div>
    </div>

    <!-- Add comment -->
    <div class="note-box" *ngIf="enableNotes">
      <div *ngIf="enableEditNote">
        <p class="note-title font-12">{{ 'ADD_NOTES' | translate }} <i class="fas fa-pencil-alt"></i></p>
        <div class="note-input">
          <ion-input class="v-align" [(ngModel)]="note" name="remark"></ion-input>
          <ion-button class="custom-btn" (click)="addComment()">{{ 'ADD' | translate }}</ion-button>
        </div>
        <hr>
      </div>
      <div *ngIf="!enableEditNote">
        <div class="note-display">
          <ion-input class="v-align" [(ngModel)]="item.comment" name="remark-result" disabled="true"></ion-input>
          <ion-button class="note-btn" (click)="enableEditNote=true">
            <i class="fas fa-pencil-alt"></i>
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #shoppingCartStyle2>

  <div class="style2 super-prod-container">

    <div class="prod-container">

      <div class="prod-image-container"
        *ngIf="firm?.product_images_enabled"
        [ngStyle]="{'background-image': 'url(' + productsService.getImage(item.item.images) + ')'}">
      </div>

      <div class="content-container">

        <p class="item-name font-12 ion-text-wrap ion-no-margin">{{ item.name }}</p>

        <div *ngFor="let i of optionsAndIngredients">
          <div class="ingredient">
            <p *ngIf="i.type === 'ingredient' && getIngredient(i.id)?.type === ingredientType.FREE && getIngredient(i.id)?.action === ingredientAction.REMOVE" class="basket-optional font-12">
              - {{getIngredient(i.id)?.name }}
            </p>
          </div>

          <div class="ingredient">
            <p *ngIf="i.type === 'ingredient' && getIngredient(i.id)?.type === ingredientType.FREE && getIngredient(i.id)?.action === ingredientAction.ADD" class="basket-optional font-12">+
              {{getIngredient(i.id)?.name }}
              <span *ngIf="getIngredient(i.id)?.amount > 1">(x{{ getIngredient(i.id)?.amount }})</span>
            </p>
          </div>

          <div class="ingredient">
            <p *ngIf="i.type === 'ingredient' && getIngredient(i.id)?.type === ingredientType.PAID" class="basket-optional font-12">
              + {{getIngredient(i.id)?.name }} {{ firm?.currency.symbol }}{{getIngredient(i.id)?.price | number:'1.2-2' }}
              <span *ngIf="getIngredient(i.id)?.amount > 1">(x{{ getIngredient(i.id)?.amount }})</span>
              <span *ngIf="item?.unit_id === units.GRAM">/{{ 'GR' | translate }}</span>
              <span *ngIf="item?.unit_id === units.KG">/{{ 'KG' | translate }}</span>
            </p>
          </div>

          <div class="ingredient">
            <p *ngIf="i.type === 'option'" class="basket-optional font-12">+ {{ getOption(i.id)?.selected_option?.name }}
              <span *ngIf="getOption(i.id)?.selected_option?.price > 0">
                {{ firm?.currency.symbol }}{{ getOption(i.id)?.selected_option?.price | number:'1.2-2' }}
                <span *ngIf="item?.unit_id === units.GRAM">/{{ 'GR' | translate }}</span>
                <span *ngIf="item?.unit_id === units.KG">/{{ 'KG' | translate }}</span>
              </span>
            </p>
          </div>
        </div>

        <div
          *ngIf="!enableNotes"
          class="notes-container"
          data-test="remark-btn"
          (click)="displayNotes()"
          tappable>
            <p>{{ 'ADD_COMMENT' | translate }}</p>
            <i class="fas fa-caret-down icon"></i>
        </div>

      </div>

      <div class="total-container">

        <div class="center-container">

          <div class="amount-container">
            <eo-quantity-selector>
              <eo-unit-selector
                *ngIf="item?.unit_id === units.UNIT || item?.unit_id === units.LITER || item?.unit_id === units.PERSON"
                [quantity]="item.amount"
                [minQuantity]="item.item.min"
                [maxQuantity]="item.item.max"
                [maxCartQuantity]="firm?.max_cart_amount"
                [totalCartQuantity]="basketAmount"
                [totalItemQuantity]="amountAlreadyInCart"
                [enableDelete]="true"
                (quantityChanged)="quantityChanged($event)"
                (deleted)="removeItem()">
              </eo-unit-selector>
            </eo-quantity-selector>
          </div>

          <p class="font-14">{{ firm?.currency.symbol }}{{ price | number:'1.2-2' }}</p>
        </div>

      </div>

    </div>

    <!-- Add comment -->
    <div class="note-box" *ngIf="enableNotes">
      <div *ngIf="enableEditNote">
        <p class="note-title font-12">{{ 'ADD_NOTES' | translate }} <i class="fas fa-pencil-alt"></i></p>
        <div class="note-input">
          <ion-input class="v-align" [(ngModel)]="note" name="remark"></ion-input>
          <ion-button class="custom-btn" (click)="addComment()">{{ 'ADD' | translate }}</ion-button>
        </div>
        <hr>
      </div>
      <div *ngIf="!enableEditNote" class="note-display">
        <ion-input class="v-align" [(ngModel)]="item.comment" name="remark-result" disabled="true"></ion-input>
        <ion-button class="note-btn" (click)="enableEditNote=true">
          <i class="fas fa-pencil-alt"></i>
        </ion-button>
      </div>
    </div>

  </div>

</ng-template>
