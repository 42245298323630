<div>
  <div>{{ 'CHOOSE_DATE_TIME' | translate }}</div>
  <div class="flex">
    <ion-select [(ngModel)]="customDateModel" value="notifications" interface="action-sheet" (ionChange)="handleOnChange()">
      <ion-select-option
        *ngFor="let customDate of customDates"
        [value]="customDate.value"
      >
        {{ customDate.text }}
      </ion-select-option>
    </ion-select>
  </div>
</div>
