import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eo-out-of-stock-label',
  templateUrl: './out-of-stock-label.component.html',
  styleUrls: ['./out-of-stock-label.component.scss'],
})
export class OutOfStockLabelComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
