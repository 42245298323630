<ion-content>
  <eo-modal
    [submitText]="'CONTINUE' | translate"
    (submitted)="save()"
  >
    <method-delivery-switcher *ngIf="showSwitcher" topLeft></method-delivery-switcher>
    <div content class="full-width">
      <div class="title-box">
        <ion-icon src="../../assets/images/qrcode_thumb.svg"></ion-icon>
        <div>
          <p>{{ 'ORDER_ON_THE_SPOT' | translate }}</p>
          <p>{{ 'CHOOSE_TABLE_NUMBER' | translate:{table_number: label?.toLowerCase()} }}</p>
        </div>
      </div>

      <div scrollX="true">
        <ion-radio-group [(ngModel)]="selectedTableNumber">
          <div class="box" *ngFor="let number of tableNumbers">
            <ion-row tappable (click)="selectTableNumber(number)">

              <ion-col size="10">
                <p class="title-txt">{{ number }}</p>
              </ion-col>

              <ion-col size="2" class="radio-col">
                <ion-radio mode="md" [value]="number"></ion-radio>
              </ion-col>

            </ion-row>
          </div>
        </ion-radio-group>
      </div>
    </div>
  </eo-modal>
</ion-content>
