<div class="box" *ngFor="let language of languages">
  <ion-row tappable (click)="chooseLanguage(language)">
    <ion-col size="10">
      <p class="title-txt v-align">{{ language.name }}</p>
    </ion-col>
    <ion-col size="2">
      <div class="radio-btn-inactive v-align" *ngIf="selectedLanguage?.code != language.code"></div>
      <div class="radio-btn-active v-align" *ngIf="selectedLanguage?.code === language.code">
        <div class="inner v-align"></div>
      </div>
    </ion-col>
  </ion-row>
</div>
