import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Availability } from '../../core/availability';
import { DateService } from '../../services/date.service';

@Pipe({
  name: 'firstAvailableDate'
})
export class FirstAvailableDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(availabilities: Availability[], deliveryTime: string): Availability {
    let date;

    if (deliveryTime === 'asap') {
      date = moment();
    } else {
      date = moment(deliveryTime);
    }

    let dayId = date.isoWeekday();
    let counter = 1;

    if (availabilities?.length > 0) {
      while (
        availabilities.find(
          (av) =>
            av.day_id === dayId &&
            (av.day_id !== date.isoWeekday() ||
              (av.day_id === date.isoWeekday() &&
                date.isBefore(
                  this.dateService.setTimeOnDate(new Date(date), av.from_time)
                )))
        ) === undefined &&
        counter <= 7
        ) {
        dayId = dayId === 7 ? (dayId = 1) : (dayId += 1);
        counter += 1;
      }

      const availableDays = availabilities.filter((av) => av.day_id === dayId);

      if (dayId === date.isoWeekday()) {
        return availableDays.find((day) =>
          date.isBefore(this.dateService.setTimeOnDate(new Date(date), day.from_time))
        );
      }

      return availableDays[0];
    }
  }
}
