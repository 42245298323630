<ion-app>
  <ion-menu
    *ngIf="loaded"
    contentId="main-content"
    side="start"
    menuId="first"
    type="overlay"
    [ngClass]="firm?.template_navigation"
    (ionWillOpen)="menuWillOpen()">
    <div class="scroll-container">
      <app-hamburger #sidemenu></app-hamburger>
    </div>
  </ion-menu>

  <ion-router-outlet *ngIf="loaded" id="main-content"></ion-router-outlet>
</ion-app>
