import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';
import { FirmService } from 'src/app/services/firm.service';

@Component({
  selector: 'firms-list-modal',
  templateUrl: './firms-list-modal.component.html',
  styleUrls: ['./firms-list-modal.component.scss'],
})
export class FirmsListModalComponent {
  @Input() deliveryMethod: string;

  public firms = [...this.firmStore.firms];
  public selectedFirm: any = null;

  constructor(
    private modalCtrl: ModalController,
    private firmStore: FirmStore,
    private firmService: FirmService,
    private storage: Storage,
  ) { }

  public async handleOnSubmit(): Promise<void> {
    await this.storage.set('firm', this.selectedFirm);
    const firm = await this.firmService.setFirm(this.selectedFirm);

    this.firmStore.setCurrentFirm(firm);

    await this.modalCtrl.dismiss({ firm });
  }

  public selectFirm(firmId: number): void {
    this.selectedFirm = firmId;
  }

  public firmHasDeliveryMethod(firm: any): boolean {
    return firm.delivery_methods.indexOf(this.deliveryMethod) !== -1;
  }
}
