<div *ngIf="loading">
  {{ 'LOADING' | translate }}
</div>

<div *ngIf="!loading">
  <div *ngIf="!isLimitedToSameDayOrdering">
    <div *ngIf="showHeader" class="header-text">{{ 'CHOOSE_DATE_TIME' | translate }}</div>
    <div *ngIf="showHeader" class="spacer"></div>
    <div>
      <div>{{ 'DATE' | translate }}:</div>
      <div class="date-box" tappable (click)="openDateDayPicker()">{{ dateDay }}</div>
    </div>
    <div class="ion-padding-top">
      <div>{{ 'TIME' | translate }}:</div>
      <div class="date-box" tappable (click)="openDateTimePicker()">{{ dateTime }}</div>
    </div>
  </div>

  <div *ngIf="isLimitedToSameDayOrdering">
    <div *ngIf="showHeader" class="header-text">{{ 'CHOOSE_TIME' | translate }}</div>
    <div *ngIf="showHeader" class="spacer"></div>
    <div>
      <div>{{ 'TIME' | translate }}:</div>
      <div class="date-box" tappable (click)="openDateTimePicker()">{{ dateTime }}</div>
    </div>
  </div>

  <ion-datetime #dateDayPicker
                [(ngModel)]="dateDayModel"
                style="display: none"
                pickerFormat="D MMM YYYY"
                [min]="firstAvailableTimeResponse?.timestamp"
                [max]="maxDate"
                monthNames="{{ 'MONTHS.JANUARY' | translate }},{{ 'MONTHS.FEBRUARY' | translate }},{{ 'MONTHS.MARCH' | translate }},{{ 'MONTHS.APRIL' | translate }},{{ 'MONTHS.MAY' | translate }},{{ 'MONTHS.JUNE' | translate }},{{ 'MONTHS.JULY' | translate }},{{ 'MONTHS.AUGUST' | translate }},{{ 'MONTHS.SEPTEMBER' | translate }},{{ 'MONTHS.OCTOBER' | translate }},{{ 'MONTHS.NOVEMBER' | translate }},{{ 'MONTHS.DECEMBER' | translate }}"
                monthShortNames="{{ 'MONTHS_SHORT.JAN' | translate }},{{ 'MONTHS_SHORT.FEB' | translate }},{{ 'MONTHS_SHORT.MAR' | translate }},{{ 'MONTHS_SHORT.APR' | translate }},{{ 'MONTHS_SHORT.MAY' | translate }},{{ 'MONTHS_SHORT.JUN' | translate }},{{ 'MONTHS_SHORT.JUL' | translate }},{{ 'MONTHS_SHORT.AUG' | translate }},{{ 'MONTHS_SHORT.SEPT' | translate }},{{ 'MONTHS_SHORT.OCT' | translate }},{{ 'MONTHS_SHORT.NOV' | translate }},{{ 'MONTHS_SHORT.DEC' | translate }}"
                name="date"
                (ionChange)="handleOnDateDayChange()"
                ngDefaultControl>
  </ion-datetime>

  <ion-datetime #dateTimePicker
                [(ngModel)]="dateTimeModel"
                style="display: none"
                pickerFormat="HH:mm"
                hourValues="1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,00"
                name="time"
                [min]="minTime"
                [max]="maxTime"
                (ionChange)="handleOnDateTimeChange()"
                ngDefaultControl>
  </ion-datetime>
</div>
