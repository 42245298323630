import { NgModule } from '@angular/core';
import { MinimumPricePipe } from './minimum-price/minimum-price';
import { CustomPickupDatePipe } from './custom-pickup-date/custom-pickup-date';
import { PickerDatePipe } from './picker-date/picker-date.pipe';
import { NegativePricePipe } from './negative-price/negative-price.pipe';

@NgModule({
	declarations: [
		MinimumPricePipe,
		CustomPickupDatePipe,
		PickerDatePipe,
		NegativePricePipe
	],
	imports: [],
	exports: [
		MinimumPricePipe,
		CustomPickupDatePipe,
		PickerDatePipe,
		NegativePricePipe
	]
})
export class PipesModule {}
