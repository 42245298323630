import { Component, ViewChild } from '@angular/core';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { CustomerStore } from 'src/app/modules/customer/store/customer.store';
import { PickupPointStore } from 'src/app/modules/delivery-method/store/pickup-point.store';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';
import { LanguageStore } from 'src/app/modules/language/store/language.store';
import { Language } from 'src/app/modules/language/types/language';
import { FirmsService } from 'src/app/services/firms.service';
import { environment } from 'src/environments/environment';
import { Config } from '../config';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { BrowserService } from './services/browser.service';
import { CustomerService } from './services/customer.service';
import { FirmService } from './services/firm.service';
import { LanguageService } from './services/language.service';
import { NavService } from './services/nav.service';
import { OrderService } from './services/order.service';
import { ProductsService } from './services/products.service';
import { UpdateService } from './services/update.service';
import { ThemingService } from './services/theming.service';
import { AnnouncementService } from './services/announcement.service';
import { Router } from '@angular/router';
import { PaymentService } from './services/payment.service';
import { PushService } from './services/push.service';
import { HamburgerComponent } from './components/hamburger/hamburger.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private customerService: CustomerService,
    private customerStore: CustomerStore,
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private network: Network,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private storage: Storage,
    private firmService: FirmService,
    private firmsService: FirmsService,
    private firmStore: FirmStore,
    private keyboard: Keyboard,
    private auth: AuthService,
    private languageService: LanguageService,
    private languageStore: LanguageStore,
    private updateService: UpdateService,
    private productsService: ProductsService,
    private orderService: OrderService,
    private browserService: BrowserService,
    private pushService: PushService,
    private ga: GoogleAnalytics,
    private deeplinks: Deeplinks,
    private alertService: AlertService,
    private navCtrl: NavController,
    public navService: NavService,
    private themingService: ThemingService,
    private announcementService: AnnouncementService,
    private paymentService: PaymentService,
    private pickupPointStore: PickupPointStore,
  ) {
    this.initializeApp();
    this.initStores();

    // watch network for a disconnect
    this.network.onDisconnect().subscribe(async () => {
      this.disconnectToast = await this.toastCtrl.create({
        message: this.translate.instant('DIALOGS.DISCONNECT'),
        position: 'top',
        cssClass: "toast",
        buttons: [
          {
            text: this.translate.instant('BUTTONS.OK'),
            role: 'cancel',
            handler: () => {}
          }
        ]
      });

      return this.disconnectToast.present();
    });

    // watch network for a connection
    this.network.onConnect().subscribe(() => {
      if (this.disconnectToast) {
        this.disconnectToast.dismiss();
      }
    });

    this.firmService.firmUpdateObservable.subscribe((firm: any) => {
      this.firm = firm;
    });

    this.translate = translate;
  }

  @ViewChild('sidemenu') set sidemenu(content: HamburgerComponent) {
    if (content) { // initially setter gets called with undefined
      this.sideMenuComponent = content;
    }
  }

  token: any;
  disconnectToast: any;
  updateRequired: boolean;
  distributor: any;

  single_app: boolean = Config.SINGLE_APP;
  firm: any;
  loaded = false;

  sideMenuComponent: any;

  initStores() {
    this.pickupPointStore
      .getSelectedPickupPoint()
      .then((pickupPoint: any) => {
        this.pickupPointStore.pickupPoint = pickupPoint;
      });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.splashScreen.hide();
      this.initGoogleAnalytics();

      // subscribe to deeplinks (for payment callbacks)
      this.deeplinks.route({
        '/payment': 'PaymentPage'
      })
      .subscribe(
        async (match: any) => {
          if(match.$route === 'PaymentPage') {
            this.paymentService.paymentResumed.next(match.$args);
            this.browserService.close();
          }
        },(nomatch) => {
          // do nothing
        }
      );

      // Set keyboard
      this.keyboard.hideFormAccessoryBar(false);

      // Auth token
      this.token = await this.auth.getToken();

      // Set default language
      await this.translate.setDefaultLang(this.languageService.default_lang);

      // Check app version with server
      const updateRequired = await this.updateService.checkAppVersion();

      if (!updateRequired) {
        this.updateRequired = false;

        this.splashScreen.hide();

        // Check payment status (only when app crashed on payment)
        await this.checkCustomer();
        await this.checkPaymentStatus();
        this.languageService.getDeviceLanguage();
        await this.checkAnnouncements();

        if (
          environment.production === true
          && (
            this.platform.is('android')
            || (this.platform.is('ios') && await this.pushService.getDevicePermission())
          )
        ) {
          this.pushService.initPushNotifications();
        }
      }
      else {
        this.updateRequired = true;
        this.splashScreen.hide();
      }

      this.loaded = true;
    });
  }

  async checkCustomer() {
    const login = await this.customerService.getLoginStorage();

    if (!login) {
      this.customerStore.setCustomer(null);
      this.customerStore.setAddresses([]);
      this.customerStore.setSelectedAddress(await this.storage.get('selectedAddress'));

      return;
    }

    const customer = await this.customerService.get(login.id);
    const addresses = await this.customerService.getAllCustomerAddresses(login.id, customer.uuid);

    this.customerStore.setCustomer(customer);
    this.customerStore.setAddresses(addresses);
  }

  async checkAnnouncements() {
    const announcementResult = await this.firmService.getAnnouncements();
    const unseenAnnouncements = await this.announcementService.getUnseenAnnouncements(announcementResult.data);

    if (announcementResult && unseenAnnouncements.length > 0) {
      this.navService.unseenAnnouncements = unseenAnnouncements;
      this.navService.announcementsLogo = announcementResult.firm.logo_fullsize;
      this.themingService.setTheme(announcementResult.firm.theme);
      return this.navCtrl.navigateRoot('/announcements');
    }

    return this.checkSingleApp();
  }

  initGoogleAnalytics() {
    this.ga.startTrackerWithId('UA-142065824-3')
      .then(() => {
        this.ga.setAppVersion(Config.VERSION);
        this.ga.trackView('HACK TO LOAD THE TAG');
      })
      .catch(e => console.log('Error starting GoogleAnalytics', e));
  }

  async fetchFirm() {
    const firm = await this.firmService.getFirmData(false, true);
    await this.firmService.setStandardFirm(
      firm,
      await this.productsService.getProductData()
    );

    this.firmStore.setCurrentFirm(firm);

    return firm;
  }

  async checkSingleApp() {
    if (!Config.SINGLE_APP) {
      return this.checkMultiApp();
    }

    await this.storage.set('firm', Config.FIRM_ID);
    let firm = await this.fetchFirm();

    const mustChooseLanguage = await this.checkLanguages(firm.languages);

    if (this.languageStore.selectedLanguage) {
      firm = await this.fetchFirm();
    }

    if (mustChooseLanguage) {
      return this.navCtrl.navigateForward('/login-language');
    }

    if (firm.theme.show_delivery_method_first) {
      return this.navCtrl.navigateForward('/method-selection');
    }

    return this.navCtrl.navigateForward('/menu');
  }

  async checkMultiApp() {
    const firms: any = await this.firmsService.getFirms();
    const languages: Language[] = [];

    firms.firms.forEach((firm: any) => {
      firm.languages.forEach((language: Language) => {
        const index = languages.findIndex((l: Language) => l.code === language.code);

        if (index === -1) {
          languages.push(language);
        }
      });
    });

    this.firmStore.setFirms(firms.firms);
    this.firmStore.setDistributor(firms.distributor);
    this.firmStore.setDemoFirms(firms.demo_firms);

    const mustChooseLanguage = await this.checkLanguages(languages);

    if (mustChooseLanguage) {
      return this.navCtrl.navigateForward('/login-language');
    }

    if (firms.distributor.theme.show_delivery_method_first) {
      return this.navCtrl.navigateForward('/method-selection');
    }

    return this.navCtrl.navigateForward('/container-menu');
  }

  async checkLanguages(languages: Language[]): Promise<boolean> {
    this.languageStore.setLanguages(languages);

    const selectedLanguage = await this.storage.get('language');

    if (languages.length !== 1 && !selectedLanguage) {
      return true;
    }

    if (selectedLanguage) {
      this.languageStore.selectLanguage(selectedLanguage);
      this.languageService.setLanguage(selectedLanguage.code);
    } else {
      this.languageStore.selectLanguage(languages[0]);
      this.languageService.setLanguage(languages[0].code);
      await this.storage.set('language', languages[0]);
    }

    return false;
  }

  async checkPaymentStatus() {
    let order_info = await this.storage.get('latest_order_info');

    if(order_info) {
      var payment_status = await this.getPaymentStatus(order_info);

      if(payment_status.paid) {
        this.alertService.presentPaymentSucceeded(payment_status.reference);
      }
    }
  }

  async getPaymentStatus(order_info) {
    if(order_info.order_id) {
      let response: any = await this.orderService.getStatus(order_info.order_id, order_info.customer_id);

      // clean local storage
      this.storage.set('latest_order_info', undefined);
      this.storage.set('order', undefined);

      if(response === 'ERROR') {
        this.alertService.presentPaymentStatusError();
      }
      else {
        return response;
      }
    }
  }

  menuWillOpen() {
    this.sideMenuComponent.getPushPermission();
  }
}
