import { DELIVERY_METHODS } from 'src/app/constants/delivery-methods';
import { AbstractDeliveryTimeService } from './abstract-delivery-time.service';

export class HomeDeliveryTimeService extends AbstractDeliveryTimeService {
  public hasOrderTimeslots(): boolean {
    return this.firm.delivery_methods.delivery.order_timeslots.active;
  }

  public hasCustomDays(): boolean {
    return this.firm.delivery_methods.delivery.has_custom_days;
  }

  public getCustomDays(): any[] {
    return this.firm.delivery_methods.delivery.custom_days;
  }

  public selectDayFromPeriodsByDate(date: string): any {
    return this.dateService.getSelectedDay(date, this.firm.periods.delivery_hours);
  }

  public isLimitedToSameDayOrdering(): boolean {
    return this.firm.delivery_methods.delivery &&
      this.firm.delivery_methods.delivery.orders_only_during_opening_hours;
  }

  public getDeliveryMethod(): string {
    return DELIVERY_METHODS.DELIVERY;
  }
}
