<div class="scroll">
  <div class="scroll-item" 
      [class.active]="isActive(cat.name)"
      id="nav-{{ cat.name }}" 
      *ngFor="let cat of categories" 
      (click)="selectCategory(cat)">
    <div class="content">
      <img *ngIf="cat.images && iconsEnabled" class="img v-align" [src]="productsService.getImage(cat.images)" />
      <div class="header">
        <p class="v-align">{{ cat.name }}</p>
      </div>
    </div>
  </div>
</div>