import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'negativePrice'
})
export class NegativePricePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value < 0) {
      value = value.replace('-','');
    }
    return value;
  }

}