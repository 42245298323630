import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DeliveryTimeServiceInterface } from 'src/app/modules/delivery-time/services/delivery-time-service.interface';
import { DeliveryTimeServiceResolver } from 'src/app/modules/delivery-time/services/delivery-time-service.resolver';

@Component({
  selector: 'custom-days-picker',
  templateUrl: './custom-days-picker.component.html',
  styleUrls: ['./custom-days-picket.component.scss'],
})
export class CustomDaysPickerComponent implements OnInit {
  @Input() firm: any;

  public customDateModel = '';
  public customDates = [];

  private deliveryTimeService: DeliveryTimeServiceInterface;

  constructor(
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
  ) { }

  public ngOnInit(): void {
    this.deliveryTimeServiceResolver.resolveDeliveryTimeService(this.firm)
      .then((service: DeliveryTimeServiceInterface) => {
        this.deliveryTimeService = service;
        this.customDateModel = service.getDeliveryTime();
        this.customDates = service.getCustomDays().map((customDay: any) => ({
          value: customDay.date,
          text: moment(customDay.date).format('DD/MM/YYYY - HH:mm'),
        }));
      });
  }

  public handleOnChange(): void {
    this.deliveryTimeService?.updateDeliveryTime(this.customDateModel);
  }
}
