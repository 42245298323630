<ion-content>

  <div class="time-modal">
    <p class="title-txt title-font">{{ 'CHOOSE_TIME' | translate }}</p>

    <div class="box">
      <ion-row>
        <ion-col size="10" class="col-height">
          <p class="v-align">{{ 'ASAP' | translate }}</p>
        </ion-col>
        <ion-col size="2" tappable (click)="chooseTime('asap')" class="col-height">
          <div class="radio-btn-inactive v-align" *ngIf="chosen_option != 'asap'"></div>
          <div class="radio-btn-active v-align" *ngIf="chosen_option === 'asap'">
            <div class="inner v-align"></div>
          </div>
        </ion-col>
      </ion-row>
    </div>

    <div class="box">
      <ion-row>
        <ion-col size="10">
          <p>{{ 'CHOOSE_HOUR' | translate }}
          </p>
        </ion-col>
        <ion-col size="2" tappable (click)="chooseTime('custom')">
          <div class="radio-btn-inactive" *ngIf="chosen_option != 'custom'"></div>
          <div class="radio-btn-active" *ngIf="chosen_option === 'custom'">
            <div class="inner v-align"></div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-datetime #time id="time"
              [(ngModel)]="timestamp"
              displayFormat="HH : mm"
              pickerFormat="HH:mm"
              name="time"
              [min]="minTime"
              [max]="maxTime"
              class="timepicker ion-text-center"
              hourValues="1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,00"
              ngDefaultControl>
          </ion-datetime>
        </ion-col>
      </ion-row>
    </div>

    <ion-button class="custom-btn" (click)="saveTime()">{{ 'CONTINUE' | translate }}</ion-button>
  </div>

</ion-content>
