import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Address } from 'src/app/core/models/address';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';
import { Config } from 'src/config';

@Component({
  selector: 'address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @Input() address: Address;
  @Input() showAddressName = true;
  @Input() showAddressDefault = true;

  @Output() onChange = new EventEmitter();

  @ViewChild('addressForm') addressForm;

  formAddress: any;
  countries: any;

  constructor(
    private firmStore: FirmStore,
  ) { }

  ngOnInit() {
    this.setFormAddress();
    this.setCountries();
    this.checkCountriesLength();
  }

  setFormAddress() {
    this.formAddress = {
      ...this.address,
      is_default: this.address?.is_default || false
    };
  }

  setCountries() {
    if (Config.IS_MULTIFIRM) {
      this.countries = this.compileCountriesFromFirms();
      return;
    }

    this.countries = this.firmStore.currentFirm.countries;
  }

  checkCountriesLength() {
    if (this.countries.length === 1) {
      this.formAddress.country = this.countries[0];
    }
  }

  compileCountriesFromFirms() {
    const countries = [];

    this.firmStore.firms.forEach((firm: any) => {
      if (countries.findIndex((country: any) => country.id === firm.country.id) === -1) {
        countries.push(firm.country);
      }
    });

    return countries;
  }

  setDefault(checked) {
    this.formAddress.is_default = checked;
  }

  handleOnChange(): void {
    this.onChange.emit({
      validForm: this.addressForm.valid,
      address: this.formAddress,
    });
  }
}
