import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private menu: MenuController,
  ) { }

  close() {
    if(this.menu.isOpen()) {
      this.menu.close();
    }
  }
}
