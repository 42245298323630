import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customPickupDate',
})
export class CustomPickupDatePipe implements PipeTransform {
  transform(value: string, language) {
    return moment(value).format('LLL');
  }
}
