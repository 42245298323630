import * as moment from 'moment';
import { BasketItemsService } from 'src/app/services/basket-items.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DateService } from 'src/app/services/date.service';
import { DeliveryTimeServiceInterface } from './delivery-time-service.interface';
import { FirmService } from 'src/app/services/firm.service';
import { OrderService } from 'src/app/services/order.service';
import { ProductsService } from 'src/app/services/products.service';
import { DeliveryTimeStore } from './../store/delivery-time.store';

export abstract class AbstractDeliveryTimeService implements DeliveryTimeServiceInterface {
  constructor(
    protected deliveryTimeStore: DeliveryTimeStore,
    protected dateService: DateService,
    protected basketItemsService: BasketItemsService,
    protected productService: ProductsService,
    protected orderService: OrderService,
    protected customerService: CustomerService,
    protected firmService: FirmService,
    protected firm: any,
  ) { }

  abstract isLimitedToSameDayOrdering(): boolean;
  abstract getDeliveryMethod(): string;
  abstract selectDayFromPeriodsByDate(date: string): any;
  abstract hasCustomDays(): boolean;
  abstract getCustomDays(): any[];
  abstract hasOrderTimeslots(): boolean;

  isShopClosed(deliveryTime: string): boolean {
    return this.dateService.isClosed(
      this.selectDayFromPeriodsByDate(deliveryTime)
    );
  }

  getDeliveryTime(): string {
    return this.deliveryTimeStore.getDeliveryTime();
  }

  formatDeliveryTime(time: string): string {
    return `${this.dateService.getDateDay(time)} - ${this.dateService.formatDateTime(time)}`;
  }

  updateDeliveryTime(deliveryTime: string): void {
    this.deliveryTimeStore.setDeliveryTime(deliveryTime);
  }

  async calculateTimestamp(): Promise<any> {
    const tempOrder = await this.getOrder();
    const login = await this.getLogin();

    return this.orderService.calculateTime(tempOrder, login.id);
  }

  async isOrderingTimeValid(time: string): Promise<any> {
    const tempOrder = await this.getOrder();
    const login = await this.getLogin();

    tempOrder.timestamp = time;

    const { valid } = await this.orderService.confirmTime(tempOrder, login.id) as any;

    return valid;
  }

  async getFirmTimeslots(time: string): Promise<any> {
    const order = await this.getOrder();

    const { data } = await this.firmService.getFirmTimeslots(
      moment(time).format('YYYY-MM-DD'),
      order.delivery_method,
      order.products,
      order.categories,
      order.firm_pickup_point_id
    );

    return data;
  }

  async getTimeIntervals(timeslotId: number, time: string): Promise<any> {
    const order = await this.getOrder();

    const { intervals } = await this.firmService.getTimeIntervals(
      timeslotId,
      moment(time).format('YYYY-MM-DD'),
      order.delivery_method,
      order.products,
      order.categories,
      order.firm_pickup_point_id
    );

    this.deliveryTimeStore.selectTimeslot(timeslotId);

    return intervals;
  }

  protected async getOrder(): Promise<Record<string, any>> {
    const tempOrder: Record<string, any> = {};

    tempOrder.timestamp = moment().format();
    tempOrder.delivery_method = this.getDeliveryMethod();

    tempOrder.categories = [];
    tempOrder.products = [];

    const basketItems = await this.basketItemsService.get();

    if (basketItems.length === 0) {
      const products = await this.productService.getProductFromStorage();
      this.parseCategories(basketItems, products);
    }

    basketItems.forEach(basketObj => {
      tempOrder.categories.push(basketObj.item.cat_id);
      tempOrder.products.push(basketObj.item.id);
    });

    return tempOrder;
  }

  protected parseCategories(basketItems: any[], categories: any[]): void {
    for (const category of categories) {
      if (category.subcategories.length > 0) {
        this.parseCategories(basketItems, category.subcategories);
      }

      if (basketItems.length > 0) {
        return;
      }

      if (category.products.length > 0) {
        basketItems.push({
          item: {
            cat_id: category.category_id,
            id: category.products[0].id,
          }
        });

        return;
      }
    }
  }

  protected async getLogin(): Promise<any> {
    const login = await this.customerService.getLoginStorage();

    if (!login) {
      return this.customerService.loginAsGuest();
    }

    return login;
  }
}
