import { Component, Input } from '@angular/core';

@Component({
  selector: 'eo-unavailable-message',
  templateUrl: './unavailable-message.component.html',
  styleUrls: ['./unavailable-message.component.scss'],
})
export class UnavailableMessageComponent {
  @Input() categoryName: string;
  @Input() dayName: string;
  @Input() toTime: string;
}
