<div class="selected-method" 
    [ngClass]="template"
    (click)="navigateToDeliveryMethodSelection()"
    tappable>
    <div class="left">
        <ion-icon src="../../assets/images/{{icon}}.svg"></ion-icon>
        <p>{{ selectedDeliveryMethodName }}</p>
    </div>
    <div class="right">
        <i class="fas fa-exchange-alt"></i>
    </div>
</div>