import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'eo-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  @Input() message = '';

  constructor(
    private modalController: ModalController,
  ) { }

  public async handleOnSubmit(): Promise<void> {
    await this.modalController.dismiss();
  }
}
