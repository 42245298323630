import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Units } from '../../constants/units';

@Component({
  selector: 'eo-weight-selector',
  template: `
    <div class="interval" tappable (click)="toggleUnit()">
      <ng-container *ngIf="unitId === units.GRAM">
        <p>{{ quantity }}</p>
        <span>{{ 'GR' | translate }}</span>
      </ng-container>

      <ng-container *ngIf="unitId === units.KG">
        <p>{{ quantity/1000 }}</p>
        <span>{{ 'KG' | translate }}</span>
      </ng-container>

      <i *ngIf="displayChevron" class="fas fa-chevron-down font-10"></i>
    </div>

    <button (click)="subtract()" [disabled]="disableSubtractBtn()">
      <i class="fas fa-minus"></i>
    </button>
    <button (click)="add()" [disabled]="disableAddBtn()">
      <i class="fas fa-plus"></i>
    </button>
  `,
  styleUrls: ['./quantity-selector.component.scss'],
  styles: [
  `
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .interval {
      display: flex;
      align-items: center;
      color: var(--label-color);
      font-size: 12px;
    }
    p {
      margin: 0;
    }
    span {
      margin: 0 4px 0 2px;
    }
    button {
      margin-left: 5px;
    }
  `
  ]
})
export class WeightSelectorComponent implements OnInit {

  @Input() quantity: number;
  @Input() minQuantity: number;
  @Input() maxQuantity: number;
  @Input() interval: number;
  @Input() unitId: number;
  @Input() displayChevron = true;

  @Output() quantityChanged = new EventEmitter<number>();

  units = Units;
  currentUnit: number;

  constructor() { }

  ngOnInit() {
    this.currentUnit = this.unitId;
  }

  subtract() {
    this.quantity -= this.interval;
    this.changeHandler();
  }

  add() {
    this.quantity += this.interval;
    this.changeHandler();
  }

  toggleUnit() {
    this.currentUnit = this.currentUnit === Units.GRAM ? this.currentUnit = Units.KG : Units.GRAM;
  }

  disableSubtractBtn() {
    return  this.quantity <= this.interval ||
            this.quantity <= this.minQuantity;
  }

  disableAddBtn() {
    return this.maxQuantity ? this.quantity >= this.maxQuantity : false;
  }

  changeHandler() {
    this.quantityChanged.emit(this.quantity);
  }

}
