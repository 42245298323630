import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { Units } from '../../constants/units';
import { FirmService } from '../../services/firm.service';
import { ProductsService } from '../../services/products.service';
import { AddToCartService } from '../../services/add-to-cart.service';

@Component({
  selector: 'order',
  templateUrl: 'order.html',
  styleUrls: ['order.scss'],
  animations: [
    trigger('openSelector', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('400ms', style({ opacity: 1 })),
      ])
    ])
  ]
})
export class OrderComponent {

  @Input() basketItem: any;
  @Output() itemChangedOutput;
  @Output() addOutput;
  @Output() cancelOutput;
  @Output() disabledOutput = new EventEmitter<any>();

  moment = moment;
  units = Units;

  init_product: any;
  options_selected: any;
  ingr_label: any;
  ingredients: any;
  totalPrice: number;
  comment: string;
  firm: any;
  options_price: any = 0;

  reachedMinLimit: boolean;

  /* Sequenced groups */
  groups_by_sequence = [];
  activeIndex: number = 0;
  activeIngredientGroup: any;
  activeOptionGroup: any;
  ingredients_enabled: boolean;
  options_enabled: boolean;
  selected_option: any;

  comment_enabled: boolean;

  constructor(private firmService: FirmService,
              public platform: Platform,
              public productsService: ProductsService,
              private storage: Storage,
              private addToCartService: AddToCartService) {
    this.itemChangedOutput = new EventEmitter<any>();
    this.addOutput = new EventEmitter<any>();
    this.cancelOutput = new EventEmitter<any>();
    this.init();
  }

  async init() {
    this.firm = await this.firmService.getLocalFirm();

    if(this.firm.template_modifiers === 'style2') {
      this.comment_enabled = this.firm.show_comments_step_product_basket;
    }

    // Set init product
    this.init_product = this.basketItem.init_item;
    this.ingredients = [];
    this.options_selected = [];
    this.options_price = 0;

    // Get price of product
    if(this.basketItem.item.has_promotional_price) {
      this.totalPrice = this.basketItem.item.promotion.price;
    }
    else {
      this.totalPrice = this.basketItem.item.price;
    }

    // set ingredients list
    if(this.basketItem.item.ingredient_groups) {
      this.basketItem.item.ingredient_groups.forEach(ingr_group => {
        ingr_group.ingredients.forEach(ingr => {
          ingr.amount = 1;
          if(ingr.type === 'main') {
            ingr.action = "add";
            ingr.selected = 1;
            this.addToIngredients(ingr);
          }
        });
      });
    }

    // set option groups
    if(this.basketItem.item.option_groups) {
      await this.basketItem.item.option_groups.forEach(option_group => {
        let selected_options = option_group.options.filter(option => option.selected && !this.productsService.isSnoozed(option) && this.isInStock(option));
        selected_options.forEach(option => {
          if (option.selected && !this.productsService.isSnoozed(option) && this.isInStock(option)) {
            this.addOption(option, option_group.id);
          }
        });
      });

      // Add options price to product
      if (this.basketItem.item.has_promotional_price) {
        this.basketItem.item.promotion.price = this.basketItem.item.promotion.price + this.options_price;
      }
      else {
        this.basketItem.item.price = this.basketItem.item.price + this.options_price;
      }

      this.setTotalPrice();
    }

    if(this.firm.template_modifiers === 'style1') {
      this.getGroupsBySequence();
    }
    else if(this.firm.template_modifiers === 'style2') {
      this.getGroupsListBySequence();
    }

    this.emit();
  }

  async getGroupsListBySequence() {
    if(this.basketItem.item.ingredient_groups) {
      await this.basketItem.item.ingredient_groups.forEach(async ingr_gr => {
        ingr_gr.type = 'ingredient_group';
        this.groups_by_sequence.push(ingr_gr);
      });
    }

    if(this.basketItem.item.option_groups) {
      await this.basketItem.item.option_groups.forEach(opt_gr => {
        opt_gr.type = 'option_group';
        this.groups_by_sequence.push(opt_gr);
      });
    }

    this.groups_by_sequence = this.groups_by_sequence.sort((a, b) => a.sequence - b.sequence);
    this.emit();
  }

  async getGroupsBySequence() {
    if(this.basketItem.item.ingredient_groups) {
      await this.basketItem.item.ingredient_groups.forEach(async ingr_gr => {
        let hasValidIngredients = await ingr_gr.ingredients.find(ingr => ingr.type === 'free' || ingr.type === 'paid');
        if(hasValidIngredients && ingr_gr) {
          let group = {
            id: ingr_gr.id,
            sequence: ingr_gr.sequence,
            type: 'ingredient_group',
            min_selection: ingr_gr.min_selection,
            max_selection: ingr_gr.max_selection,
          }
          this.groups_by_sequence.push(group);
        }
      });
    }

    if(this.basketItem.item.option_groups) {
      await this.basketItem.item.option_groups.forEach(opt_gr => {
        if(opt_gr) {
          let group = {
            id: opt_gr.id,
            sequence: opt_gr.sequence,
            type: 'option_group'
          }
          this.groups_by_sequence.push(group);
        }
      });
    }

    this.groups_by_sequence = this.groups_by_sequence.sort((a, b) => a.sequence - b.sequence);
    this.enableNextGroup(this.activeIndex);
  }

  async enableNextGroup(index) {
    let current_group = this.groups_by_sequence[index];
    this.activeIndex = index;

    if(current_group) {
      if(current_group.type === 'ingredient_group') {
        this.activeIngredientGroup = await this.basketItem.item.ingredient_groups.find((ingr_gr) => ingr_gr.id === current_group.id);

        if(this.activeIngredientGroup) {
          this.ingredients_enabled = true;
          this.options_enabled = false;
        }
      }
      if(current_group.type === 'option_group') {
        this.activeOptionGroup = await this.basketItem.item.option_groups.find((ingr_gr) => ingr_gr.id === current_group.id);

        if(this.activeOptionGroup) {
          this.ingredients_enabled = false;
          this.options_enabled = true;
          this.setSelectedRadio(this.activeOptionGroup);
        }
      }
    }
    else if(!current_group && (this.firm.template_modifiers === 'style1' && this.firm.show_comments_step_product_basket)) {
      this.ingredients_enabled = false;
      this.options_enabled = false;
      this.comment_enabled = true;
    }
    else {
      this.addOutput.emit();
    }
  }

  /* disable ingredients when the max has been reached */
  disableIngredientCheckbox(ingredient, group) {
    return this.hasReachedGroupMax(group) && !ingredient.selected;
  }

  /* Check if amount has reached group max */
  hasReachedGroupMax(group) {
    var selectedIngredients = 0;
    if(group && group.max_selection !== null) {
      group.ingredients.forEach(ingr => {
        if(ingr.selected && ingr.type !== 'main') {
          selectedIngredients += Number(ingr.amount);
        }
      });
      return selectedIngredients >= group.max_selection;
    }
    else {
      return false;
    }
  }

  disableOptionNextButton() {
    return !this.options_selected.some(opt => opt.selected_option.id === this.selected_option);
  }

  hasReachedGroupMin(group) {
    return this.addToCartService.hasReachedGroupMin(group);
  }

  setSelectedRadio(option_group) {
    option_group.options.forEach((option) => {
      if(option.selected && !this.productsService.isSnoozed(option)) {
        this.selected_option = option.id;
      }
    });
  }

  /* Check/uncheck an ingredient */
  async checkIngredient(selectedIngredient, group) {
    if(!this.disableIngredientCheckbox(selectedIngredient, group) && !this.productsService.isSnoozed(selectedIngredient) && this.isInStock(selectedIngredient)) {
      let originalIngredient: any = await this.getOriginalIngredient(selectedIngredient);

      if(!originalIngredient.selected && !selectedIngredient.selected) {
        originalIngredient.action = "add";
        originalIngredient.amount = selectedIngredient.amount;
        this.addToIngredients(originalIngredient);
        selectedIngredient.type === 'paid' ? this.addIngredientPrice(selectedIngredient) : null;
      }
      else if(originalIngredient.selected && selectedIngredient.selected) {
        originalIngredient.action = "rem";
        this.addToIngredients(originalIngredient);
        selectedIngredient.type === 'paid' ? this.addIngredientPrice(selectedIngredient) : null;
        selectedIngredient.amount = 1;
      }
      else {
        this.removeFromIngredients(selectedIngredient.id);
        selectedIngredient.type === 'paid' ? this.subtractIngredientPrice(selectedIngredient, selectedIngredient.amount) : null;
        selectedIngredient.amount = 1;
      }

      selectedIngredient.selected = !selectedIngredient.selected;

      this.setTotalPrice();
      this.emit();
    }
  }

  /* Get ingredient from initial product */
  getOriginalIngredient(ingredient): Promise<any> {
    return new Promise(async (resolve) => {
      let init_product = JSON.parse(this.init_product);
      init_product.ingredient_groups.forEach(ingr_group => {
        ingr_group.ingredients.forEach(ingr => {
          if(ingredient.id === ingr.id) {
            resolve(ingr);
          }
        });
      });
    });
  }

  /* Add to ingredients list */
  addToIngredients(ingredient) {
    this.ingredients.push(ingredient);
    this.emit();
  }

  /* Remove from ingredients list */
  removeFromIngredients(ingr_id) {
    this.ingredients.forEach(async ingr => {
      if(ingr.id === ingr_id) {
        let index = await this.getIndex(this.ingredients, ingr);
        if (index > -1) {
          this.ingredients.splice(index, 1);
        }
      }
    });

    this.emit();
  }

  /* Update the ingredient amount */
  updateIngredientAmount(ingredient) {
    this.ingredients.forEach(async ingr => {
      if(ingr.id === ingredient.id) {
        ingr.amount = Number(ingredient.amount);
      }
    });
    this.emit();
  }

  /* Add price of ingredient to item */
  addIngredientPrice(selectedIngredient) {
    if(this.basketItem.item.has_promotional_price) {
      this.basketItem.item.promotion.price = this.basketItem.item.promotion.price + selectedIngredient.price;
    }
    else {
      this.basketItem.item.price = this.basketItem.item.price + selectedIngredient.price;
    }
  }

  /* Subtract price of ingredient from item (amount can be defined) */
  subtractIngredientPrice(selectedIngredient, amount) {
    if(this.basketItem.item.has_promotional_price) {
      this.basketItem.item.promotion.price = this.basketItem.item.promotion.price - (selectedIngredient.price * amount);
    }
    else {
      this.basketItem.item.price = this.basketItem.item.price - (selectedIngredient.price * amount);
    }
  }

  async handleQuantitySubtract(ingredient) {
    this.subtractIngredientAmount(ingredient);

    const originalIngredient = await this.getOriginalIngredient(ingredient);
    if (originalIngredient.selected && ingredient.amount === 1) {
      this.removeFromIngredients(originalIngredient.id);
    }

    this.subtractIngredientPrice(ingredient, 1);
    this.setTotalPrice();
    this.emit();
  }

  async handleQuantityAdd(ingredient) {
    this.addIngredientAmount(ingredient);

    const originalIngredient = await this.getOriginalIngredient(ingredient);
    if (originalIngredient.selected && ingredient.amount === 2) {
      originalIngredient.action = 'add';
      originalIngredient.amount = ingredient.amount;
      this.addToIngredients(originalIngredient);
    }

    this.addIngredientPrice(ingredient);
    this.setTotalPrice();
    this.emit();
  }

  /* Check an option */
  async checkOptionGroup(option, group) {
    if(!this.productsService.isSnoozed(option) && this.isInStock(option)) {
      option.selected = true;

      if(this.options_selected && this.options_selected.length > 0) {
        let opt_gr = await this.options_selected.find(option_group => option_group.id === group.id);
        if(opt_gr) {
          // Set all other options to false
          this.basketItem.item.option_groups.forEach(og => {
            if (og.id === opt_gr.id) {
              og.options.forEach(opt => {
                if (opt.id != option.id) {
                  opt.selected = false;
                }
              });
            }
          });

          // Remove price of previous selected
          if (this.basketItem.item.has_promotional_price) {
            this.basketItem.item.promotion.price = this.basketItem.item.promotion.price - opt_gr.selected_option.price;
          }
          else {
            this.basketItem.item.price = this.basketItem.item.price - opt_gr.selected_option.price;
          }

          opt_gr.selected_option = option;
        }
        else {
          this.addOption(option, group.id);
        }
      }
      else {
        this.addOption(option, group.id);
      }

      // Add price of new selected option
      if (this.basketItem.item.has_promotional_price) {
        this.basketItem.item.promotion.price = this.basketItem.item.promotion.price + option.price;
      }
      else {
        this.basketItem.item.price = this.basketItem.item.price + option.price;
      }

      this.setSelectedRadio(group);
      this.setTotalPrice();
      this.emit();
    }
  }

  addOption(option, group_id) {
    this.options_price = this.options_price + option.price;
    this.options_selected.push({
      id: group_id,
      selected_option: option
    });
  }

  /* Set total price of the item */
  setTotalPrice() {
    if(this.basketItem.item.has_promotional_price) {
      this.totalPrice = this.basketItem.item.promotion.price * Number(this.basketItem.amount);
    }
    else {
      this.totalPrice = this.basketItem.item.price * Number(this.basketItem.amount);
    }
  }

  getIndex(searchArray, searchQuery) {
    return searchArray.indexOf(searchQuery);
  }

  subtractIngredientAmount(ingredient) {
    ingredient.amount = Number(ingredient.amount) - 1;
    this.updateIngredientAmount(ingredient);
  }

  addIngredientAmount(ingredient) {
    ingredient.amount = Number(ingredient.amount) + 1;
    this.updateIngredientAmount(ingredient);
  }

  isInStock(item) {
    return item.in_stock;
  }

  emit() {
    if(this.firm.template_modifiers === 'style2') {
      const ingrDisabled = !this.addToCartService.haveAllGroupsReachedGroupMin(this.groups_by_sequence);
      const optDisabled = this.basketItem.item.option_groups ? this.basketItem.item.option_groups.length !== this.options_selected.length : false;
      this.disabledOutput.emit(ingrDisabled || optDisabled);
    }
    this.itemChangedOutput.emit({ basketItem: this.basketItem, ingredients: this.ingredients, options: this.options_selected, totalPrice: this.totalPrice });
  }

}
