import { DELIVERY_METHODS } from 'src/app/constants/delivery-methods';
import { PickupPointStore } from 'src/app/modules/delivery-method/store/pickup-point.store';
import { AbstractDeliveryTimeService } from './abstract-delivery-time.service';

export class PickupPointTimeService extends AbstractDeliveryTimeService {
  private store: PickupPointStore;

  setStore(store: PickupPointStore): void {
    this.store = store;
  }

  getCustomDays(): any[] {
    return [];
  }

  getDeliveryMethod(): string {
    return DELIVERY_METHODS.PICKUP_POINT;
  }

  hasCustomDays(): boolean {
    return false;
  }

  hasOrderTimeslots(): boolean {
    return this.store.pickupPoint.use_timeslots;
  }

  isLimitedToSameDayOrdering(): boolean {
    return this.firm.delivery_methods.pickup &&
      this.firm.delivery_methods.pickup.orders_only_during_opening_hours;
  }

  selectDayFromPeriodsByDate(date: string): any {
    if (this.store.pickupPoint.use_pickup_hours) {
      return this.dateService.getSelectedDay(date, this.firm.periods.pickup_hours);
    }

    return this.dateService.getSelectedDay(date, this.store.pickupPoint.periods.pickup_hours);
  }

  protected async getOrder(): Promise<Record<string, any>> {
    const order = await super.getOrder();
    order.delivery_method = DELIVERY_METHODS.PICKUP;
    order.firm_pickup_point_id = this.store.pickupPoint.id;

    return order;
  }
}
