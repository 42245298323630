import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  periods: any;
  sales_terms: any;
  category: any;
  categories: any;
  cat_id: any;
  subcategory: any;
  subcategories: any;
  item: any;
  user: any;
  cust_found: boolean;
  email: any;
  cust_id: any;
  order: any;
  message: any;
  pickup_points: any;
  params: any;
  order_id: any;
  payment_response: any;
  previous: string;
  basketItem: any;
  menuItem: any;
  index: any;
  opt_in: any;
  previousPage: any;
  standard_check: any;
  standard_found: any;
  response: any;
  itemAmount: any;
  basketTotal: any;
  unseenAnnouncements: any;
  announcementsLogo: any;
  unreadMessages: any;
  loginRedirect: any;
  deliverect_categories: any;

  constructor(
    private navController: NavController,
  ) {}

  navigateToMenu(firm, params?) {
    if (firm.template_categories === 'style2') {
      return this.navigateToPage(`/category/${firm.id}`, params);
    }

    return this.navigateToPage(`/menu/${firm.id}`, params);
  }

  navigateToPage(page, params?) {
    if (params === undefined || params === null) {
      return this.navController.navigateRoot(page);
    }

    return this.navController.navigateRoot(page, { queryParams: { index: params } });
  }

}
