<div *ngIf="basketItem">

  <!-- Style 1 -->
  <div *ngIf="firm?.template_modifiers === 'style1'" class="style1">
    <ion-icon name="close" tappable (click)="cancelOutput.emit()" class="cancel-icon"></ion-icon>

    <!-- Ingredients -->
    <div *ngIf="ingredients_enabled">
      <div class="title-box">
        <p>{{ activeIngredientGroup?.name ? activeIngredientGroup?.name : ('INGREDIENTS' | translate) }}
          <i *ngIf="activeIngredientGroup?.description" class="fas fa-question-circle option-description" [tooltip]="activeIngredientGroup?.description" [hideOthers]="true" positionH="right" event="click" topOffset="10"></i>
        </p>
        <p *ngIf="groups_by_sequence[activeIndex]?.min_selection > 0" class="cap-ingredient">{{'MINIMUM' | translate}}: {{groups_by_sequence[activeIndex].min_selection}}</p>
        <p *ngIf="groups_by_sequence[activeIndex]?.max_selection > 0" class="cap-ingredient">{{'MAXIMUM' | translate}}: {{groups_by_sequence[activeIndex].max_selection}}</p>
      </div>

      <div scrollX="true">
        <div class="box"
          *ngFor="let ingredient of activeIngredientGroup?.ingredients"
          [class.disabled]="!isInStock(ingredient) || productsService.isSnoozed(ingredient)">

          <!-- Main -->
          <eo-modifier
            *ngIf="ingredient?.type === 'main'"
            type="checkbox"
            [label]="ingredient.name"
            [disabled]="true"
            [checked]="true"
            [isSnoozed]="productsService.isSnoozed(ingredient)"
            [snoozeEnd]="ingredient.snooze_end"
            [inStock]="isInStock(ingredient)"
            [enableImage]="firm?.theme?.show_modifier_images"
            [image]="ingredient.image">
          </eo-modifier>

          <!-- Free/Paid -->
          <eo-modifier
            *ngIf="ingredient?.type === 'paid' || ingredient?.type === 'free'"
            type="checkbox"
            [label]="ingredient.name"
            [disabled]="disableIngredientCheckbox(ingredient, activeIngredientGroup)"
            [checked]="ingredient.selected && isInStock(ingredient)"
            [isSnoozed]="productsService.isSnoozed(ingredient)"
            [snoozeEnd]="ingredient.snooze_end"
            [inStock]="isInStock(ingredient)"
            [price]="ingredient.price"
            [currency]="firm?.currency.symbol"
            [unitId]="basketItem?.unit_id"
            [enableImage]="firm?.theme?.show_modifier_images"
            [image]="ingredient.image"
            [multiMax]="activeIngredientGroup.multi_max"
            [reachedGroupMax]="hasReachedGroupMax(activeIngredientGroup)"
            (selected)="checkIngredient(ingredient, activeIngredientGroup)"
            (quantitySubtract)="handleQuantitySubtract(ingredient)"
            (quantityAdd)="handleQuantityAdd(ingredient)">
          </eo-modifier>
        </div>
      </div>

      <ion-button class="custom-btn" [disabled]="!hasReachedGroupMin(activeIngredientGroup)" (click)="enableNextGroup(this.activeIndex+1)">{{ 'CONTINUE' | translate }}</ion-button>
    </div>

    <!-- Options -->
    <div *ngIf="options_enabled">

      <div class="title-box">
        <p>{{ activeOptionGroup?.name }}
          <i *ngIf="activeOptionGroup?.description" class="fas fa-question-circle option-description" [tooltip]="activeOptionGroup?.description" [hideOthers]="true" positionH="right" event="click" topOffset="10"></i>
        </p>
      </div>

      <div scrollX="true">
        <eo-option-group
          [group]="activeOptionGroup"
          [currency]="firm?.currency.symbol"
          [unitId]="basketItem?.unit_id"
          [enableImage]="firm?.theme?.show_modifier_images"
          (selected)="checkOptionGroup($event, activeOptionGroup)">
        </eo-option-group>
      </div>

      <ion-button class="custom-btn" (click)="enableNextGroup(this.activeIndex+1)" [disabled]="disableOptionNextButton()">{{ 'CONTINUE' | translate }}</ion-button>
    </div>

  </div>

  <!-- Style 2 -->
  <div *ngIf="firm?.template_modifiers === 'style2'" class="style2">

    <div *ngFor="let group of groups_by_sequence">

      <!-- Ingredients -->
      <div class="group" *ngIf="group.type === 'ingredient_group'">

        <div class="title-box">
          <p>{{ group?.name ? group?.name : ('INGREDIENTS' | translate) }}
            <i *ngIf="group?.description" class="fas fa-question-circle option-description" [tooltip]="group?.description" [hideOthers]="true" positionH="right" event="click" topOffset="10"></i>
          </p>
          <p *ngIf="group?.min_selection > 0" class="cap-ingredient">{{'MINIMUM' | translate}}: {{group.min_selection}}</p>
          <p *ngIf="group?.max_selection > 0" class="cap-ingredient">{{'MAXIMUM' | translate}}: {{group.max_selection}}</p>
        </div>

        <div class="box"
          *ngFor="let ingredient of group?.ingredients"
          [class.disabled]="!isInStock(ingredient)">

          <!-- Main -->
          <eo-modifier
            *ngIf="ingredient?.type === 'main'"
            type="checkbox"
            [label]="ingredient.name"
            [disabled]="true"
            [checked]="true"
            [isSnoozed]="productsService.isSnoozed(ingredient)"
            [snoozeEnd]="ingredient.snooze_end"
            [inStock]="isInStock(ingredient)"
            [enableImage]="firm?.theme?.show_modifier_images"
            [image]="ingredient.image">
          </eo-modifier>

          <!-- Free/Paid -->
          <eo-modifier
            *ngIf="ingredient?.type === 'paid' || ingredient?.type === 'free'"
            type="checkbox"
            [label]="ingredient.name"
            [disabled]="disableIngredientCheckbox(ingredient, group)"
            [checked]="ingredient.selected && isInStock(ingredient)"
            [isSnoozed]="productsService.isSnoozed(ingredient)"
            [snoozeEnd]="ingredient.snooze_end"
            [inStock]="isInStock(ingredient)"
            [price]="ingredient.price"
            [currency]="firm?.currency.symbol"
            [unitId]="basketItem?.unit_id"
            [enableImage]="firm?.theme?.show_modifier_images"
            [image]="ingredient.image"
            [multiMax]="group.multi_max"
            [reachedGroupMax]="hasReachedGroupMax(group)"
            (selected)="checkIngredient(ingredient, group)"
            (quantitySubtract)="handleQuantitySubtract(ingredient)"
            (quantityAdd)="handleQuantityAdd(ingredient)">
          </eo-modifier>
        </div>

      </div>

      <!-- Options -->
      <div class="group" *ngIf="group.type === 'option_group'">

        <div class="title-box">
          <p>{{ group?.name }}
            <i *ngIf="group?.description" class="fas fa-question-circle option-description" [tooltip]="group?.description" [hideOthers]="true" positionH="right" event="click" topOffset="10"></i>
          </p>
        </div>

        <eo-option-group
          [group]="group"
          [currency]="firm?.currency.symbol"
          [unitId]="basketItem?.unit_id"
          [enableImage]="firm?.theme?.show_modifier_images"
          (selected)="checkOptionGroup($event, group)">
        </eo-option-group>

      </div>

    </div>

  </div>

  <!-- Comment -->
  <div *ngIf="comment_enabled" class="comment-container" [ngClass]="firm?.template_modifiers">
    <div class="title-box">
      <p>{{ 'ADD_NOTES' | translate }}</p>
    </div>

    <div class="note-input">
      <ion-input [(ngModel)]="basketItem.comment" placeholder="{{ 'NOTE_PLACEHOLDER' | translate }}" (ngModelChange)="this.emit()"></ion-input>
    </div>

    <ion-button *ngIf="firm?.template_modifiers === 'style1'" class="custom-btn" (click)="this.emit(); this.addOutput.emit();">{{ 'CONTINUE' | translate }}</ion-button>
  </div>

</div>
