<div class="segment-container">
  <ion-segment [(ngModel)]="selectedSegment" (ionChange)="segmentChanged($event.detail.value)">
    <ion-segment-button *ngFor="let segment of segmentButtons"
      [value]="segment.value">
      <ion-label>
        <i [class]="segment.iconName"></i>
        {{ segment.title }}
      </ion-label>
    </ion-segment-button>
  </ion-segment>

  <ng-content></ng-content>
</div>
