import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Segment } from '../../core/models/segment';

@Component({
  selector: 'eo-segment',
  templateUrl: './eo-segment.component.html',
  styleUrls: ['./eo-segment.component.scss'],
})
export class EoSegmentComponent {
  @Input() segmentButtons: Segment[];
  @Output() selected = new EventEmitter<string>();
  selectedSegment = 'personal';

  segmentChanged(segment) {
    this.selectedSegment = segment;
    this.selected.emit(segment);
  }
}
