<ion-radio-group [(ngModel)]="selectedOption">
  <div class="box"
    *ngFor="let option of group?.options"
    [class.disabled]="!isInStock(option) || productsService.isSnoozed(option)">

    <eo-modifier
      type="radio"
      [label]="option.name"
      [id]="option.id"
      [description]="option.description"
      [isSnoozed]="productsService.isSnoozed(option)"
      [snoozeEnd]="option.snooze_end"
      [inStock]="isInStock(option)"
      [price]="option.price"
      [currency]="currency"
      [unitId]="unitId"
      [enableImage]="enableImage"
      [image]="option.image"
      (selected)="selectOption(option)">
    </eo-modifier>
  </div>
</ion-radio-group>
