import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { MenuService } from '../../services/menu.service';
import { DELIVERY_METHODS } from '../../constants/delivery-methods';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryMethodService } from '../../services/delivery-method.service';

@Component({
  selector: 'eo-change-delivery-method-btn',
  templateUrl: './change-delivery-method.component.html',
  styleUrls: ['./change-delivery-method.component.scss'],
})
export class ChangeDeliveryMethodComponent implements OnInit {

  @Input() template: string;
  icon: string;
  selectedDeliveryMethodName: string;

  constructor(
    private navCtrl: NavController,
    private menu: MenuService,
    private storage: Storage,
    private translate: TranslateService,
    private deliveryMethodService: DeliveryMethodService,
  ) {
    this.deliveryMethodService.deliveryMethodObservable.subscribe(() => this.setSelectedDeliveryMethod());
  }

  ngOnInit() {
    this.setSelectedDeliveryMethod();
  }

  setSelectedDeliveryMethod() {
    this.storage.get('selectedDeliveryMethod').then((method) => {
      switch(method) {
        case DELIVERY_METHODS.PICKUP:
          this.selectedDeliveryMethodName = this.translate.instant('TAKEAWAY');
          this.icon = 'takeaway';
          break;
        case DELIVERY_METHODS.DELIVERY:
          this.selectedDeliveryMethodName = this.translate.instant('DELIVERY');
          this.icon = 'delivery';
          break;
        case DELIVERY_METHODS.PICKUP_POINT:
          this.selectedDeliveryMethodName = this.translate.instant('PICKUP_POINT');
          this.icon = 'pickuppoint';
          break;
        case DELIVERY_METHODS.TABLE:
          this.selectedDeliveryMethodName = this.translate.instant('ORDER_ON_THE_SPOT');
          this.icon = 'qrcode';
          break;
      }
    });
  }

  navigateToDeliveryMethodSelection() {
    this.navCtrl.navigateForward('method-selection');
    this.menu.close();
  }

}
