import { Injectable } from '@angular/core';
import { Tags } from '../constants/tags';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  readonly tags = Tags;

  constructor() { }

  setMarketingTags(tags): void {
    tags.forEach(async tag => {
      if(!this.getScript(tag.type)) {
        switch(tag.type) { 
          case this.tags.GOOGLE_TAG_MANAGER: {
            const tagId = tag.fields.find(t => t.id === 'google_tag_manager_id');
            if(tagId) this.loadGoogleTagManager(tagId.value, tag.type);
            break;
          }
          case this.tags.FACEBOOK_PIXEL: {
            const pixelId = tag.fields.find(t => t.id === 'facebook_pixel_id');
            const appId = tag.fields.find(t => t.id === 'facebook_app_id');
            if(pixelId && appId) this.loadFacebookPixel(pixelId.value, appId.id, tag.type);
            break;
          }
        }
      }
    });
  }

  getScript(id): HTMLScriptElement {
    let script;
    const scripts = document.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; ++i) {
      if(scripts[i].id === id) {
        script = scripts[i];
      }
    }
    return script;
  }

  loadGoogleTagManager(id, type) {
    const script = document.createElement("script");
    script.id = type;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    this.appendToHead(script);
  }

  loadFacebookPixel(pixelId, appId, type) {
    (function (f: any, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }; if (!f._fbq) f._fbq = n;
        n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; t.id = type; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    (<any>window).fbq('init', pixelId);
    (<any>window).fbq('set', 'mobileBridge', pixelId, appId);
    (<any>window).fbq('track', 'PageView');
  }

  appendToHead(el) {
    document.head.appendChild(el);
  }

  addEvent(event, parameter?) {
    this.addDataLayerEvent(event, parameter);
    this.addFacebookEvent(event, parameter);
  }

  addDataLayerEvent(event, parameter?) {
    if((<any>window).dataLayer) {
      if(parameter) {
        (<any>window).dataLayer.push({
          'event': event,
          'value': parameter
        });
      } else {
        (<any>window).dataLayer.push({'event': event});
      }
    }
  }

  addFacebookEvent(event, parameter?) {
    if((<any>window).fbq) {
      switch(event) {
        case 'eo_app_create_account': {
          (<any>window).fbq('track', 'CompleteRegistration');
          break;
        }
        case 'eo_app_add_item_to_cart': {
          (<any>window).fbq('track', 'AddToCart');
          break;
        }
        case 'eo_app_checkout': {
          (<any>window).fbq('track', 'InitiateCheckout');
          break;
        }
        case 'eo_app_order': {
          (<any>window).fbq('track', 'Purchase', { value: parameter });
          break;
        }
        default: {
          (<any>window).fbq('track', event);
          break;
        }
      }
    }
  }
  
}
