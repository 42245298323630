import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DELIVERY_METHODS } from 'src/app/constants/delivery-methods';
import { CustomerStore } from 'src/app/modules/customer/store/customer.store';
import { DeliveryAddressService } from 'src/app/modules/delivery-method/services/delivery-address.service';
import { DeliveryTimeServiceInterface } from 'src/app/modules/delivery-time/services/delivery-time-service.interface';
import { DeliveryTimeServiceResolver } from 'src/app/modules/delivery-time/services/delivery-time-service.resolver';
import { DeliveryTimeStore } from 'src/app/modules/delivery-time/store/delivery-time.store';
import { TableDeliveryMethodStore } from 'src/app/modules/delivery-method/store/table-delivery-method.store';
import { DeliveryMethodService } from 'src/app/services/delivery-method.service';
import { DeliveryTimePickerService } from 'src/app/services/delivery-time-picker.service';
import { ModalService } from 'src/app/services/modal.service';
import { Config } from '../../../config';
import { FirmStore } from '../../modules/firm/store/firm.store';

@Component({
  selector: 'sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() firm: any;

  public readonly DELIVERY_METHODS = DELIVERY_METHODS;
  public deliveryMethods: any[] = [];
  public selectedDeliveryMethod = '';
  public deliveryTimeService: DeliveryTimeServiceInterface;

  public deliveryAddress$ = this.customerStore.deliveryAddress$;
  public currentDeliveryTime$ = new BehaviorSubject<string>('');
  public tableNumber$ = new BehaviorSubject<string>('');
  public tableMethodName$ = new BehaviorSubject<string>('');

  private listeners: any[] = [];

  constructor(
    private modalCtrl: ModalController,
    private modalService: ModalService,
    private deliveryMethodService: DeliveryMethodService,
    private deliverAddressService: DeliveryAddressService,
    private deliveryTimeServiceResolver: DeliveryTimeServiceResolver,
    private deliveryTimeStore: DeliveryTimeStore,
    private deliveryTimePickerService: DeliveryTimePickerService,
    private tableMethodStore: TableDeliveryMethodStore,
    private customerStore: CustomerStore,
    private translate: TranslateService,
    private router: Router,
    private firmStore: FirmStore
  ) { }

  public ngOnInit(): void {
    this.listenToEvents();
    this.setTableNumber();
    this.customerStore.setDeliveryAddress();
  }

  public ngOnChanges(): void {
    if (this.firm) {
      this.deliveryMethodService.getSelectedDeliveryMethod()
        .then((method: string) => {
          this.setActiveDeliveryMethods(method);

          this.deliveryTimeServiceResolver
            .resolveDeliveryTimeService(this.firm)
            .then(async (service: DeliveryTimeServiceInterface) => {
              this.deliveryTimeService = service;

              const deliveryTime = this.deliveryTimeStore.getDeliveryTime();

              if (
                !deliveryTime
                && this.firm.theme.show_timepicker_modal
                && this.deliveryTimeStore.modalShown$.getValue() === false
              ) {
                return this.openDateTimeModal();
              }

              if (!deliveryTime && !this.firm.theme.show_timepicker_modal) {
                await this.deliveryTimePickerService.selectFirstAvailableTime();
              }

              this.setCurrentDeliveryTime(this.deliveryTimeStore.getDeliveryTime());
            });
        });
    }
  }

  public ngOnDestroy(): void {
    this.listeners.forEach((listener: any) => listener.unsubscribe());
  }

  private setTableNumber(): void {
    this.tableMethodStore.getSelectedTableNumber()
      .then((tableNumber: any) => {
        this.tableNumber$.next(tableNumber);
      });

    this.tableMethodStore.getSelectedTableMethod()
      .then((tableMethod: any) => {
        this.tableMethodName$.next(tableMethod?.name || this.translate.instant('ORDER_ON_THE_SPOT'));
      });
  }

  private listenToEvents(): void {
    this.listeners[0] = this.deliveryTimeStore
      .updateEvent
      .subscribe(
        (deliveryTime: string) => {
          this.setCurrentDeliveryTime(deliveryTime);
        }
      );

    this.listeners[1] = this.deliveryMethodService
      .selectDeliveryMethodUpdate
      .subscribe(
        (method: string) => {
          this.setActiveDeliveryMethods(method);
        }
      );

    this.listeners[2] = this.tableMethodStore
      .updateSelectedTableNumberEvent
      .subscribe(
        (tableNumber: any) => {
          this.tableNumber$.next(tableNumber);
        }
      );

    this.listeners[3] = this.tableMethodStore
      .updateSelectedTableMethodEvent
      .subscribe(
        (tableMethod: any) => {
          this.tableMethodName$.next(tableMethod?.name || this.translate.instant('ORDER_ON_THE_SPOT'));
        }
      );
  }

  public async openDateTimeModal(): Promise<any> {
    this.deliveryTimeStore.modalShown$.next(true);

    return this.modalService.openDeliveryDateTimeModal();
  }

  public setCurrentDeliveryTime(deliveryTime: string): void {
    if (deliveryTime === 'asap') {
      this.currentDeliveryTime$.next(this.translate.instant('ASAP'));
      return;
    }

    if (!deliveryTime) {
      this.currentDeliveryTime$.next(this.translate.instant('CHOOSE_TIME'));
      return;
    }

    this.currentDeliveryTime$.next(this.deliveryTimeService?.formatDeliveryTime(deliveryTime));
  }

  public setActiveDeliveryMethods(method): void {
    this.deliveryMethods = [];
    this.selectedDeliveryMethod = method;

    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      this.setActiveDeliveryMethodsForMultifirm(method);
    } else {
      this.setActiveDeliveryMethodsForSingleFirm(method);
    }

    const selected = this.deliveryMethods.filter((deliveryMethod: any) => deliveryMethod.selected);

    if (selected.length === 0 && method !== '') {
      this.deliveryMethodService.updateSelectedDeliveryMethod(this.deliveryMethods[0].name).then(() => ({}));
    }
  }

  setActiveDeliveryMethodsForSingleFirm(method) {
    if (
      this.deliveryMethodService.isPickupMethodActiveForFirm(this.firm)
      && !this.deliveryMethodService.isPickupMethodTemporarilyInactive(this.firm)
    ) {
      this.deliveryMethods.push({
        selected: method === DELIVERY_METHODS.PICKUP,
        name: DELIVERY_METHODS.PICKUP,
        icon: 'takeaway',
      });
    }

    if (
      this.deliveryMethodService.isDeliveryMethodActive(this.firm)
      && !this.deliveryMethodService.isDeliveryMethodTemporarilyInactive(this.firm)
    ) {
      this.deliveryMethods.push({
        selected: method === DELIVERY_METHODS.DELIVERY,
        name: DELIVERY_METHODS.DELIVERY,
        icon: 'delivery',
      });
    }

    if (
      this.deliveryMethodService.isPickupPointMethodActive(this.firm.pickup, this.firm.pickup_points)
      && !this.deliveryMethodService.isPickupMethodTemporarilyInactive(this.firm)
    ) {
      this.deliveryMethods.push({
        selected: method === DELIVERY_METHODS.PICKUP_POINT,
        name: DELIVERY_METHODS.PICKUP_POINT,
        icon: 'pickuppoint',
      });
    }

    if (
      this.deliveryMethodService.isTableMethodActive(this.firm)
      && !this.deliveryMethodService.isTableMethodTemporarilyInactive(this.firm)
    ) {
      this.deliveryMethods.push({
        selected: method === DELIVERY_METHODS.TABLE,
        name: DELIVERY_METHODS.TABLE,
        icon: 'qrcode',
      });
    }
  }

  setActiveDeliveryMethodsForMultifirm(method) {
    if (this.deliveryMethodService.isMethodEnabledForMultiFirm(DELIVERY_METHODS.PICKUP)) {
      this.deliveryMethods.push({
        selected: method === DELIVERY_METHODS.PICKUP,
        name: DELIVERY_METHODS.PICKUP,
        icon: 'takeaway',
      });
    }

    if (this.deliveryMethodService.isMethodEnabledForMultiFirm(DELIVERY_METHODS.DELIVERY)) {
      this.deliveryMethods.push({
        selected: method === DELIVERY_METHODS.DELIVERY,
        name: DELIVERY_METHODS.DELIVERY,
        icon: 'delivery',
      });
    }

    if (this.deliveryMethodService.isMethodEnabledForMultiFirm(DELIVERY_METHODS.PICKUP_POINT)) {
      this.deliveryMethods.push({
        selected: method === DELIVERY_METHODS.PICKUP_POINT,
        name: DELIVERY_METHODS.PICKUP_POINT,
        icon: 'pickuppoint',
      });
    }

    if (this.deliveryMethodService.isMethodEnabledForMultiFirm(DELIVERY_METHODS.TABLE)) {
      this.deliveryMethods.push({
        selected: method === DELIVERY_METHODS.TABLE,
        name: DELIVERY_METHODS.TABLE,
        icon: 'qrcode',
      });
    }
  }

  public mustDisplayDeliveryAddress(): boolean {
    return this.selectedDeliveryMethod === DELIVERY_METHODS.DELIVERY;
  }

  public mustDisplayTableNumber(): boolean {
    return this.selectedDeliveryMethod === DELIVERY_METHODS.TABLE;
  }

  public async goToMethodSelection(): Promise<void> {
    await this.router.navigate(['/method-selection']);
  }

  public async openDeliveryAddressModal(showSwitcher: boolean): Promise<void> {
    const modal = await this.deliverAddressService.openDeliveryAddressSelector(showSwitcher);

    modal.onDidDismiss()
      .then(({ data }) => {
        if (data.cancelled) {
          return;
        }

        this.deliveryTimeStore.setDeliveryTimeWithoutEvent(undefined);
      });
  }

  public hasMethodSelected(): boolean {
    return this.selectedDeliveryMethod && this.selectedDeliveryMethod !== '';
  }

  public hasOneDeliveryMethodSelectedButNotEatIn(): boolean {
    return this.selectedDeliveryMethod
      && this.selectedDeliveryMethod !== ''
      && this.selectedDeliveryMethod !== DELIVERY_METHODS.TABLE;
  }

  public async handleOnDeliveryMethodTap(): Promise<void> {
    if (this.selectedDeliveryMethod === DELIVERY_METHODS.DELIVERY) {
      return this.openDeliveryAddressModal(true);
    }

    if (this.selectedDeliveryMethod === DELIVERY_METHODS.PICKUP) {
      return this.goToMethodSelection();
    }

    if (this.selectedDeliveryMethod === DELIVERY_METHODS.PICKUP_POINT) {
      return this.modalService.openPickPointSelectionModal(this.firm.pickup_points, this.firm, true);
    }

    if (this.selectedDeliveryMethod === DELIVERY_METHODS.TABLE) {
      if (this.firm.custom_table_methods && this.firm.custom_table_methods.length > 0) {
        return this.modalService.openTableMethodSelectionModal(
          this.firm.custom_table_methods,
          this.firm,
          true,
        );
      }

      if (this.firm.table_numbers && this.firm.table_numbers.length > 0) {
        await this.tableMethodStore.resetSelectedTableMethod();

        return this.modalService.openTableNumberSelectionModal(
          this.firm.table_numbers,
          this.firm.labels.table,
          this.firm,
          true,
        );
      }

      await this.tableMethodStore.resetSelectedTableMethod();
      await this.tableMethodStore.resetSelectedTableNumber();
      return this.goToMethodSelection();
    }
  }
}
