<ng-container [ngTemplateOutlet]="getTemplateRef(template) || default"></ng-container>

<ng-template #default>
  <div class="block-item container-shadow ion-text-center" tappable (click)="openFirm()">
    <div class="shop-closed" *ngIf="firm?.is_offline">
      <p>{{ 'SHOP_CLOSED' | translate }}</p>
    </div>

    <div class="shop-image img-center-center" [style.background-image]="'url('+ firm?.shop_image +')'"></div>

    <div class="info">
      <p class="firm-name font-14">{{ firm?.name }}</p>
      <p class="address font-13">{{ firm?.address }},</p>
      <p class="address font-13">{{ firm?.zipcode }} {{ firm?.locality }}</p>
    </div>

    <p class="display-opening-hours font-12" tappable (click)="$event.stopPropagation(); openInfoModal()">{{ 'SHOW_OPENING_HOURS' | translate }}</p>
    <ion-button class="custom-btn" *ngIf="!firm?.is_demo">{{ 'CHOOSE' | translate }}</ion-button>
    <ion-button class="custom-btn" *ngIf="firm?.is_demo">{{ 'UNDER_CONSTRUCTION' | translate }}</ion-button>
  </div>
</ng-template>

<ng-template #locationPageStyle2>
  <div class="style2-container">
    <div class="image-container" [style.background-image]="'url('+ firm?.shop_image +')'"></div>

    <div class="info-container">
      <div class="center-container">
        <p class="firm-name font-14">{{ firm?.name }}</p>
        <p class="address font-13">{{ firm?.address }}, {{ firm?.locality }}</p>
        <div class="buttons">
          <ion-button class="custom-btn" (click)="openFirm()" *ngIf="!firm?.is_demo">{{ 'CHOOSE' | translate }}</ion-button>
          <ion-button class="custom-btn" (click)="openInfoModal()">{{ 'OPENING_HOURS' | translate }}</ion-button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
