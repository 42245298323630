<ion-content>
  <eo-modal
    [submitText]="'CONTINUE' | translate"
    (submitted)="save()"
  >
    <method-delivery-switcher *ngIf="showSwitcher" topLeft></method-delivery-switcher>
    <div class="full-width" content>
      <div class="title-box">
        <ion-icon src="../../assets/images/qrcode_thumb.svg"></ion-icon>
        <div>
          <p>{{ 'ORDER_ON_THE_SPOT' | translate }}</p>
          <p>{{ 'SELECT_AN_OPTION' | translate }}</p>
        </div>
      </div>

      <div scrollx="true">
        <ion-radio-group [(ngModel)]="selectedMethodId">
          <div class="box" *ngFor="let method of methods">
            <ion-row tappable (click)="selectMethod(method)">

              <ion-col size="10">
                <p class="title-txt">{{ method?.name }}</p>
              </ion-col>

              <ion-col size="2" class="radio-col">
                <ion-radio mode="md" [value]="method?.id"></ion-radio>
              </ion-col>

            </ion-row>
          </div>
        </ion-radio-group>
      </div>
    </div>
  </eo-modal>
</ion-content>
