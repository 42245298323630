import { Component } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'powered-by',
  templateUrl: 'powered-by.html',
  styleUrls: ['powered-by.scss']
})
export class PoweredByComponent {
  
  distributor: any;

  constructor(private storage: Storage,
              public platform: Platform) {}

  async ngOnInit() {
    this.distributor = await this.storage.get('distributor');
  }

}
