import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'eo-nav-slider',
  templateUrl: './nav-slider.component.html',
  styleUrls: ['./nav-slider.component.scss'],
})
export class NavSliderComponent implements OnInit {

  @Output() clicked = new EventEmitter<any>();

  @Input() categories: any;
  @Input() iconsEnabled: boolean = false;
  @Input() activeCategory: any;

  constructor(public productsService: ProductsService) { }

  ngOnInit() {
    if(this.categories) {
      this.selectCategory(this.categories[0]);
    }
  }

  ngOnChanges() {
    this.scrollToActiveElement();
  }

  selectCategory(category) {
    this.activeCategory = `nav-${category.name}`;
    this.clicked.emit(category);
  }

  async scrollToActiveElement() {
    const activeEl = await document.getElementById(this.activeCategory);
    activeEl.scrollIntoView({behavior: "smooth"});
  }

  isActive(categoryName) {
    return `nav-${categoryName}` === this.activeCategory;
  }
}
