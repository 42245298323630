import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Address } from 'src/app/core/models/address';

@Injectable({
  providedIn: 'root',
})
export class CustomerStore {
  public customer: any;
  public addresses: Address[];
  public selectedAddress: Address;

  public deliveryAddress$ = new BehaviorSubject<string>('');

  constructor(
    private storage: Storage,
    private translate: TranslateService,
  ) { }

  public setCustomer(customer: any): void {
    this.customer = customer;
  }

  public setAddresses(addresses: Address[]): void {
    this.addresses = addresses;
  }

  public setSelectedAddress(address: Address): void {
    this.selectedAddress = address;
    this.setDeliveryAddress();
    this.storage.set('selectedAddress', address).then(() => {});
  }

  public updateAddress(address: Address): void {
    if (address.is_default) {
      this.updateIsDefaultToFalseForAllAddresses();
    }

    const index = this.addresses.findIndex((a: Address) => a.id === address.id);
    this.addresses.splice(index, 1, address);
  }

  public addAddress(address: Address): void {
    if (address.is_default) {
      this.updateIsDefaultToFalseForAllAddresses();
    }

    this.addresses.push(address);
  }

  public setDeliveryAddress(): void {
    if (this.selectedAddress && this.selectedAddress.street.length > 10) {
      this.deliveryAddress$.next(`${this.selectedAddress.street.substr(0, 10)}...`);
      return;
    }

    if (this.selectedAddress) {
      this.deliveryAddress$.next(this.selectedAddress.street);
      return;
    }

    this.deliveryAddress$.next(this.translate.instant('FILL_IN_ADDRESS'));
  }

  private updateIsDefaultToFalseForAllAddresses(): void {
    this.addresses = this.addresses.map((address: Address) => ({
      ...address,
      is_default: false,
    }));
  }
}
