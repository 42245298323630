import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UUID } from 'angular2-uuid';
import { Config } from '../../config';
import { Units } from '../constants/units';
import { ErrorService } from './error.service';
import { HeadersService } from './headers.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  endpoint_calculate = "/order/calculate";
  endpoint_confirm = "/order/calculate?confirm=1";
  endpoint_calculate_order = "/order/calculate_delivery";
  endpoint_addorder = "/order"
  endpoint_orders = "/orders/";
  endpoint_pending = '/orders/pending';
  endpoint_past = '/orders/past';
  endpoint_status = '/payment_status';
  endpoint_coupon = '/coupon';

  uuid: any;

  constructor(private http: HttpClient,
    private errorService: ErrorService,
    private storage: Storage,
    private headers: HeadersService) { }

  /* Calculate time of order */
  async calculateTime(order: any, cust_id) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders(cust_id);
      headers = await headers.append('uuid', String(await this.storage.get('uuid')));

      let body: any = {
        categories: order.categories,
        delivery_method: order.delivery_method,
        products: order.products,
        timestamp: order.timestamp
      };

      if(order.firm_pickup_point_id) {
        body.firm_pickup_point_id = order.firm_pickup_point_id;
      }

      return this.http.post(environment.API_URL + this.endpoint_calculate, body, { headers: headers })
        .toPromise()
        .then(async (data: any) => {
          return data;
        })
        .catch(async err => {
          let error = await this.errorService.checkCalculateErrors(err);
          if (error === 'TOKEN_INVALID') {
            this.calculateTime(order, cust_id);
          }
        });
    }
  }

  /* Calculate the cost of delivery */
  async calculateOrder(order, cust_id) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders(cust_id);
      headers = await headers.append('uuid', String(await this.storage.get('uuid')));

      return this.http.post(environment.API_URL + this.endpoint_calculate_order, order, { headers: headers })
        .toPromise()
        .then(async (data: any) => {
          return data;
        })
        .catch(async err => {
          let error = await this.errorService.checkCalculateErrors(err);

          if (error === 'TOKEN_INVALID') {
            this.calculateOrder(order, cust_id);
          } else if (error === 'ORDER_PRICE_TOO_LOW_FOR_DELIVERY') {
            return { error: true, errorCode: 'orderPriceTooLow', minPrice: err.error.message };
          }

          return { error: true };
        });
    }
  }

  /* Confirm chosen time */
  async confirmTime(order: any, cust_id) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders(cust_id);
      headers = await headers.append('uuid', String(await this.storage.get('uuid')));

      return this.http.post(environment.API_URL + this.endpoint_confirm, order, { headers: headers })
        .toPromise()
        .then(data => {
          return data;
        })
        .catch(async err => {
          let error = await this.errorService.checkCalculateErrors(err);
          if (error === 'TOKEN_INVALID') {
            this.confirmTime(order, cust_id);
          }
        });
    }
  }

  /* Add order to server */
  async add(order, cust_id, firm, total_price) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders(cust_id);
      headers = await headers.append('uuid', String(await this.storage.get('uuid')));

      var orderStr = JSON.stringify(order);
      var body = JSON.parse(orderStr);

      if (body.discount_percentage === 0) {
        body.discount_percentage = undefined;
      }
      if (body.products.length === 0) {
        body.products = undefined;
      }
      if (body.coupon) {
        body.coupon = undefined;
      }
      if (total_price) {
        if (total_price < 0 || total_price === 0) {
          body.payment_method_id = 1;
        }
      }

      return this.http.post(environment.API_URL + this.endpoint_addorder, body, { headers: headers })
        .toPromise()
        .then(data => {
          return data;
        })
        .catch(async err => {
          let body = err.error;

          let error = await this.errorService.checkOrderErrors(err);

          if (error === 'TOKEN_INVALID') {
            this.add(order, cust_id, firm, total_price);
          }
          else if (error === 'BASKET_CHANGE') {
            body.type = 'basket_change';
            return body;
          }
          else if (error === 'INVALID_TIMESTAMP') {
            body.type = 'invalid_timestamp';
            return body;
          }
          else if (error === 'INVALID_UUID') {
            await this.setUuid();
            await this.add(order, cust_id, firm, total_price);
          }
        });
    }
  }

  /* Get order from server */
  async get(order_id, cust_id) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders(cust_id);
      return this.http.get(environment.API_URL + this.endpoint_orders + order_id, { headers: headers })
        .toPromise()
        .then((data: any) => {
          return data.data;
        })
        .catch(() => {
          return "ERROR";
        });
    }
  }

  /* Get order from server */
  async getStatus(order_id, cust_id) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders(cust_id);
      return this.http.get(environment.API_URL + this.endpoint_orders + order_id + this.endpoint_status, { headers: headers })
        .toPromise()
        .then(data => {
          return data;
        })
        .catch(() => {
          return "ERROR";
        });
    }
  }

  /* Get pending orders */
  async getPendingOrders(cust_id) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders(cust_id);
      return this.http.get(environment.API_URL + this.endpoint_pending, { headers: headers })
        .toPromise()
        .then((data: any) => {
          return data.data;
        })
        .catch(async err => {
          let error = await this.errorService.checkOrderErrors(err);
          if (error === 'TOKEN_INVALID') {
            this.getPendingOrders(cust_id);
          }
        });
    }
  }

  /* Get past orders */
  async getPastOrders(cust_id) {
    if (this.errorService.checkConnection()) {
      let headers = await this.getHeaders(cust_id);
      return this.http.get(environment.API_URL + this.endpoint_past, { headers: headers })
        .toPromise()
        .then((data: any) => {
          return data.data;
        })
        .catch(async err => {
          let error = await this.errorService.checkOrderErrors(err);
          if (error === 'TOKEN_INVALID') {
            this.getPastOrders(cust_id);
          }
        });
    }
  }

  /* Check coupon */
  async checkCoupon(body) {
    if (this.errorService.checkConnection()) {
      let headers = await this.headers.getHeaders();

      const login = await this.storage.get('login');
      if(login) {
        headers = await headers.append('customerId', String(login.id));
      }

      headers = await headers.append('passwordToken', await this.headers.checkToken());
      headers = await headers.append('demoPasswordToken', await this.headers.getDemoToken());
      headers = await headers.append('firmId', String(await this.storage.get('firm')));
      headers = await headers.append('language', await this.headers.getLanguage());
      headers = await headers.append('uuid', String(await this.storage.get('uuid')));
      if(Config.STORE_ID) {
        headers = await headers.append('storeId', Config.STORE_ID);
      }

      return this.http.post(environment.API_URL + this.endpoint_coupon, body, { headers: headers })
        .toPromise()
        .then(data => {
          return data;
        })
        .catch(async err => {
          const errorTriggered = await this.errorService.checkErrors(err.status);

          if(errorTriggered) {
            if(err.status === 401) this.checkCoupon(body);
          }
          else {
            return err.error;
          }
        });
    }
  }

  setUuid() {
    this.storage.set('uuid', UUID.UUID());
  }

  /* Set the products of the order */
  async setProducts(order, firm) {
    // set products
    let products = [];
    order.basket_items.forEach(item => {
      let ingredients = [];
      let product_options = [];

      // set ingredients
      item.item.ingredients.forEach(ingr => {
        let ingredient = {
          id: ingr.id,
          type: ingr.type,
          price: ingr.price,
          quantity: ingr.amount,
          action: ingr.action,
          selected: ingr.selected
        }
        ingredients.push(ingredient);
      });

      // set product options
      if (item.item.product_options) {
        item.item.product_options.forEach(option => {
          let product_option = {
            group_id: option.id,
            id: option.selected_option.id,
            price: option.selected_option.price
          }
          product_options.push(product_option)
        });
      }

      // Parse init item
      let init_item = JSON.parse(item.item.init_item);

      // set product
      let product: any = {
        id: init_item.id,
        price: init_item.price,
        parent_cat_id: init_item.category.parent_category_id,
        cat_id: init_item.category.category_id,
        quantity: item.item.amount,
        ingredients: ingredients,
        product_options: product_options,
        comment: item.item.comment,
        unit_id: item.item.unit_id
      }

      if (item.item.unit_id === Units.KG) {
        product.quantity = item.item.amount / 1000;
      }

      if (init_item.weight != null) {
        product.weight = init_item.weight;
      }

      // Set price of product
      if (item.item.item.has_promotional_price) {
        product.price = init_item.promotion.price;
      }
      else {
        product.price = init_item.price;
      }

      products.push(product);
    });

    order.products = products;
    return order;
  }

  async getHeaders(cust_id) {
    let headers = await this.headers.getHeaders();
    headers = await headers.append('passwordToken', await this.headers.checkToken());
    headers = await headers.append('demoPasswordToken', await this.headers.getDemoToken());
    headers = await headers.append('firmId', String(await this.storage.get('firm')));
    headers = await headers.append('language', await this.headers.getLanguage());
    headers = await headers.append('customerId', String(cust_id));
    headers = await headers.append('customerToken', await this.storage.get('customerToken'));
    if(Config.STORE_ID) {
      headers = await headers.append('storeId', Config.STORE_ID);
    }
    return headers;
  }

}
