import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'eo-modal',
  templateUrl: './eo-modal.component.html',
  styleUrls: ['./eo-modal.component.scss']
})
export class EoModalComponent {
  @Input() submitButtonEnabled = true;
  @Input() submitText: string;
  @Input() customFooter = false;
  @Output() submitted = new EventEmitter<void>();

  constructor(
    private modalCtrl: ModalController
  ) {}

  public async handleOnClose(): Promise<void> {
    await this.modalCtrl.dismiss({ cancelled: true });
  }

  submitHandler() {
    this.submitted.emit();
  }
}
