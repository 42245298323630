import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  browser: any;

  constructor(private iab: InAppBrowser) { }

  /* Open iab with payment form */
  open(pageContentUrl){
    this.browser = this.iab.create(pageContentUrl, '_blank');
  }

  /* Close iab */
  close() {
    if(this.browser) {
      this.browser.close();
    }
  }
}