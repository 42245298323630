import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

/* Languages */
export const availableLanguages = [
  {
    id: '1',
    code: 'nl',
    name: 'Nederlands'
  },
  {
    id: '2',
    code: 'fr',
    name: 'Français'
  },
  {
    id: '3',
    code: 'en',
    name: 'English'
  },
  {
    id: '4',
    code: 'es',
    name: 'Español'
  },
  {
    id: '5',
    code: 'de',
    name: 'Deutsch'
  },
  {
    id: '6',
    code: 'pt',
    name: 'Portuguese'
  }
];
export const defaultLanguage = availableLanguages[0].code;

export const sysOptions = {
  systemLanguage: defaultLanguage
};

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  default_lang: any = defaultLanguage;

  constructor(
    private translate: TranslateService,
    private storage: Storage
  ) { }

  getDeviceLanguage() {

    this.storage.get('container_language').then((container_language) => {
      if (!container_language) {
        let language;
        if ((window as any).Intl && typeof (window as any).Intl === 'object') {
          language = this.getSuitableLanguage(navigator.language);
          this.setLanguage(language);
          sysOptions.systemLanguage = language;
        }
        else {
          const browserLanguage = this.translate.getBrowserLang() || defaultLanguage;
          language = this.getSuitableLanguage(browserLanguage);
          this.setLanguage(language);
          this.storage.set('device_language', language);
          sysOptions.systemLanguage = language;
        }

        let selected_container_lang;
        if (language === 'nl') {
          selected_container_lang = {
            name: 'Nederlands',
            code: language
          };
        }
        else if (language === 'en') {
          selected_container_lang = {
            name: 'English',
            code: language
          };
        }
        else if (language === 'fr') {
          selected_container_lang = {
            name: 'Français',
            code: language
          };
        }
        else if (language === 'de') {
          selected_container_lang = {
            name: "Deutsch",
            code: language
          }
        } else if(language === 'pt') {
          selected_container_lang = {
            name: "Portuguese",
            code: language
          }
        }
        this.storage.set('container_language', selected_container_lang);
      } else {
        this.storage.get('language').then((language) => {
          if (language) {
            this.setLanguage(language.code);
          } else {
            this.setLanguage(container_language.code.toLowerCase());
          }
        });
      }
    });
  }

  getSuitableLanguage(language) {
    language = language.substring(0, 2).toLowerCase();
    return availableLanguages.some(lang => lang.code === language) ? language : defaultLanguage;
  }

  setLanguage(language) {
    this.translate.use(language);
    moment.locale(language);
  }
}
