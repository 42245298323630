import { DELIVERY_METHODS } from 'src/app/constants/delivery-methods';
import { AbstractDeliveryTimeService } from 'src/app/modules/delivery-time/services/abstract-delivery-time.service';

export class TableDeliveryTimeService extends AbstractDeliveryTimeService {
  getCustomDays(): any[] {
    return [];
  }

  getDeliveryMethod(): string {
    return DELIVERY_METHODS.TABLE;
  }

  hasCustomDays(): boolean {
    return false;
  }

  hasOrderTimeslots(): boolean {
    return false;
  }

  isLimitedToSameDayOrdering(): boolean {
    return true;
  }

  selectDayFromPeriodsByDate(date: string): any {
    return null;
  }
}
