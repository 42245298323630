import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MethodSelectorEvents } from 'src/app/modules/delivery-method/events/MethodSelectorEvents';

@Component({
  selector: 'not-deliverable-modal',
  templateUrl: './not-deliverable-modal.component.html',
  styleUrls: ['./not-deliverable-modal.component.scss']
})
export class NotDeliverableModalComponent {
  constructor(
    private modalController: ModalController,
    private methodSelectorEvents: MethodSelectorEvents
  ) { }

  public async closeModal(): Promise<void> {
    await this.modalController.dismiss();
  }

  public async chooseBack(): Promise<void> {
    await this.closeModal();
    this.methodSelectorEvents.selectDelivery.emit(true);
  }

  public async chooseTakeaway(): Promise<void> {
    await this.closeModal();
    this.methodSelectorEvents.selectTakeaway.emit(true);
  }
}
