import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minimumPrice',
})
export class MinimumPricePipe implements PipeTransform {

  transform(value: any, ...args) {

    if(value < 0) {
      value = 0;
    }

    return Number(value);
  }
}
