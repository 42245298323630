import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductsService } from '../../services/products.service';
import * as moment from 'moment';
import { Units } from '../../constants/units';

@Component({
  selector: 'eo-option-group',
  templateUrl: './option-group.component.html',
  styleUrls: ['./option-group.component.scss'],
})
export class OptionGroupComponent implements OnInit {
  @Input() group: any;
  @Input() currency: any;
  @Input() unitId: any;
  @Input() enableImage: boolean;

  @Output() selected = new EventEmitter<any>();

  selectedOption: any;
  moment = moment;
  units = Units;

  constructor(
    public productsService: ProductsService
  ) { }

  ngOnInit() {
    this.setSelectedOption();
  }

  setSelectedOption() {
    const option = this.group.options.find(option => option.selected);
    if(option) this.selectedOption = option.id;
  }

  selectOption(option) {
    if(this.isInStock(option)) {
      this.selectedOption = option.id;
      this.selected.emit(option);
    }
  }

  isInStock(item) {
    return item.in_stock;
  }

}
