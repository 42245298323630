import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Language } from 'src/app/modules/language/types/language';

@Injectable({
  providedIn: 'root',
})
export class LanguageStore {
  public languages: Language[];
  public selectedLanguage: Language|null = null;

  public updateEvent$: BehaviorSubject<Language>;

  constructor() {
    this.updateEvent$ = new BehaviorSubject<Language>(null);
  }

  public setLanguages(languages: Language[]): void {
    this.languages = languages;
  }

  public selectLanguage(language: Language): void {
    this.selectedLanguage = language;
    this.updateEvent$.next(language);
  }
}
