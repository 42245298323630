<ion-content>
  <eo-modal
    [submitText]="'BUTTONS.CLOSE' | translate"
    (submitted)="handleOnSubmit()"
  >
    <ion-grid content>
      <ion-row class="ion-align-items-center">
        <ion-col size="2">
          <ion-icon name="alert-circle"></ion-icon>
        </ion-col>
        <ion-col><strong>{{ 'OOPS' | translate }}</strong></ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          {{ message }}
        </ion-col>
      </ion-row>
    </ion-grid>
  </eo-modal>
</ion-content>
