import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { Config } from '../../config';
import { ErrorService } from './error.service';
import { HeadersService } from './headers.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  endpoint_order = "/order/";
  endpoint_ogone = "/pay/ogone";
  endpoint_ideal = "/pay/ideal";
  endpoint_ems = "/pay/ems";
  endpoint_stripe = "/pay/stripe";
  endpoint_mollie = "/pay/mollie";

  paymentResumed = new Subject();
  paymentResumedObservable = this.paymentResumed.asObservable();

  constructor(private http: HttpClient,
              private errorService: ErrorService,
              private storage: Storage,
              private headers: HeadersService,
              private platform: Platform) { }

  async ogone(cust_id, order_id, firm) {
    let headers = await this.getHeaders(cust_id);
    return this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_ogone, await this.getAppReturnUrls(order_id), { headers: headers })
    .toPromise()
    .then((data: any) => {
      return data;
    })
    .catch(async err => {
      let error = await this.errorService.checkPaymentErrors(err);
      if(error === 'ORDER_ALREADY_PAID') {
        return "ALREADY_PAID";
      }
      else if(error === 'TOKEN_INVALID') {
        this.ogone(cust_id, order_id, firm.app_url);
      }
      else {
        return "ERROR";
      }
    });
  }

  async ideal(cust_id, order_id, firm) {
    let headers = await this.getHeaders(cust_id);
    return this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_ideal, await this.getAppReturnUrls(order_id), { headers: headers })
    .toPromise()
    .then(data => {
      return data
    })
    .catch(async err => {
      let error = await this.errorService.checkPaymentErrors(err);
      if(error === 'ORDER_ALREADY_PAID') {
        return "ALREADY_PAID";
      }
      else if(error === 'TOKEN_INVALID') {
        this.ideal(cust_id, order_id, firm.app_url);
      }
      else {
        return "ERROR";
      }
    });
  }

  async ems(cust_id, order_id, firm) {
    let headers = await this.getHeaders(cust_id);
    return this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_ems, await this.getBackendReturnUrls(order_id), { headers: headers })
    .toPromise()
    .then((data: any) => {
      return data;
    })
    .catch(async err => {
      let error = await this.errorService.checkPaymentErrors(err);

      if(error === 'ORDER_ALREADY_PAID') {
        return "ALREADY_PAID";
      }
      else if(error === 'TOKEN_INVALID') {
        this.ems(cust_id, order_id, firm.app_url);
      }
      else {
        return "ERROR";
      }
    });
  }

  async stripe(cust_id, order_id, firm) {
    let headers = await this.getHeaders(cust_id);
    return this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_stripe, await this.getBackendReturnUrls(order_id), { headers: headers })
    .toPromise()
    .then(async (data: any) => {
      return data;
    })
    .catch(async err => {
      let error = await this.errorService.checkPaymentErrors(err);
      if(error === 'ORDER_ALREADY_PAID') {
        return "ALREADY_PAID";
      }
      else if(error === 'TOKEN_INVALID') {
        this.stripe(cust_id, order_id, firm.app_url);
      }
      else {
        return "ERROR";
      }
    });
  }

  async mollie(cust_id, order_id, firm) {
    let headers = await this.getHeaders(cust_id);
    return this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_mollie, await this.getAppReturnUrls(order_id), { headers: headers })
    .toPromise()
    .then(async (data: any) => {
      return data;
    })
    .catch(async err => {
      let error = await this.errorService.checkPaymentErrors(err);
      if(error === 'ORDER_ALREADY_PAID') {
        return "ALREADY_PAID";
      }
      else if(error === 'TOKEN_INVALID') {
        this.mollie(cust_id, order_id, firm.app_url);
      }
      else {
        return "ERROR";
      }
    });
  }

  async initiateOnlinePayment(paymentType, customerId, orderId) {
    const headers = await this.getHeaders(customerId);
    const body = {
      returnUrl: `${Config.URL_SCHEME}easyorderapp.com/payment`,
    };
    return this.http.post(
      `${environment.API_URL}/payment/order/${orderId}/pay/${paymentType}`,
      body,
      { headers })
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(async err => {
        const error = await this.errorService.checkPaymentErrors(err);

        switch (error) {
          case 'ORDER_ALREADY_PAID':
            return 'ALREADY_PAID';
          case 'TOKEN_INVALID':
            return 'TOKEN_INVALID';
          default:
            return 'ERROR';
        }
      });
  }

  async getAppReturnUrls(order_id) {
    return {
      "acceptUrl" :      Config.URL_SCHEME + "easyorderapp.com/payment?accept=true&id=" + order_id,
      "declineUrl" :     Config.URL_SCHEME + "easyorderapp.com/payment?decline=true&id=" + order_id,
      "cancelUrl" :      Config.URL_SCHEME + "easyorderapp.com/payment?cancel=true&id=" + order_id,
      "exceptionUrl" :   Config.URL_SCHEME + "easyorderapp.com/payment?exception=true&id=" + order_id
    };
  }

  async getBackendReturnUrls(order_id) {
    return {
      "acceptUrl" :     environment.API_URL + "/return-payment-ems?scheme=" + Config.URL_SCHEME + "easyorderapp.com/payment?accept=true&id=" + order_id,
      "cancelUrl" :     environment.API_URL + "/return-payment-ems?scheme=" + Config.URL_SCHEME + "easyorderapp.com/payment?cancel=true&id=" + order_id,
      "exceptionUrl" :  environment.API_URL + "/return-payment-ems?scheme=" + Config.URL_SCHEME + "easyorderapp.com/payment?exception=true&id=" + order_id
    };
  }

  getDeviceType() {
    var deviceType;

    if(this.platform.is('tablet')) {
      deviceType = 'tablet';
    }
    else {
      deviceType = 'mobile';
    }

    return deviceType;
  }

  async getHeaders(cust_id) {
    let headers: any = await this.headers.getHeaders();
    headers = await headers.append('firmId', String(await this.storage.get('firm')));
    headers = await headers.append('passwordToken', await this.headers.checkToken());
    headers = await headers.append('demoPasswordToken', await this.headers.getDemoToken());
    headers = await headers.append('language', await this.headers.getLanguage());
    headers = await headers.append('customerId', String(cust_id));
    headers = await headers.append('customerToken', await this.storage.get('customerToken'));
    if(Config.STORE_ID) {
      headers = await headers.append('storeId', Config.STORE_ID);
    }
    return headers;
  }
}
