import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavService } from '../../services/nav.service';
import { FirmService } from '../../services/firm.service';

@Component({
  selector: 'featured-product',
  templateUrl: './featured-product.component.html',
  styleUrls: ['./featured-product.component.scss'],
})
export class FeaturedProductComponent implements OnInit {

  @Input() item: any;
  @Input() firm: any;

  constructor(private navCtrl: NavController,
              private navService: NavService,
              private firmService: FirmService) { }

  async ngOnInit() {
    this.firm = await this.firmService.getLocalFirm();
  }

  goToFeaturedProduct(product) {
    this.navService.item = JSON.stringify(product);
    this.navService.cat_id = product.category.category_id;
    this.navCtrl.navigateForward('/item');
  }

}
