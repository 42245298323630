import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { CustomerStore } from 'src/app/modules/customer/store/customer.store';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { AlertService } from './alert.service';
import { HeadersService } from './headers.service';
import { NavService } from './nav.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  client = {
    client_id: 'app',
    client_secret: 'VhtDuF5GQjXyMu3Md8XTKuys3t4vy8astxOAGCeE'
  };

  endpoint_auth = '/client/login';

  loggedOut = new Subject();
  loggedOutObservable = this.loggedOut.asObservable();

  constructor(private http: HttpClient,
              private storage: Storage,
              private customerStore: CustomerStore,
              private alertService: AlertService,
              private headers: HeadersService,
              private navService: NavService) {}

  async getToken() {
    const headers = await this.headers.getHeaders();
    return this.http.post(
      environment.API_URL + this.endpoint_auth,
      this.client,
      { headers }
    )
    .toPromise()
    .then((data: any) => {
      this.storage.set('access_token', data.access_token);
      return data.access_token;
    })
    .catch(async () => {
      return this.alertService.presentServerNotRespondingError();
    });
  }

  async logout() {
    this.customerStore.setCustomer(undefined);
    this.customerStore.setAddresses([]);
    await this.storage.set('login', null);
    await this.storage.set('customerToken', undefined);
    await this.storage.set('uuid', undefined);
    await this.storage.set('unreadMessages', undefined);
    this.loggedOut.next();
    this.navService.loginRedirect = 'menu';
  }

}
