<ion-content>
  <eo-modal
    [submitText]="'BUTTONS.CLOSE' | translate"
    [customFooter]="true"
  >
    <ion-grid content>
      <ion-row class="ion-align-items-center">
        <ion-col size="2">
          <ion-icon name="alert-circle"></ion-icon>
        </ion-col>
        <ion-col><strong>{{ 'OOPS' | translate }}</strong></ion-col>
      </ion-row>
      <ion-row>
        <ion-col [innerHTML]="'DIALOGS.CANNOT_DELIVER_TO_YOUR_ADDRESS' | translate">
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid class="ion-no-padding" footer>
      <ion-row>
        <ion-col class="small-padding-right" size="8">
          <ion-button class="casual-btn" tappable (click)="chooseTakeaway()">
            <ion-icon src="../../assets/images/takeaway.svg"></ion-icon>
            <div class="text">{{ 'TAKEAWAY' | translate }}</div>
          </ion-button>
        </ion-col>
        <ion-col size="4">
          <eo-button
            [label]="'BUTTONS.BACK' | translate"
            (clicked)="chooseBack()"
          ></eo-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </eo-modal>
</ion-content>
