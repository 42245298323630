<div class="flex">

  <div *ngIf="item?.extra_points && item?.extra_points > 0 && firm?.template_categories === 'style2'" class="gold-border"></div>

  <div [ngClass]="{
    'style1-container container-shadow': firm?.template_categories === 'style1',
    'style2-container': firm?.template_categories === 'style2',
    'gold-container': firm?.template_categories === 'style1' && item?.extra_points && item?.extra_points > 0 }">

    <!-- Promo & out of stock -->
    <div class="badge-container" *ngIf="firm?.template_categories === 'style1'">
      <div *ngIf="!item?.in_stock" class="stock-container">
        <p>{{ 'NOT_IN_STOCK' | translate }}</p>
      </div>
      <div *ngIf="item?.has_promotional_price" class="promo-container container-shadow">
        <p>{{ 'PROMO' | translate }}</p>
      </div>
    </div>

    <!-- Content -->
    <div class="content-container" tappable (click)="firm?.template_categories === 'style1' && firm?.template_modifiers === 'style1' ? openOrderBox($event) : goToItemPage($event)">

      <!-- Image (style1) -->
      <div class="image-container ion-no-padding" *ngIf="firm?.product_images_enabled && firm?.template_categories === 'style1'" tappable (click)="goToItemPage($event)">
        <div *ngIf="item.images" class="img" [ngStyle]="{'background-image': 'url(' + productsService.getImage(item.images) + ')'}"></div>
      </div>

      <!-- Extra points (style1) -->
      <div *ngIf="item?.extra_points && item?.extra_points > 0 && firm?.template_categories === 'style1'" class="extra-points-container">
        <p>+{{ item?.extra_points }} {{ 'STAMPS_LOWERCASE' | translate }}</p>
      </div>

      <div class="center">

        <!-- Promo & out of stock -->
        <div class="badge-container" *ngIf="firm?.template_categories === 'style2'">
          <div *ngIf="!item?.in_stock" class="stock-container" [ngClass]="!item?.in_stock && item?.has_promotional_price ? 'stock-container-with-promo' : 'stock-container'">
            <p>{{ 'NOT_IN_STOCK' | translate }}</p>
          </div>
          <div *ngIf="item?.has_promotional_price" class="promo-container container-shadow">
            <p>{{ 'PROMO' | translate }}</p>
          </div>
        </div>

        <p class="font-12 ion-text-wrap ion-no-margin title">{{ item.name }}</p>
        <p class="font-11 ion-no-margin description" *ngIf="firm?.template_categories === 'style2' && item.description">{{ item.description }}</p>

        <div *ngIf="!isSnoozed">

          <!-- Allergens (style2) -->
          <div class="allergens" *ngIf="item?.allergenes.length > 0 && firm?.template_categories === 'style2'">
            <img *ngFor="let allergen of item?.allergenes" [src]="allergen.image">
          </div>

          <div class="price-container">
            <div class="price" style="padding: 0px">
              <p *ngIf="!item?.has_promotional_price">{{ firm?.currency.symbol }}{{ item.price | number:'1.2-2' }}
              </p>

              <div class="promo" *ngIf="item?.has_promotional_price">
                <del>{{ firm?.currency.symbol }}{{ item.price  | number:'1.2-2' }}</del>
                <span>{{ firm?.currency.symbol }}{{ item.promotion.price | number:'1.2-2' }}</span>
              </div>
            </div>

            <!-- Popular -->
            <div *ngIf="item?.popular" class="popular-container container-shadow">
              <p><i class="fas fa-fire icon"></i>{{ 'POPULAR' | translate }}!</p>
            </div>
          </div>

          <!-- Allergens (style1) -->
          <div class="allergens" *ngIf="item?.allergenes.length > 0 && firm?.template_categories === 'style1'">
            <img *ngFor="let allergen of item?.allergenes" [src]="allergen.image">
          </div>

        </div>

        <div class="snoozed" *ngIf="isSnoozed">
          <eo-snoozed-label
            [snoozeEnd]="item?.snooze_end">
          </eo-snoozed-label>
        </div>

      </div>

      <!-- Info icon (style1) -->
      <div *ngIf="firm?.template_categories === 'style1'" class="right">
        <i tappable class="fas fa-info-circle icon font-20 v-align" (click)="goToItemPage($event)"></i>
      </div>

      <!-- Image (style2) -->
      <div class="image-container ion-no-padding" *ngIf="firm?.product_images_enabled && firm?.template_categories === 'style2'" tappable (click)="goToItemPage($event)">
        <div *ngIf="item.images" class="img v-align" [ngStyle]="{'background-image': 'url(' + productsService.getImage(item.images) + ')'}"></div>
        <div *ngIf="item?.extra_points && item?.extra_points > 0" class="extra-points-container">
          <p>+{{ item?.extra_points }} {{ 'STAMPS_LOWERCASE' | translate }}</p>
        </div>
      </div>

    </div>

    <!-- Order container -->
    <div class="order-container" *ngIf="item.opened && !isSnoozed">

      <p class="ingredients" *ngIf="ingr_group?.ingredients?.length > 0">
        {{ ingr_group?.name ? ingr_group?.name : ('INGREDIENTS' | translate) }}:
        <span *ngFor="let ingr of ingr_group?.ingredients; let last = last">{{ ingr?.name }}<span *ngIf="!last">, </span></span>
      </p>

      <div class="amount-container">

        <eo-quantity-selector>
          <eo-unit-selector
            *ngIf="item?.unit.id === units.UNIT || item?.unit.id === units.LITER || item?.unit.id === units.PERSON"
            [quantity]="item.min ? item.min : 1"
            [minQuantity]="item?.min"
            [maxQuantity]="item?.max"
            [maxCartQuantity]="firm?.max_cart_amount"
            [totalCartQuantity]="basketAmount+quantity"
            [totalItemQuantity]="amountAlreadyInCart+quantity"
            (quantityChanged)="quantityChanged($event)">
          </eo-unit-selector>
          <eo-weight-selector
            *ngIf="item?.unit.id === units.GRAM || item?.unit.id === units.KG"
            [quantity]="item.min ? item.min : item.unit_interval"
            [minQuantity]="item?.min"
            [maxQuantity]="item?.max"
            [interval]="item?.unit_interval"
            [unitId]="item?.unit.id"
            [displayChevron]="false"
            (quantityChanged)="quantityChanged($event)">
          </eo-weight-selector>
        </eo-quantity-selector>

        <button class="order-btn"
            [disabled]="!item?.in_stock || isSnoozed || (item?.max ? amountAlreadyInCart >= item?.max : false) || (firm.max_cart_amount ? basketAmount >= firm.max_cart_amount : false)"
            [ngClass]="activated ? 'order-btn-active' : 'order-btn'"
            (click)="!activated ? orderProduct() : null">
          <div class="btn-inactive" *ngIf="!activated">
            <i class="fas fa-cart-plus"></i>
            <p>{{ 'ADD' | translate }}</p>
          </div>
          <div class="btn-active" *ngIf="activated">
            <i class="far fa-check-circle"></i>
            <p>{{ 'ADDED' | translate }}</p>
          </div>
        </button>

      </div>

    </div>

  </div>

</div>
