import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { NavService } from '../../services/nav.service';
import { ProductsService } from '../../services/products.service';
import { FavoritesService } from '../../services/favorites.service';

@Component({
  selector: 'favorite-products',
  templateUrl: './favorite-products.component.html',
  styleUrls: ['./favorite-products.component.scss'],
})
export class FavoriteProductsComponent {

  @Input() favorite_products;
  @Input() category_list;
  @Input() firm;

  slideOpts = {
      initialSlide: 0,
      centeredSlides: true,
      centeredSlidesBounds: true,
      width: "130"
  };

  constructor(private navCtrl: NavController, 
              private navService: NavService,
              public productsService: ProductsService,
              public favoritesService: FavoritesService) {}

  async removeFavorite(event, prod) {
    event.stopPropagation(); 
    await this.favoritesService.remove(prod); 
    this.favorite_products = await this.favoritesService.getFavorites(this.category_list);
  }
  
  async goToProductPage(product) {
    this.navService.item = JSON.stringify(product);
    this.navService.cat_id = product.category.category_id;
    this.navCtrl.navigateForward('/item');
  }

}