import { Injectable } from '@angular/core';
import { DELIVERY_METHODS } from 'src/app/constants/delivery-methods';
import { DeliveryTimeServiceInterface } from 'src/app/modules/delivery-time/services/delivery-time-service.interface';
import { HomeDeliveryTimeService } from 'src/app/modules/delivery-time/services/home-delivery-time.service';
import { PickupPointTimeService } from 'src/app/modules/delivery-time/services/pickup-point-time.service';
import { PickupTimeService } from 'src/app/modules/delivery-time/services/pickup-time.service';
import { TableDeliveryTimeService } from 'src/app/modules/delivery-time/services/table-delivery-time.service';
import { DeliveryTimeStore } from 'src/app/modules/delivery-time/store/delivery-time.store';
import { PickupPointStore } from 'src/app/modules/delivery-method/store/pickup-point.store';
import { BasketItemsService } from 'src/app/services/basket-items.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DateService } from 'src/app/services/date.service';
import { DeliveryMethodService } from 'src/app/services/delivery-method.service';
import { FirmService } from 'src/app/services/firm.service';
import { OrderService } from 'src/app/services/order.service';
import { ProductsService } from 'src/app/services/products.service';


@Injectable({
  providedIn: 'root'
})
export class DeliveryTimeServiceResolver {
  constructor(
    private deliveryTimeStore: DeliveryTimeStore,
    private pickupPointStore: PickupPointStore,
    private dateService: DateService,
    private basketItemsService: BasketItemsService,
    private productService: ProductsService,
    private orderService: OrderService,
    private customerService: CustomerService,
    private deliveryMethodService: DeliveryMethodService,
    private firmService: FirmService,
  ) {}

  public async resolveDeliveryTimeService(firm: any): Promise<DeliveryTimeServiceInterface> {
    const deliveryMethod = await this.deliveryMethodService.getSelectedDeliveryMethod();

    if (deliveryMethod === DELIVERY_METHODS.DELIVERY) {
      return new HomeDeliveryTimeService(
        this.deliveryTimeStore,
        this.dateService,
        this.basketItemsService,
        this.productService,
        this.orderService,
        this.customerService,
        this.firmService,
        firm
      );
    }

    if (deliveryMethod === DELIVERY_METHODS.PICKUP_POINT) {
      const service = new PickupPointTimeService(
        this.deliveryTimeStore,
        this.dateService,
        this.basketItemsService,
        this.productService,
        this.orderService,
        this.customerService,
        this.firmService,
        firm
      );

      service.setStore(this.pickupPointStore);

      return service;
    }

    if (deliveryMethod === DELIVERY_METHODS.TABLE) {
      return new TableDeliveryTimeService(
        this.deliveryTimeStore,
        this.dateService,
        this.basketItemsService,
        this.productService,
        this.orderService,
        this.customerService,
        this.firmService,
        firm
      );
    }

    return new PickupTimeService(
      this.deliveryTimeStore,
      this.dateService,
      this.basketItemsService,
      this.productService,
      this.orderService,
      this.customerService,
      this.firmService,
      firm
    );
  }
}
