import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: 'eo-button',
  templateUrl: './eo-button.component.html',
  styleUrls: ['./eo-button.component.scss'],
})
export class EoButtonComponent implements OnDestroy {
  @Input() label: string;
  @Input() iconName: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() className = 'custom-btn';

  @Output() clicked = new EventEmitter<any>();

  buttonClicked = new Subject<any>();

  constructor() {
    this.setThrottledBtn();
  }

  setThrottledBtn() {
    const buttonClickedThrottled = this.buttonClicked.pipe(throttleTime(1000));
    buttonClickedThrottled.subscribe(() => {
      this.clicked.emit();
    });
  }

  clickHandler() {
    this.buttonClicked.next();
  }

  ngOnDestroy() {
    this.buttonClicked.unsubscribe();
  }
}
