import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'eo-square-btn',
  templateUrl: './square-btn.component.html',
  styleUrls: ['./square-btn.component.scss'],
})
export class SquareBtnComponent implements OnInit {

  @Input() icon: string;
  @Input() label: string;
  @Input() disabled: boolean;

  @Output() clicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  isDisabled() {
    return this.disabled;
  }

  clickHandler() {
    this.clicked.emit();
  }

}
