import { Component, EventEmitter, Output } from '@angular/core';
import { LanguageStore } from 'src/app/modules/language/store/language.store';
import { Language } from 'src/app/modules/language/types/language';

@Component({
  selector: 'language',
  templateUrl: 'language.html',
  styleUrls: ['language.scss']
})
export class LanguageComponent {
  @Output() languageOutput;
  @Output() btnDisabledOutput;

  savedLanguage: any;

  constructor(
    private languageStore: LanguageStore,
  ) {
    this.languageOutput = new EventEmitter<string>();
    this.btnDisabledOutput = new EventEmitter<string>();
  }

  public get languages(): Language[] {
    return this.languageStore.languages;
  }

  public get selectedLanguage(): Language|null {
    return this.languageStore.selectedLanguage;
  }

  chooseLanguage(language) {
    this.languageStore.selectLanguage(language);
    this.languageOutput.emit(language);
  }
}
