import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Address } from 'src/app/core/models/address';
import {
  NotDeliverableModalComponent
} from 'src/app/modules/delivery-method/components/not-deliverable-modal/not-deliverable-modal.component';
import { FirmStore } from 'src/app/modules/firm/store/firm.store';
import { ErrorModalComponent } from 'src/app/shared/error-modal/error-modal.component';
import { environment } from 'src/environments/environment';
import { Config } from '../../config';
import { ThemingService } from './theming.service';
import { CustomerService } from './customer.service';
import { ErrorService } from './error.service';
import { HeadersService } from './headers.service';
import { NavService } from './nav.service';
import { ProductsService } from './products.service';
import { TagService } from './tag.service';
import {LanguageService} from './language.service';

@Injectable({
  providedIn: 'root'
})
export class FirmService {

  endpoint_firm: string = "/firm";
  endpoint_announcements: string = "/firm/announcements";
  endpoint_newsfeed: string = "/firm/newsfeed";
  endpoint_reservation_times: string = "/reservations/times";
  endpoint_reservation_confirm: string = "/reservations/confirm";

  endpoint_firms_locator = '/firms/store-locator';

  firmUpdate = new Subject();
  firmUpdateObservable = this.firmUpdate.asObservable();

  firm = new Subject();
  firmObservable = this.firm.asObservable();

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private storage: Storage,
    private headers: HeadersService,
    private productsService: ProductsService,
    private navService: NavService,
    private navCtrl: NavController,
    private translate: TranslateService,
    private customerService: CustomerService,
    private themingService: ThemingService,
    private tagService: TagService,
    private languageService: LanguageService,
    private firmStore: FirmStore,
    private modalController: ModalController,
  ) { }

  /* API REQUESTS */
  async getFirmData(update_hamburger: boolean = false, forceFetch: boolean = false) {
    const firmId = await this.storage.get('firm');

    if (this.firmStore.currentFirm?.id === firmId && !forceFetch) {
      return this.firmStore.currentFirm;
    }

    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.get(environment.API_URL + this.endpoint_firm, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        let standard = this.storage.get('standard');
        this.storage.set('distributor', data.data.distributor);
        if(standard) {
          this.storage.set('last_download_firm', moment().format());
        }
        if(update_hamburger) {
          this.firm.next(data.data);
        }
        if(data.data.tracking && (Config.SINGLE_APP || Config.IS_MULTIFIRM)) this.tagService.setMarketingTags(data.data.tracking);
        this.firmUpdate.next(data.data);
        this.firmStore.setCurrentFirm(data.data);
        return data.data;
      })
      .catch(async err => {
        let error = await this.errorService.checkFirmErrors(err);
        if(error === 'TOKEN_INVALID') {
          this.getFirmData();
        }
      });
    }
  }

  async getFirmByAddress(address: Address) {
    let headers = await this.getHeaders();
    headers = await headers.set('firmId', String(this.firmStore.distributor.store_id));

    return this.http.post(
        `${environment.API_URL}${this.endpoint_firms_locator}`,
      {
        ...address,
        country: address.country.name,
      },
      { headers }
      )
      .toPromise()
      .then(async (data: any) => {
        if (data.message) {
          const modal = await this.modalController.create({
            component: NotDeliverableModalComponent,
            cssClass: 'contrast-modal',
            backdropDismiss: true,
            showBackdrop: true
          });

          await modal.present();

          return;
        }

        return data;
      })
      .catch(async error => {
        const errorCode = await this.errorService.checkFirmErrors(error);

        if (errorCode === 'TOKEN_INVALID') {
          return this.getFirmByAddress(address);
        }
      });
  }

  async getFirmTimeslots(date, delivery_method, products, categories, firm_pickup_point_id) {
    let headers = await this.getHeaders();
    return this.http.post(`${environment.API_URL}${this.endpoint_firm}/timeslots`,
       { date, delivery_method, products, categories, firm_pickup_point_id },
       { headers: headers })
       .toPromise()
       .then(async (data: any) => {
        return data;
       }).catch(async err => {
         let error = await this.errorService.checkFirmErrors(err);
         if(error === 'TOKEN_INVALID'){
            this.getFirmTimeslots(date, delivery_method, products, categories, firm_pickup_point_id);
         }
       });
  }

  async getTimeIntervals(timeslot_id, date, delivery_method, products, categories, firm_pickup_point_id) {
    let headers = await this.getHeaders();
    return this.http.post(`${environment.API_URL}${this.endpoint_firm}/timeslots/${timeslot_id}`,
        {date, delivery_method, products, categories, firm_pickup_point_id},
        { headers: headers})
       .toPromise()
       .then(async (data: any) => {
        return data.data;
       }).catch(async err => {
         let error = await this.errorService.checkFirmErrors(err);
         if(error === 'TOKEN_INVALID'){
           this.getTimeIntervals(timeslot_id, date, delivery_method, products, categories, firm_pickup_point_id);
         }
       });
  }

  async getTimeSlots(reservation) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.post(environment.API_URL + this.endpoint_firm + this.endpoint_reservation_times, reservation, {headers: headers})
      .toPromise()
      .then(async (data: any) => {

        var timeslots = [];

        data.data.forEach(ts => {
          let timeslot = {
            text: ts,
            value: ts
          }
          timeslots.push(timeslot);
        });

        return timeslots;
      })
      .catch(async err => {
        let error = await this.errorService.checkFirmErrors(err);
        if(error === 'TOKEN_INVALID') {
          this.getTimeSlots(reservation);
        }
      });
    }
  }

  async confirmReservation(reservation) {
    if(this.errorService.checkConnection()) {
      let headers = await this.getHeaders();
      return this.http.post(environment.API_URL + this.endpoint_firm + this.endpoint_reservation_confirm, reservation, { headers: headers })
      .toPromise()
      .then(() => {
        return;
      })
      .catch(async err => {
        let error = await this.errorService.checkFirmErrors(err);
        if(error === 'TOKEN_INVALID') {
          this.confirmReservation(reservation);
        }
        else if(error === 'RESERVATION_NOT_POSSIBLE') {
          var body;
          try {
            body = JSON.parse(err._body);
          }
          catch (error) {
            body = err._body;
          }
          return body.message;
        }
      });
    }
  }

  async enterPassword(password, firm_id) {
    if(this.errorService.checkConnection()) {
      let headers = await this.headers.getHeaders();
      headers = await headers.append('firmId', await this.storage.get('firm'));
      headers = await headers.append('language', await this.headers.getLanguage());
      if(Config.STORE_ID) {
        headers = await headers.append('storeId', Config.STORE_ID);
      }

      return this.http.post(environment.API_URL + '/firm/checkpassword', { password: password }, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        this.saveToken(data, firm_id);
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkPasswordErrors(err);
        if(error === 'TOKEN_INVALID') {
          this.enterPassword(password, firm_id);
        }
        else if(error === "403") {
          return "PASSWORD_INCORRECT";
        }
      });
    }
  }

  async getAnnouncements() {
    if(this.errorService.checkConnection()) {
      let headers: any = await this.headers.getHeaders();
      headers = await headers.append('passwordToken', await this.headers.checkToken());

      const language = await this.storage.get('language');
      if(language) {
        headers = await headers.append('language', language.code.toLowerCase());
      } else {
        const containerLanguage = await this.storage.get('container_language');
        headers = await headers.append('language', containerLanguage.code.toLowerCase());
      }

      if(Config.STORE_ID) {
        headers = await headers.append('storeId', Config.STORE_ID);
      }
      else {
        headers = await headers.append('firmId', Config.FIRM_ID);
      }

      return this.http.get(environment.API_URL + this.endpoint_announcements, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        return data;
      })
      .catch(async err => {
        let error = await this.errorService.checkFirmErrors(err);

        if(error === 'TOKEN_INVALID') {
          this.getAnnouncements();
        }
      });
    }
  }

  async getNewsfeed() {
    if(this.errorService.checkConnection()) {
      let headers: any = await this.headers.getHeaders();
      headers = await headers.append('passwordToken', await this.headers.checkToken());
      headers = await headers.append('language', await this.headers.getLanguage());
      headers = await headers.append('firmId', String(await this.storage.get('firm')));
      if(Config.STORE_ID) {
        headers = await headers.append('storeId', Config.STORE_ID);
      }
      return this.http.get(environment.API_URL + this.endpoint_newsfeed, { headers: headers })
      .toPromise()
      .then(async (data: any) => {
        return data.data;
      })
      .catch(async err => {
        let error = await this.errorService.checkFirmErrors(err);
        if(error === 'TOKEN_INVALID') {
          this.getNewsfeed();
        }
      });
    }
  }

  async getAppStoreSettings() {
    const url = 'https://easyorder-images.s3.eu-central-1.amazonaws.com/local/firms/72f88a4a-302c-4183-abfd-2de59de1d086/font/KneesPlainLight.otf';
    document.body.style.setProperty('--font-header-title', url);
    document.body.style.setProperty('--font-general', url);
  }

  async getAppStoreSettings2() {
    if (this.errorService.checkConnection()) {
      const headers = await this.headers.getHeaders();
      //const appId = await this.appVersion.getPackageName();
      const appId = encodeURI('mobi.easyorderapp.belchickentest');
      console.log('AppId: ', appId);
      return this.http.get(`${environment.API_URL}/firm/${appId}/mobile-app-store-settings`, { headers })
        .toPromise()
        .then(async (data: any) => {
          console.log('Data: ', data);
          document.body.style.setProperty('--font-header-title', data.font_header_title);
          document.body.style.setProperty('--font-general', data.font_general);
          return data.data;
        })
        .catch(async err => {
          const error = await this.errorService.checkFirmErrors(err);
          if (error === 'TOKEN_INVALID') {
            this.getNewsfeed();
          }
        });
    }
  }

  async getHeaders() {
    let headers: any = await this.headers.getHeaders();
    headers = await headers.append('passwordToken', await this.headers.checkToken());
    headers = await headers.append('firmId', String(await this.storage.get('firm')));
    headers = await headers.append('language', await this.headers.getLanguage());
    if(Config.STORE_ID) {
      headers =  await headers.append('storeId', Config.STORE_ID);
    }
    return headers;
  }

  /* Get firm from localStorage */
  async getLocalFirm() {
    let standard = await this.storage.get('standard');
    var firm;

    if(standard) {
      firm = await this.storage.get('standard_firm');
    }
    else {
      firm = await this.storage.get('current_firm');
    }

    return firm;
  }

  async setFirm(firm_id) {
    let firm: any = await this.getFirmData(true);
    let products = await this.productsService.getProductData();

    let standardFirm = await this.storage.get('standard_firm');

    if(standardFirm) {
      if(standardFirm.id === firm_id) {
        await this.setStandardFirm(firm, products);
      }
      else {
        await this.setCurrentFirm(firm, products);
      }
    }
    else {
      await this.setCurrentFirm(firm, products);
    }

    return firm;
  }

  /* Set standard firm */
  async setStandardFirm(firm, products) {
    await this.storage.set('standard', true);
    await this.storage.set('standard_firm', firm);
    await this.storage.set('standard_products', products);
    await this.storage.set('last_opened_firm', firm.id);

    // set colors
    this.themingService.setTheme(firm.theme);
  }

  /* Set current firm */
  async setCurrentFirm(firm, products) {
    // set localStorage
    await this.storage.set('standard', false);
    await this.storage.set('current_firm', firm);
    await this.storage.set('current_products', products);
    await this.storage.set('last_opened_firm', firm.id);

    // set colors
    this.themingService.setTheme(firm.theme);
  }

  /* Remove standard firm */
  async removeStandardFirm() {
    // Empty standard firm
    await this.storage.set('standard', false);
    await this.storage.set('standard_firm', null);
    await this.storage.set('standard_products', null);
  }

  async saveToken(response, firm_id) {
    // get all tokens
    let tokens: any = await this.storage.get('password_tokens');

    if(tokens != null) {
      // if token already saved, replace by new one
      tokens.forEach(token => {
        if(token.firm_id === firm_id) {
          let index = tokens.indexOf(token);

          // remove item
          if(index > -1) {
            tokens.splice(index, 1);
          }
        }
      });
    }
    else {
      tokens = []
    }

    // add new token to list
    let new_token = {
      firm_id: firm_id,
      passwordToken: response.passwordToken
    }
    tokens.push(new_token);

    // save list to localStorage
    await this.storage.set('password_tokens', tokens);
  }

  getHolidayPeriods(holidays) {
    // Get holiday periods with correct locale
    var holidayPeriods = [];

    if(holidays.length > 0){
      holidays.forEach(holiday => {
        holidayPeriods.push({
          from_date: moment(holiday.from_date).local().format('LL'),
          to_date: moment(holiday.to_date).local().format('LL')
        });
      });
    }

    return holidayPeriods;
  }

  setHours(days) {
    let week_days = days;

    var i = 0;
    for (i; i <= days.length; i++) {
      if(week_days[i] && week_days[i+1]) {
        if((week_days[i].pm && week_days[i].pm.type === 'open' && week_days[i].pm.to_time === '23:59') && (week_days[i+1].am && week_days[i+1].am.type === 'open' && week_days[i+1].am.from_time === '00:00')) {
          week_days[i].pm.to_time = week_days[i+1].am.to_time;
          week_days[i+1].am.type = 'closed';
        }
        else if((week_days[i].pm && week_days[i].pm.type === 'open' && week_days[i].pm.to_time === '23:59') && ((week_days[i+1].am && week_days[i+1].am.type === 'open' && week_days[i+1].am.from_time !== '00:00') || (week_days[i+1].am && week_days[i+1].am.type === 'closed'))) {
          week_days[i].pm.to_time = '00:00';
        }
      }
      else if(week_days[i] && !week_days[i+1]) {
        if((week_days[i].pm && week_days[i].pm.type === 'open' && week_days[i].pm.to_time === '23:59') && (week_days[0].am && week_days[0].am.type === 'open' && week_days[0].am.from_time === '00:00')) {
          week_days[i].pm.to_time = week_days[0].am.to_time;
          week_days[0].am.type = 'closed';
        }
        else if((week_days[i].pm && week_days[i].pm.type === 'open' && week_days[i].pm.to_time === '23:59') && ((week_days[0].am && week_days[0].am.type === 'open' && week_days[0].am.from_time !== '00:00') || (week_days[0].am && week_days[0].am.type === 'closed'))) {
          week_days[i].pm.to_time = '00:00';
        }
      }
    }

    return week_days;
  }

  isShopOpen(holidays, openingHours, todayDate?) {
    todayDate = todayDate || new Date();

    const onHoliday = this.isOnHoliday(holidays, todayDate);

    if (!onHoliday) {
      return this.isInsideOpeningHours(
        openingHours,
        moment().format('HH:mm'),
        todayDate.getDay() !== 0 ? todayDate.getDay() : 7
      );
    }

    return false;
  }

  isOnHoliday(holidays, todayDate: Date) {
    let onHoliday = false;

    if(holidays.length > 0) {
      const today = new Date(new Date(todayDate).setHours(0,0,0,0));

      holidays.forEach(period => {
        const from = new Date(new Date(period.from_date).setHours(0,0,0,0));
        const to = new Date(new Date(period.to_date).setHours(0,0,0,0));

        // Works in browser, but not in tests
        if(from <= today && today <= to) {
            onHoliday = true;
        }
        // Add to make tests work
        else if(from.getTime() === today.getTime() || to.getTime() === today.getTime()) onHoliday = true;
      });
    }

    return onHoliday;
  }

  isInsideOpeningHours(openingHours, currentTime, todayId) {
    let opened = false;

    // Check opening hours
    if(openingHours.length > 0) {
      // Search for current day in openinghours list
      let day: any = openingHours.filter(day => day.day_id === todayId);
      day = day[0];

      if(day) {

        // am & pm
        if((day.am && day.am.type === 'open') && (day.pm && day.pm.type === 'open')) {
          if((day.am.from_time < currentTime && currentTime < day.am.to_time) || (day.pm.from_time < currentTime && currentTime < day.pm.to_time)) {
            opened = true;
          }
        }
        // only am
        else if((day.am && day.am.type === 'open') && (day.pm && day.pm.type === 'closed')) {
          if(day.am.from_time < currentTime && currentTime < day.am.to_time) {
            opened = true;
          }
        }
        // only pm
        else if((day.pm && day.pm.type === 'open') && (day.am && day.am.type === 'closed')) {
          if(day.pm.from_time < currentTime && currentTime < day.pm.to_time) {
            opened = true;
          }
        }

      }

    }

    return opened;
  }

  async openFirm(firm) {
    if(firm.password_protected) {
      this.navService.previousPage = 'container';
      this.navCtrl.navigateRoot('/password');
    }
    else {
      // check language of chosen firm
      this.checkLanguage(firm.id);
    }
  }

  /* Check for multiple languages */
  async checkLanguage(firm_id) {
    // set firm id
    await this.storage.set('firm', firm_id);

    const firm: any = await this.getFirmData();
    this.firmStore.setCurrentFirm(firm);

    // Multiple supported languages
    if (firm.languages.length >  1) {
      await this.storage.get('language').then(async (language) => {
        // no language saved
        if(language === null) {
          this.setFirm(firm.id).then(() => {
            this.navCtrl.navigateForward('/login-language');
          });
        }
        // language already saved locally
        else {
          // check if saved language is in list of available languages
          if (firm.languages.some(lang => lang.code === language.code)) {
            this.languageService.setLanguage(language.code);
            this.setFirm(firm.id).then(() => {
              this.checkForMethodSelection(firm);
            });
          }
          else {
            this.navCtrl.navigateForward('/login-language');
          }
        }
      });
    }
    // Only 1 supported language, set default
    else {
      this.languageService.setLanguage(firm.languages[0].code);
      await this.storage.set('language', firm.languages[0]);
      this.setFirm(firm.id).then(() => {
        this.checkForMethodSelection(firm);
      });
    }
  }

  checkForMethodSelection(firm) {
    if (Config.IS_MULTIFIRM && this.firmStore.distributor.theme.show_delivery_method_first) {
      return this.navigateToMenu(firm);
    }

    if (firm.theme.show_delivery_method_first) {
      return this.navCtrl.navigateForward('/method-selection');
    }

    return this.navigateToMenu(firm);
  }

  /* Go to firm page */
  async navigateToMenu(firm) {
    // if user logged in, check push notifications
    const login = await this.storage.get('login');
    if (login) {
      if (login.activated) {
        const messages = await this.customerService.getMessages(login.id);
        await this.storage.set('unread_messages', messages);
        await this.customerService.getMessageAmount();
      }
    }
    this.navService.navigateToMenu(firm, firm.uses_deliverect ? 0 : undefined);
  }

}
