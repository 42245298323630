<ion-content>
  <eo-modal
    [submitText]="'CONTINUE' | translate"
    (submitted)="save()"
  >
    <method-delivery-switcher *ngIf="showSwitcher" topLeft></method-delivery-switcher>
    <div content class="full-width">
      <div class="title-box">
        <ion-icon src="../../assets/images/pickuppoint_thumb.svg"></ion-icon>
        <p>{{ 'CHOOSE_PICKUP_POINT' | translate }}</p>
      </div>

      <div scrollx="true">
        <ion-radio-group [(ngModel)]="selectedPickupPointId" [value]="selectedPickupPointId">
          <div *ngFor="let pickup of pickupPoints">
            <ion-row *ngIf="!pickup.main_branch" tappable (click)="selectPickupPoint(pickup)" class="box" [class.disabled]="pickup.temporary_inactive">

              <ion-col size="10">
                <p class="title-txt">{{ pickup?.name }}</p>
                <p class="address">({{ pickup?.address }}, {{ pickup?.zipcode }} {{ pickup?.locality }})</p>
              </ion-col>

              <ion-col size="2" class="radio-col">
                <ion-radio mode="md" [value]="pickup?.id"></ion-radio>
              </ion-col>

            </ion-row>
          </div>
        </ion-radio-group>
      </div>
    </div>
  </eo-modal>
</ion-content>
