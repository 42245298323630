<ion-list class="skeleton">
  <ion-item>
    <ion-avatar slot="start">
      <ion-skeleton-text animated="true"></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 65%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 85%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-avatar slot="start">
      <ion-skeleton-text animated="true"></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 65%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 85%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-avatar slot="start">
      <ion-skeleton-text animated="true"></ion-skeleton-text>
    </ion-avatar>
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 65%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 85%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 75%"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-list>