import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Address } from '../../core/models/address';

@Component({
  selector: 'eo-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent {
  @Input() address: Address;
  @Input() displaySelected = true;
  @Output() selected = new EventEmitter<Address>();
  @Output() edit = new EventEmitter<Address>();

  selectedHandler(address) {
    this.selected.emit(address);
  }

  editHandler(address) {
    this.edit.emit(address);
  }
}
