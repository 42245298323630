export class Config {

    /* ---- Version & app_id ---- */
        public static get VERSION(): string { return "7.3.0"; };
        public static get API_VERSION(): string { return "5.7.1"; };
        public static get URL_SCHEME(): string { return "sushigourmet://"; };

    /* ---- Container & multifirms ---- */
        /* public static get IS_MULTIFIRM(): boolean { return true; }; // False for store, true for multifirm
        public static get SINGLE_APP(): boolean { return false; }; // False for container, true for single app
        public static get PRIVATE_APP(): boolean { return false; }; // False for production app, true for AppCenter build
        public static get SENDER_ID(): string { return "692261048023" };
        public static get STORE_ID(): string { return "a5b1d728-bf41-42c8-a212-3d1a2a28cd9a"; };

        // Only for single apps (still needed to avoid syntax errors)
        public static get FIRM_ID(): string { return }; */

    /* ---- Single app ---- */
        public static get IS_MULTIFIRM(): boolean { return false; }; // False for store, true for multifirm
        public static get SINGLE_APP(): boolean { return true; }; // False for container, true for single app
        public static get PRIVATE_APP(): boolean { return false; }; // False for production app, true for AppCenter build
        public static get SENDER_ID(): string { return "692261048023" };
        public static get FIRM_ID(): string { return "27"; };

        // Only for container/multifirm apps (still needed to avoid syntax errors)
        public static get STORE_ID(): string { return };
}
