<div>
  <div *ngIf="showHeader">{{ 'CHOOSE_DATE_TIME' | translate }}</div>
  <div>{{ 'DATE' | translate }}:</div>
  <div class="date-box" tappable (click)="openDateDayPicker()">{{ dateDay }}</div>
  <div class="spacer"></div>
  <div>{{ 'TIME' | translate }}:</div>
  <div *ngIf="loadingTimeslot && !noTimeslotAvailable">
    <ion-spinner name="dots"></ion-spinner>
  </div>
  <div *ngIf="!loadingTimeslot && !noTimeslotAvailable">
    <div
      *ngFor="let timeslot of timeslots"
      class="timeslot"
      [class.selected]="selectedTimeslot === timeslot.id"
      tappable
      (click)="selectTimeslot(timeslot.id)"
    >
      {{ timeslot.from_time }} - {{ timeslot.to_time }}
    </div>
  </div>
  <div class="error" *ngIf="noTimeslotAvailable">{{ 'MESSAGES.NO_TIMESLOTS_AVAILABLE' | translate }}</div>
  <div class="horizontal-ruler"></div>
  <div *ngIf="loadingInterval">
    <ion-spinner name="dots"></ion-spinner>
  </div>
  <div class="interval-container" *ngIf="!loadingInterval">
    <div
      *ngFor="let interval of intervals"
      class="timeslot"
      [class.selected]="selectedInterval === interval.time"
      tappable
      (click)="selectInterval(interval.time)"
    >
      {{ interval.time }}
    </div>
  </div>
</div>

<ion-datetime #dateDayPicker
              [(ngModel)]="dateDayModel"
              style="display: none"
              pickerFormat="D MMM YYYY"
              [min]="minDate"
              [max]="maxDate"
              monthNames="{{ 'MONTHS.JANUARY' | translate }},{{ 'MONTHS.FEBRUARY' | translate }},{{ 'MONTHS.MARCH' | translate }},{{ 'MONTHS.APRIL' | translate }},{{ 'MONTHS.MAY' | translate }},{{ 'MONTHS.JUNE' | translate }},{{ 'MONTHS.JULY' | translate }},{{ 'MONTHS.AUGUST' | translate }},{{ 'MONTHS.SEPTEMBER' | translate }},{{ 'MONTHS.OCTOBER' | translate }},{{ 'MONTHS.NOVEMBER' | translate }},{{ 'MONTHS.DECEMBER' | translate }}"
              monthShortNames="{{ 'MONTHS_SHORT.JAN' | translate }},{{ 'MONTHS_SHORT.FEB' | translate }},{{ 'MONTHS_SHORT.MAR' | translate }},{{ 'MONTHS_SHORT.APR' | translate }},{{ 'MONTHS_SHORT.MAY' | translate }},{{ 'MONTHS_SHORT.JUN' | translate }},{{ 'MONTHS_SHORT.JUL' | translate }},{{ 'MONTHS_SHORT.AUG' | translate }},{{ 'MONTHS_SHORT.SEPT' | translate }},{{ 'MONTHS_SHORT.OCT' | translate }},{{ 'MONTHS_SHORT.NOV' | translate }},{{ 'MONTHS_SHORT.DEC' | translate }}"
              name="date"
              (ionChange)="handleOnDateDayChange()"
              ngDefaultControl>
</ion-datetime>
