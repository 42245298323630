import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { MenuStore } from 'src/app/modules/menu/store/menu.store';
import { Config } from 'src/config';
import { BasketItemsService } from '../../services/basket-items.service';
import { CustomerService } from '../../services/customer.service';
import { NavService } from '../../services/nav.service';
import { TemplateService } from '../../services/template.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() firm: any;
  @Input() backBtn: boolean;
  @Input() search = false;
  @Input() hideSubHeader = false;
  @Output() navigateback = new EventEmitter<any>();
  @Input() hideBasket: any;

  login: any;
  unreadMessages: any;

  multifirm: boolean = Config.IS_MULTIFIRM;

  constructor(
    public customerService: CustomerService,
    public basketItemsService: BasketItemsService,
    public navCtrl: NavController,
    public navService: NavService,
    private storage: Storage,
    public templateService: TemplateService,
    public menuStore: MenuStore,
  ) {}

  async ngOnInit() {
    this.login = await this.storage.get('login');
    this.unreadMessages = this.customerService.unreadMessages;

    if (!this.unreadMessages) {
      this.unreadMessages = 0;
    }
  }

  navigateBack() {
    this.navigateback.emit();
  }
}
